import React, { useState, useEffect } from "react";
import { FcGallery } from "react-icons/fc";
import { IoSend } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import chatting from "../../api/chatting";
import {
  setChangeHeader,
  setClickedEdit,
  setDeleteMessge,
  setSendMessageUpdate,
} from "../../redux/action";
import { uploadFilesToS3 } from "../../images/images";
import { emitEvent } from "../../utils/socketio";

function ChatFooter() {
  const selectedUser = useSelector((state) => state.selectedUser);
  const clickedEdit = useSelector((state) => state.clickedEdit);
  const messageContent = useSelector((state) => state.messageContent);

  console.log(messageContent, "===>messageContent 12345");

  const [inputMessage, setInputMessage] = useState("");
  const [editContent, setEditContent] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [uploadPosterURL, setUploadPosterURL] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const myId = localStorage.getItem("userId");

  // Update editContent when clickedEdit or messageContent changes
  useEffect(() => {
    if (clickedEdit && messageContent) {
      setEditContent(messageContent.content || "");
    }
  }, [clickedEdit, messageContent]);

  const handleInputMessage = (e) => {
    setInputMessage(e.target.value);
  };

  const handleInputEdit = (e) => {
    setEditContent(e.target.value);
  };


  const reduxMessage = {
    senderId: Number(selectedUser?.userId),
    recipientId: Number(myId),
    content: inputMessage,
    messageType: uploadPosterURL?.length > 0 ? "image" : "text",
    mediaUrl: uploadPosterURL[0],
  };
  const handleSendMessage = async () => {
    const messageData = {
      senderId: Number(selectedUser?.userId),
      recipientId: Number(myId),
      content: inputMessage,
      messageType: uploadPosterURL?.length > 0 ? "image" : "text",
      mediaUrl: uploadPosterURL[0],
    };

    try {
      emitEvent("chat:sendMessage", messageData);

      dispatch(setSendMessageUpdate(reduxMessage));

      setInputMessage("");
      setUploadProgress("");
      setUploadPosterURL("");
      setSelectedFile("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleEditMessage = async () => {
    if (!editContent) {
      console.error("Cannot edit an empty message");
      return;
    }

    try {
      const response = await chatting.editMessage(
        selectedUser?.userId,
        messageContent?.id,
        editContent
      );
      if (response?.status === true) {
        dispatch(setDeleteMessge(true));
        dispatch(setChangeHeader(false));
      }

      console.log("Message edited successfully", response);
      dispatch(setClickedEdit(false));

      setEditContent("");
    } catch (error) {
      console.error("Failed to edit message", error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("Please select a file first");
      return;
    }
    setSelectedFile(file);
    try {
      const url = await uploadFilesToS3([file], setUploadProgress);
      setUploadPosterURL(url);
    } catch (error) {
      console.error("File upload error:", error);
      setError("File upload failed. Please try again.");
    }
  };

  return (
    <>
      {selectedUser.block ? (
        <>
          <div className="p-1 white-bg footer">
            <div className="text-center grey-text">
              You cannot send messages to this user as they are blocked.
            </div>
          </div>
        </>
      ) : clickedEdit ? (
        <div className="p-1 white-bg footer">
          <div className="white-bg border-grey br-20 font-14 d-flex flex-row py-2 px-2">
            <input
              type="text"
              value={editContent || ""}
              placeholder="Edit message"
              className="all-none grey-text w-75"
              onChange={handleInputEdit}
            />
            <div className="d-flex flex-row w-25 align-items-center justify-content-around">
              <FcGallery className="font-30 mx-1" />
              <IoSend
                className="font-30 grey-text"
                onClick={handleEditMessage}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="p-1 white-bg footer">
          <div className="white-bg border-grey br-20 font-14 d-flex flex-row py-2 px-2">
            <input
              type="text"
              value={inputMessage}
              placeholder={`${
                selectedFile &&
                uploadProgress &&
                uploadProgress[selectedFile.name] === 100
                  ? "Image uploaded"
                  : "Type your message"
              }`}
              className="all-none grey-text w-75"
              onChange={handleInputMessage}
            />
            <div className="d-flex flex-row w-25 align-items-center justify-content-around">
              <input
                type="file"
                style={{ display: "none" }}
                id="file-upload"
                onChange={handleFileChange}
              />
              <label htmlFor="file-upload">
                <FcGallery className="font-30 mx-1" />
              </label>
              <IoSend
                className="font-30 grey-text"
                onClick={handleSendMessage}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChatFooter;
