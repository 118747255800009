import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonButton,
} from '@ionic/react';
import { Images } from '../../images';
import { getMyMemories } from '../../api/apiMethods';

const PrivatePhotos = () => {
  const photos = [
    Images.private1,
    Images.private2,
    Images.private4,
    Images.private3,
    Images.private1,
    Images.private2,
    Images.private4,
    Images.private3,
    Images.private4,
  ];

  const [selectedPhoto, setSelectedPhoto] = useState(null); // For tracking the clicked photo
  const [isModalOpen, setIsModalOpen] = useState(false); // For controlling modal visibility

  const openModal = (photo) => {
    setSelectedPhoto(photo);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedPhoto(null);
    setIsModalOpen(false);
  };

  const [myMemories, setMyMemories] = useState([]);
  console.log(myMemories, "user my photos");
  const fetchAllMyMemories = () => {
    getMyMemories({})
      .then((response) => {
        if (response.status === true) {
          console.log(response, "my user images");
          setMyMemories(response?.data);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  useEffect(() => {
    fetchAllMyMemories();
  }, []);

  return (
    <IonPage>
      <IonHeader className="pale-pink">
        <IonToolbar color="blue">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" className="clr-white" />
          </IonButtons>
          <h3 className="d-flex flex-center clr-white">Your Photos</h3>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow>
            {myMemories.map((photo, index) => (
              <IonCol
                size="6"
                key={index}
                className="photo-col"
                onClick={() => openModal(Images.private1 || photo?.path)} 
              >
                <img
                  src={Images.private1 || photo?.path}
                  alt={`Photo ${index + 1}`}
                  className="photo-img"
                />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>

        
        <IonModal isOpen={isModalOpen} onDidDismiss={closeModal}  cssClass="private-photos  ">
          <IonContent className="ion-padding">
            {selectedPhoto && (
              <div className="photo-modal-container">
                <img src={selectedPhoto} alt="Selected" className="modal-photo-img" />
              </div>
            )}
            <IonButton expand="block" onClick={closeModal} cssClass='close-block'>
              Close
            </IonButton>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default PrivatePhotos;
