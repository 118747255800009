import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import QRCodeModal from "./QRCodeModal";
import { cancelUserTrip } from "../../api/apiMethods";
import SuccessPopup from "./SuccessPopup";

function TotalExpenesesPopup({
  showExpense,
  setShowExpense,
  bookingIdToCancel,
}) {
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState("");
  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [showQrCode, setShowQrCode] = useState(false);

  const [upiDetails, setUpiDetails] = useState({
    upiNumber: "1234567890@upi",
    name: "John Doe",
  });

  const [neftDetails, setNeftDetails] = useState({
    ifsc: "XYZB0001234",
    bankAccount: "9876543210",
    name: "Jane Doe",
    phoneNumber: "9876543210",
  });

  const [textAreaContent, setTextAreaContent] = useState("");

  useEffect(() => {
    if (selectedPaymentGateway === "QR Code") {
      setTextAreaContent("");
    } else if (
      ["Phone Pe", "Google Pay", "Paytm"].includes(selectedPaymentGateway)
    ) {
      setTextAreaContent(
        `UPI Number: ${upiDetails.upiNumber}\nName: ${upiDetails.name}`
      );
    } else if (selectedPaymentGateway === "NEFT") {
      setTextAreaContent(
        `IFSC Code: ${neftDetails.ifsc}\nBank Account: ${neftDetails.bankAccount}\nName: ${neftDetails.name}\nPhone Number: ${neftDetails.phoneNumber}`
      );
    } else {
      setTextAreaContent("");
    }
  }, [selectedPaymentGateway, upiDetails, neftDetails]);

  const handlePaymentGatewayChange = (event) => {
    setSelectedPaymentGateway(event.target.value);
  };

  //   const handleOpenConfirmModal = () => {
  //     setConfirmModal(true);
  //   };

  const handleShowQrCode = () => {
    setShowQrCode(true);
  };

  const handleCloseQrCode = () => {
    setShowQrCode(false);
  };

  const handleQrCodeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setQrCodeImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const CancelTheUpcomingTour = () => {
    const payment_gateway = selectedPaymentGateway;
    let banking_details = "";
    if (payment_gateway === "QR Code") {
      banking_details = qrCodeImage;
    } else {
      banking_details = textAreaContent;
    }
    const currency_name = "USD";
    const payload = {
      payment_gateway,
      banking_details,
      currency_name,
    };
    console.log("Payload:", payload);

    cancelUserTrip(bookingIdToCancel, payload)
      .then((res) => {
        if (res?.status === true) {
          console.log("Tour Is Cancelled");
          setShowSuccessPopup(true);
          setShowExpense(false);
        } else {
          console.log("Something wrong happened");
        }
      })
      .catch((error) => console.log(error.message));
  };
  return (
    <div>
      <IonModal
        isOpen={showExpense}
        cssClass="custom-modal"
        backdropDismiss={false}
        centered
      >
        <div className="p-2 white-bg br-20">
          <div className="d-flex justify-content-end pointer">
            <RxCross2
              className="icon-font"
              onClick={() => setShowExpense(false)}
            />
          </div>
          <h3 className="fw-600 clr-black d-flex justify-content-center mb-2">
            TOTAL EXPENCES
          </h3>

          <h6 className="fw-600 clr-black d-flex justify-content-center my-2 font-14">
            Add Your Original Payment Method (IND/INR)
          </h6>
          <div className="w-100 d-flex flex-column">
            <label className="font-14 fw-500 mb-1 clr-black">
              Payment Gateway
            </label>
            <select
              className="font-14 text-hash input-box1 br-10 p-2 w-fill fw-500 pointer"
              value={selectedPaymentGateway}
              onChange={handlePaymentGatewayChange}
            >
              <option value="">Select Payment Gateway</option>
              <option value="Phone Pe">Phone Pe</option>
              <option value="Google Pay">Google Pay</option>
              <option value="Paytm">Paytm</option>
              <option value="NEFT">NEFT</option>
              <option value="QR Code">QR Code</option>
            </select>
          </div>

          {selectedPaymentGateway === "QR Code" && (
            <div className="w-100 mt-2 d-flex flex-column">
              <label className="font-14 fw-500 mb-1 clr-black">
                Upload QR Code
              </label>
              <input
                type="file"
                className="font-14 grey-text border-grey br-10 p-2 w-fill fw-500 pointer"
                onChange={handleQrCodeUpload}
              />
            </div>
          )}

          {selectedPaymentGateway !== "QR Code" && (
            <div className="w-100 mt-2 d-flex flex-column">
              <label className="font-14 fw-500 mb-1 clr-black">
                UPI/Bank Details
              </label>
              <textarea
                className="font-14 text-hash input-box1 br-10 p-2 w-fill fw-500 pointer"
                value={textAreaContent}
                readOnly
              />
            </div>
          )}

          {qrCodeImage && (
            <div className="flex-center mt-2">
              <img
                onClick={handleShowQrCode}
                src={qrCodeImage}
                alt="QR Code"
                className="qr-code-img"
              />
            </div>
          )}

          <div className="flex-between w-100 mt-3 font-14 fw-600">
            <div
              className="pink-bg clr-white w-48 p-2 br-20 text-center"
              onClick={CancelTheUpcomingTour}
            >
              Proceed
            </div>
            <div
              className="password-clr border-grey white-bg text-center p-2 w-48 br-20"
              onClick={() => setShowExpense(false)}
            >
              Cancel
            </div>
          </div>
          <QRCodeModal
            showQrCode={showQrCode}
            setShowQrCode={setShowQrCode}
            qrCodeImage={qrCodeImage}
            handleCloseQrCode={handleCloseQrCode}
          />
        </div>
      </IonModal>
      <SuccessPopup
        showSuccess={showSuccessPopup}
        setShowSuccess={setShowSuccessPopup}
        title="Requested Cancellation"
        description="You Successfully Submitted Ur Request For Cancellation The Tour"
      />
    </div>
  );
}

export default TotalExpenesesPopup;
