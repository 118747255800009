import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { IoSend } from "react-icons/io5";
import chatting from "../../api/chatting";
import { Images } from "../../images";

import { FcGallery } from "react-icons/fc";
import {
  IonModal,
  IonHeader,
  IonContent,
  IonFooter,
  IonButton,
  IonInput,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { setMessageContent } from "../../redux/action";
import { uploadFilesToS3 } from "../../images/images";
import {
  connectSocket,
  disconnectSocket,
  onEvent,
  emitEvent,
  offEvent,
} from "./../../utils/socketio";

function ChatPage({ setShowChatPage }) {
  const [officeTeam, setOfficeTeam] = useState([]);
  const [userMessages, setUserMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);

  const [selectedTitle, setSelectedTitle] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [longPressTimeout, setLongPressTimeout] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [uploadPosterURL, setUploadPosterURL] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [error, setError] = useState();
  const [socketConnected, setSocketConnected] = useState(false);

  const myId = localStorage.getItem("userId");
  const dispatch = useDispatch();

  console.log(officeTeam, "==>officeTeam");

  const handleSelectTeamPerson = (userName, id) => {
    setSelectedTitle({ userName, id });
    console.log(userName, id, "==>userName");

    handleChatMessages();
  };

  const handleChatPageClose = () => {
    setShowChatPage(false);
  };

  const handleInputMessage = (e) => {
    setInputMessage(e.target.value);
  };

  const handleChatWithTeam = async () => {
    try {
      const response = await chatting.chatWithTeam();
      setOfficeTeam(response.data);
    } catch (error) {
      console.error("Error while chatting with team:", error);
    }
  };

  useEffect(() => {
    handleChatWithTeam();
  }, []);

  useEffect(() => {
    let registeredHandler;
    let messageHandler;

    if (selectedTitle.id) {
      if (!socketConnected) {
        connectSocket();
        emitEvent("register", selectedTitle.id);

        registeredHandler = (data) => {
          console.log("User registered 123:", data);
        };

        messageHandler = (message) => {
          console.log("New message received:", message);
          setUserMessages((prevMessages) => [...prevMessages, message]);
        };

        offEvent("registered", registeredHandler);
        onEvent("registered", registeredHandler);

        offEvent("chat:receiveMessage", messageHandler);
        onEvent("chat:receiveMessage", messageHandler);

        console.log(`Connected with user: ${selectedTitle.id}`);
        setSocketConnected(true);
      }
    } else if (socketConnected) {
      disconnectSocket();
      setSocketConnected(false);
      console.log("Disconnected due to no chatting user");
    }

    return () => {
      if (socketConnected) {
        offEvent("registered", registeredHandler);
        offEvent("chat:receiveMessage", messageHandler);
        disconnectSocket();
        setSocketConnected(false);
        console.log(`Disconnected from user: ${selectedTitle.id}`);
      }
    };
  }, [selectedTitle, socketConnected]);

  const handleChatMessages = async () => {
    if (!selectedTitle?.id) return;
    try {
      const response = await chatting.getChatMessages(selectedTitle?.id);
      const filteredMessages = response.data.filter(
        (message) => !message.deletedBy
      );
      setUserMessages(filteredMessages);
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    }
  };

  const handleSendMessage = async () => {
    const messageData = {
      senderId: Number(selectedTitle.id),
      recipientId: Number(myId),
      content: inputMessage,
      messageType: uploadPosterURL?.length > 0 ? "image" : "text",
      mediaUrl: uploadPosterURL[0],
    };

    try {
      emitEvent("chat:sendMessage", messageData);

      const messageWithTimestamp = {
        ...messageData,
        timestamp: messageData.timestamp || new Date().toISOString(),
      };
      setUserMessages((prevMessages) => [
        ...prevMessages,
        messageWithTimestamp,
      ]);
      setInputMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleLongPressStart = (index, content, id) => {
    if (selectedMessageIndex === index) {
      setSelectedMessageIndex(null);
      dispatch(setMessageContent({ id: id, content: content }));
    } else {
      const timeout = setTimeout(() => {
        setSelectedMessageIndex(index);
      }, 500);
      setLongPressTimeout(timeout);
    }
  };

  const handleLongPressEnd = () => {
    clearTimeout(longPressTimeout);
  };
  const handleMessageClick = (index) => {
    if (selectedMessageIndex === index) {
      setSelectedMessageIndex(null);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("Please select a file first");
      return;
    }
    setSelectedFile(file);
    try {
      const url = await uploadFilesToS3([file], setUploadProgress);
      setUploadPosterURL(url);
    } catch (error) {
      console.error("File upload error:", error);
      setError("File upload failed. Please try again.");
    }
  };

  return (
    <IonModal
      isOpen={true}
      onDidDismiss={handleChatPageClose}
      className="chatpage-position br-10 border-black"
    >
      <div className="white-bg w-100 d-flex flex-end">
        <IoMdClose
          className="font-24 my-2 mx-2"
          onClick={handleChatPageClose}
        />
      </div>
      <div className="d-flex flex-column align-items-center white-bg">
        <div className="d-flex w-100 flex-between px-2">
          {officeTeam.map((item, index) => (
            <div
              key={index}
              className="d-flex w-100 flex-between my-2"
              onClick={() => handleSelectTeamPerson(item.userName, item.id)}
            >
              <div className="d-flex flex-column mx-2 align-items-center">
                <img
                  src={item.image || Images.chatpic1}
                  alt="chatpic1"
                  className="chat-images"
                />
                <span className="font-16 fw-600">{item.userName}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <IonContent className="chat-scroll p-1">
        {userMessages.length > 0 &&
          userMessages.map((msg, index) => (
            <div
              key={index}
              className={`d-flex flex-column w-100 position-relative mt-3 ${
                selectedMessageIndex === index ? "highlighted-message" : ""
              }`}
              onClick={() => handleMessageClick(index)}
            >
              <div
                className={
                  Number(msg?.senderId) === Number(myId)
                    ? "w-100 d-flex justify-content-start"
                    : "w-100 d-flex justify-content-end"
                }
                onTouchStart={() =>
                  handleLongPressStart(index, msg.content, msg.id)
                }
                onTouchEnd={handleLongPressEnd}
              >
                <div className="d-flex flex-column px-2">
                  <div
                    className={`${
                      selectedMessageIndex === index
                        ? "highlighted-message"
                        : ""
                    } ${
                      Number(msg?.senderId) === Number(myId)
                        ? "grey-text grey-bg2 font-14 p-2 br-10 d-flex flex-column"
                        : "outgoing-chat-clr lightgreen-bg font-14 p-2 br-10"
                    }`}
                  >
                    {/* Check for image or text messages */}
                    {msg?.messageType === "image" ||
                    (msg?.content && msg?.content.includes("http")) ? (
                      <img
                        src={msg.mediaUrl || msg.content}
                        alt="Message Content"
                        className="chatImagesize"
                        onError={(e) => (e.target.style.display = "none")}
                      />
                    ) : (
                      <div className="px-2">{msg.content}</div>
                    )}
                  </div>
                  <div>
                    <div className="grey-text font-12 d-flex flex-between mx-2">
                      <span className="timestamp font-14">
                        {new Date(msg.timestamp).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </span>

                      {Number(msg?.senderId) !== Number(myId) && (
                        <FaCheck className="font-10" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </IonContent>

      <IonFooter className="p-1 white-bg border-grey br-2">
        <div className="d-flex align-items-center">
          <IonInput
            type="text"
            value={inputMessage}
            placeholder={`${
              selectedFile &&
              uploadProgress &&
              uploadProgress[selectedFile.name] === 100
                ? "Image uploaded"
                : "Type your message"
            }`}
            className="all-none grey-text w-75"
            onIonInput={handleInputMessage}
          />

          <div className="d-flex w-25 align-items-center justify-content-around">
            <input
              type="file"
              style={{ display: "none" }}
              id="file-upload"
              onChange={handleFileChange}
            />
            <label htmlFor="file-upload">
              <FcGallery className="font-30 mx-1" />
            </label>
            <IoSend className="font-30 grey-text" onClick={handleSendMessage} />
          </div>
        </div>
      </IonFooter>
    </IonModal>
  );
}

export default ChatPage;
