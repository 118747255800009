import { IonModal } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import { signUpUser } from "../../api/apiMethods";

function SignUp({
  signupModal,
  setSignupModal,
  handleSuccessPopup,
  handleLoginModal,
}) {
  const handleSignupClose = () => {
    setSignupModal(false);
  };

  const [errormessage, seterrormessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [signUpData, setSignUpData] = useState({
    user_name: "",
    phone: "",
    password: "",
    confirm_password: "",
  });
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const formattedPhoneNumber = value.replace(/\D/g, "").slice(0, 10);
      setSignUpData({ ...signUpData, [name]: formattedPhoneNumber });
    } else {
      setSignUpData({ ...signUpData, [name]: value });
    }
  };

  const handleSignUp = () => {
    signUpUser({
      user_name: signUpData.user_name,
      country_code: "+91",
      phone: signUpData.phone,
      password: signUpData.password,
      confirm_password: signUpData.confirm_password,
    })
      .then((response) => {
        if (response?.status === true) {
          setSignUpData({
            user_name: "",
            phone: "",
            password: "",
            confirm_password: "",
          });
          setTimeout(() => {
            setSignupModal(false);
            handleLoginModal();
          }, 1000);
        } else {
          console.error("Signup failed: Invalid status");
        }
      })
      .catch((error) => seterrormessage(error?.message));
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <IonModal isOpen={signupModal} className="custom-modal">
      <div className="white-bg p-3 br-20">
        <div className="text-end" onClick={() => handleSignupClose()}>
          <RxCross2 className="font-30" />
        </div>
        <h2 className="password-clr w-100 text-center fw-600">SignUp</h2>

        <div className="d-flex flex-column">
          <div className="black-text font-16 fw-600 my-1">
            User Name <span className="star">*</span>
          </div>
          <div className="input-bg br-10 my-1">
            <input
              type="text"
              name="user_name"
              value={signUpData.user_name}
              onChange={handleChange}
              placeholder="Enter Username"
              className="w-100 p-2 br-10 bg-none"
            />
          </div>
        </div>

        <div className="d-flex flex-column">
          <div className="black-text font-16 fw-600 my-1">
            Phone No <span className="star">*</span>
          </div>
          <div className="input-bg br-10 my-1">
            <input
              type="number"
              name="phone"
              value={signUpData.phone}
              onChange={handleChange}
              placeholder="Enter Phone Number"
              className="w-100 p-2 br-10 bg-none"
            />
          </div>
        </div>

        <div className="d-flex flex-column">
          <div className="black-text font-16 fw-600 my-1">
            Password <span className="star">*</span>
          </div>
          <div className="input-bg br-10 my-1 d-flex align-items-center">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={signUpData.password}
              onChange={handleChange}
              placeholder="Enter Password"
              className="w-90 p-2 br-10 bg-none"
            />
            <AiFillEye
              className="font-30 clr-grey"
              onClick={togglePasswordVisibility}
            />
          </div>
        </div>

        <div className="d-flex flex-column">
          <div className="black-text font-16 fw-600 my-1">
            Confirm Password <span className="star">*</span>
          </div>
          <div className="input-bg br-10 my-1 d-flex align-items-center">
            <input
              type={showPassword ? "text" : "password"}
              name="confirm_password"
              value={signUpData.confirm_password}
              onChange={handleChange}
              placeholder="Confirm Password"
              className="w-90 p-2 br-10 bg-none"
            />
            <AiFillEyeInvisible
              className="font-30 clr-grey"
              onClick={toggleConfirmPasswordVisibility}
            />
          </div>
        </div>
        <div className="clr-red">{errormessage} </div>
        <div
          className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2"
          onClick={handleSignUp}
        >
          Sign Up
        </div>

        <div className="text-center" onClick={() => handleLoginModal()}>
          <span className="grey-text">Already have an account?</span>
          <span className="grey-text fw-700">Login</span>
        </div>
      </div>
    </IonModal>
  );
}

export default SignUp;
