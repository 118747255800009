import { IonCol, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import CompletetedTrips from "./CompletetedTrips";
import UpcomingTrips from "./UpcomingTrips";
import Documents from "../popups/Documents";
import {
  getAllTrips,
  getCompletedTrips,
  getUpcomingTrips,
} from "../../api/apiMethods";

function Trips() {
  const buttonsTrips = ["Upcoming Trips", "Completed Trips"];
  const token = localStorage.getItem("jwt_token");

  const [activeTripsButton, setActiveTripsButton] = useState(0);
  const handleActiveTripsButton = (index) => {
    setActiveTripsButton(index);
  };
  const [showDocuments, setShowDocumnets] = useState(false);
  const [bookingIdToCancel, setBookingIdToCancel] = useState(null); // New state for bookingId

  const [selectedImage, setSelectedImage] = useState(null);

  console.log(selectedImage, "============>selectedImage<===============");
  const handleDocuments = (tour) => {
    const images = {
      travel: tour.path[0] || "",
      hotel: tour.path[1] || "",
      tour: tour.path[2] || "",
    };
    setSelectedImage(images);
    setShowDocumnets(true);
  };

  // const handleCancelPopup = () => {
  //   setCancelPopup(true);
  // };
  const [upcomingTours, setUpcomingTours] = useState([]);
  const [completedTours, setCompletedTours] = useState([]);
  const getTours = () => {
    getAllTrips()
      .then((res) => {
        if (res?.status === true) {
          console.log(res);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => console.log(err));
  };
  const getUpcomingTours = () => {
    getUpcomingTrips({ token })
      .then((res) => {
        if (res?.status === true) {
          console.log(res);
          setUpcomingTours(res.data.record);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => console.log(err));
  };
  const handleCompletedTrips = () => {
    getCompletedTrips({ token })
      .then((response) => {
        if (response?.status === true) {
          console.log(response);
          setCompletedTours(response.data.record);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => console?.log(error));
  };
  useEffect(() => {
    getTours();
    getUpcomingTours();
    handleCompletedTrips();
  }, []);

  const formatDateToIndian = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Intl.DateTimeFormat("en-IN", options).format(
      new Date(dateString)
    );
  };

  return (
    <div className="input-bg homecontent-sec">
      <div className="w-100 d-flex flex-row justify-content-between align-items-center my-2 py-1 px-2">
        {buttonsTrips.map((button, index) => (
          <div
            key={index}
            className={
              activeTripsButton === index
                ? "w-50 pink-bg clr-white fw-700 py-2 br-20 text-center mx-1"
                : "w-50 bg-grey clr-black fw-700 py-2 br-20 text-center mx-1"
            }
            onClick={() => handleActiveTripsButton(index)}
          >
            {button}
          </div>
        ))}
      </div>
      {activeTripsButton === 1 && (
        <CompletetedTrips
          completedTours={completedTours}
          formatDateToIndian={formatDateToIndian}
          handleDocuments={handleDocuments}
          showDocuments={showDocuments}
          setShowDocumnets={setShowDocumnets}
        />
      )}
      {activeTripsButton === 0 && (
        <UpcomingTrips
          bookingIdToCancel={bookingIdToCancel}
          setBookingIdToCancel={setBookingIdToCancel}
          formatDateToIndian={formatDateToIndian}
          upcomingTours={upcomingTours}
          handleDocuments={handleDocuments}
          showDocuments={showDocuments}
          setShowDocumnets={setShowDocumnets}
        />
      )}
      <Documents
        selectedImage={selectedImage} // Pass the selectedImage state
        showDocuments={showDocuments}
        setShowDocumnets={setShowDocumnets}
      />
    </div>
  );
}

export default Trips;
