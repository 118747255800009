import React, { useState } from "react";
import { IonModal } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";
import { userEditProfile } from "../../api/apiMethods";
import chatting from "../../api/chatting";
import { useSelector } from "react-redux";

function BlockUser({ blockUser, setBlockUser }) {
  const token = localStorage.getItem("jwt_token");

  const selectedUser = useSelector((state) => state?.selectedUser);

  const handleBlockTheUser = () => {
    const response = chatting.blockTheUser(selectedUser.userId);
  };

  return (
    <IonModal
      isOpen={blockUser}
      onDidDismiss={() => setBlockUser(false)}
      cssClass="personal-photos white-bg auto-height-modal"
    >
      
      <div className="w-100 d-flex justify-content-end">
        <RxCross2
          className="font-24 grey-text cursor-pointer"
          onClick={() => setBlockUser(false)}
        />
      </div>
      <div className="w-100 flex-center fw-700">Sure want to Block ?</div>
      <div className="d-flex w-100 flex-evenly py-2">
        <div
          className="pink-bg clr-white font-16 fw-600 px-2 py-1 br-10"
          onClick={handleBlockTheUser}
        >
          Block
        </div>
        <div
          className="pink-bg clr-white font-16 fw-600 px-2 py-1 br-10"
          onClick={() => setBlockUser(false)}
        >
          Cancel
        </div>
      </div>
    </IonModal>
  );
}

export default BlockUser;
