import React, { useState } from "react";
import { IonModal } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { resetUserPassword } from "../../api/apiMethods";

function ResetPassword({ openResetPassword, setOpenResetPassword }) {
  const handlePasswordClose = () => {
    setOpenResetPassword(false);
  };
  const [resetPasswordData, setResetPasswordData] = useState({});
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState();

  const handleChange = (e) => {
    setResetPasswordData({
      ...resetPasswordData,
      [e.target.name]: e.target.value,
    });
  };

  console.log(resetPasswordData, "===> resetPasswordData");

  const handleResetPassword = () => {
    resetUserPassword({
      old_password: resetPasswordData?.old_password,
      new_password: resetPasswordData?.new_password,
      confirm_password: resetPasswordData?.confirm_password,
    })
      .then((response) => {
        if (response?.status === true) {
          setResetPasswordData(response.data);
          console.log(response, "reser password response");
          setTimeout(() => {
            setOpenResetPassword(false);
          }, 2000);
        } else {
          setError("Something Went Wrong");
        }
      })
      .catch((error) => setError(error?.message));
  };

  return (
    <IonModal
      isOpen={openResetPassword}
      cssClass="reset-pswd-popup border-grey br-20"
    >
      <div className="white-bg p-3">
        <div className="text-end" onClick={() => handlePasswordClose()}>
          <RxCross2 className="font-30" />
        </div>
        <h2 className="password-clr w-100 text-center fw-600">
          Reset Password
        </h2>
        <div className="d-flex flex-column">
          <div className="black-text font-16 fw-600 my-1">
            Old Password <span className="star">*</span>
          </div>
          <div className="input-bg br-10 my-1 d-flex align-items-center">
            <input
              type={showOldPassword ? "text" : "password"}
              name="old_password"
              placeholder="Enter Old Password"
              className="grey-text w-100 p-2 bg-none"
              value={resetPasswordData.old_password}
              onChange={handleChange}
            />
            {showOldPassword ? (
              <AiFillEyeInvisible
                className="font-30 clr-grey cursor-pointer"
                onClick={() => setShowOldPassword(!showOldPassword)}
              />
            ) : (
              <AiFillEye
                className="font-30 clr-grey cursor-pointer"
                onClick={() => setShowOldPassword(!showOldPassword)}
              />
            )}
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="black-text font-16 fw-600 my-1">
            New Password <span className="star">*</span>
          </div>
          <div className="input-bg br-10 my-1 d-flex align-items-center">
            <input
              type={showNewPassword ? "text" : "password"}
              name="new_password"
              placeholder="Enter New Password"
              className="grey-text w-100 p-2 bg-none"
              value={resetPasswordData?.new_password}
              onChange={handleChange}
            />
            {showNewPassword ? (
              <AiFillEyeInvisible
                className="font-30 clr-grey cursor-pointer"
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
            ) : (
              <AiFillEye
                className="font-30 clr-grey cursor-pointer"
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
            )}
          </div>
        </div>

        <div className="d-flex flex-column">
          <div className="black-text font-16 fw-600 my-1">
            Confirm Password <span className="star">*</span>
          </div>
          <div className="input-bg br-10 my-1 d-flex align-items-center">
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              placeholder="Confirm New Password"
              className="grey-text w-100 p-2 bg-none"
              value={resetPasswordData?.confirm_password}
              onChange={handleChange}
            />
            {showConfirmPassword ? (
              <AiFillEyeInvisible
                className="font-30 clr-grey cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            ) : (
              <AiFillEye
                className="font-30 clr-grey cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            )}
          </div>
        </div>
        <div
          className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2"
          onClick={handleResetPassword}
        >
          Reset Password
        </div>
        {error && (
          <div className="red-text font-14 text-center mt-2">{error}</div>
        )}
      </div>
    </IonModal>
  );
}

export default ResetPassword;
