import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { updateUserDepositDetailsByID } from "../../api/apiMethods";
import { useSelector } from "react-redux";
import { IonCol, IonModal, IonRow } from "@ionic/react";

function EditDepositDetails({
  editDepositDetail,
  setEditDepositDetail,
  depositDetails,
  userWalletStatsData,
  selectDepositId,
}) {
  console.log(depositDetails, "depositDetails");
  const UserID = useSelector((state) => state.userDetailsData.id);

  const [formState, setFormState] = useState({
    deposit_amount: "",
    balance_amount: "",
    tnx_id: "",
  });
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);

  useEffect(() => {
    if (depositDetails && userWalletStatsData) {
      setFormState({
        deposit_amount: depositDetails.deposit_amount || "",
        balance_amount: userWalletStatsData.balance || "",
        tnx_id: depositDetails.tnx_id || "",
      });
    }
  }, [depositDetails, userWalletStatsData, selectDepositId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveChanges = () => {
    const payload = {
      deposit_amount: formState.deposit_amount,
      balance_amount: formState.balance_amount,
      user_id: UserID,
      tnx_id: formState.tnx_id,
    };

    updateUserDepositDetailsByID(selectDepositId, payload)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Updated Response");
          setEditDepositDetail(false);
          setTimeout(() => {
            setShowSubmitPopup(true);
          }, 1000);
        } else {
          console.error("Error updating deposit details.");
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <IonModal
        isOpen={editDepositDetail}
        onDidDismiss={() => setEditDepositDetail(false)}
        className="custom-modal"
        centered
      >
        <div className="p-2 br-10 white-bg">
          <div className="flex-between">
            <h6 className="text-black fw-bold">Edit Deposit Details</h6>
            <RxCross2
              className="pointer font-2rem"
              onClick={() => setEditDepositDetail(false)}
            />
          </div>

          <IonRow>
            <IonCol size="6">
              <div className="flex-column">
                
                <label className="font-14 mb-2 text-black">Balance Amount</label>
                <input
                  type="number"
                  name="balance_amount"
                  placeholder="Amount"
                  value={formState.balance_amount}
                  onChange={handleInputChange}
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  min={1000}
                />
              </div>
            </IonCol>
            <IonCol size="6">
              <div className="flex-column">
                <label className="font-14 mb-2 text-black">Deposit Amount</label>
                <input
                  type="text"
                  name="deposit_amount"
                  placeholder="Enter Amount"
                  value={formState.deposit_amount}
                  onChange={handleInputChange}
                  className="input-box1 br-10 p-2 w-fill fw-500"
                />
              </div>
            </IonCol>
          </IonRow>
          <div className="w-100 font-14 flex-column mt-2">
            <label className="mb-1">Tnx ID</label>
            <input
              type="text"
              name="tnx_id"
              placeholder="Enter Tnx id"
              value={formState.tnx_id}
              onChange={handleInputChange}
              className="input-box1 br-10 p-2 w-fill fw-500"
            />
          </div>
          <div
            className="pink-bg text-center py-2 clr-white br-20 mt-4 pointer"
            onClick={handleSaveChanges}
          >
            Save Changes
          </div>
        </div>
      </IonModal>
    </>
  );
}

export default EditDepositDetails;
