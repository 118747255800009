import React, { useState } from "react";
import { IonModal } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";
import personalPhotos from "../../api/personalphotos";
import { useSelector } from "react-redux";

function PhotosRequest({ showPhotosRequest, setShowPhotosRequest }) {
  const token = localStorage.getItem("jwt_token");

  const selectedUser = useSelector((state) => state?.selectedUser);
  console.log(selectedUser, "===>selectedUser");

  const requestForPersonalPhotos = async () => {
    try {
      const response = await personalPhotos.requestForPersonalPhotos(
        selectedUser?.userId
      );
    } catch (error) {
      console.error("Error requesting personal photos:", error);
    }
  };

  return (
    <IonModal
      isOpen={showPhotosRequest}
      onDidDismiss={() => setShowPhotosRequest(false)}
      cssClass="personal-photos white-bg auto-height-modal"
    >
      <div className="w-100 d-flex justify-content-end">
        <RxCross2
          className="font-24 grey-text cursor-pointer"
          onClick={() => setShowPhotosRequest(false)}
        />
      </div>
      <div className="w-100 flex-center fw-700">Request Personal Photos ?</div>
      <div className="d-flex w-100 flex-evenly py-2">
        <div
          className="pink-bg clr-white font-16 fw-600 px-2 py-1 br-10"
          onClick={requestForPersonalPhotos}
        >
          Request
        </div>
        <div className="pink-bg clr-white font-16 fw-600 px-2 py-1 br-10" onClick={()=>setShowPhotosRequest(false)}>
          Cancel
        </div>
      </div>
    </IonModal>
  );
}

export default PhotosRequest;
