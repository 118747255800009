import { IonModal } from "@ionic/react";
import React from "react";
import { FaThumbsUp } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

function SubmitPopup({ successPopup, setSuccessPopup, title, description }) {
  const handleSuccessClose = () => {
    window.location.reload();
    setSuccessPopup(false);
  };
  return (
    <IonModal
      show={successPopup}
      onHide={handleSuccessClose}
      className="otp-popup"
      centered
    >
      <div className="p-1">
        <div className="w-100 d-flex justify-content-end">
          <RxCross2
            className="large-font"
            onClick={() => handleSuccessClose()}
          />
        </div>
        <div className="p-2">
          <div className="w-100 d-flex justify-content-center">
            <div className="w-20 green-bg p-4 text-center rounded-circle">
              <FaThumbsUp className="clr-white font-40" />
            </div>
          </div>
          <div className="px-4 my-2">
            <h5 className="password-clr w-100 text-center fw-600">{title}</h5>
            <div className="font-14 fw-500 w-100 text-center grey-text">
              {description}
            </div>
            <div
              className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2"
              onClick={() => handleSuccessClose()}
            >
              Go Back
            </div>
          </div>
        </div>
      </div>
    </IonModal>
  );
}

export default SubmitPopup;
