import { Images } from "../../images";
import React, { useEffect, useRef, useState } from "react";
import { IoCallOutline } from "react-icons/io5";
import { IoVideocamOutline } from "react-icons/io5";
import { MdBlock } from "react-icons/md";
import { FaArrowCircleLeft } from "react-icons/fa";
import { LuFolderLock } from "react-icons/lu";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import PhotosRequest from "../popups/PhotosRequest";
import BlockUser from "../popups/BlockUser";
import { GrGallery } from "react-icons/gr";
import { TbCornerUpLeftDouble } from "react-icons/tb";
import { TbCornerUpRightDouble } from "react-icons/tb";
import { HiOutlineDotsVertical } from "react-icons/hi";
import {
  setChangeHeader,
  setClickedEdit,
  setDeleteMessge,
} from "../../redux/action";
import { IonItem, IonList, IonModal } from "@ionic/react";
import chatting from "../../api/chatting";
import ForwardMessage from "./ForwardMessage";
import personalPhotos from "../../api/personalphotos";

function ChatHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPhotosRequest, setShowPhotosRequest] = useState(false);
  const [blockUser, setBlockUser] = useState(false);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [personalStatus, setPersonalStatus] = useState();
  const optionsRef = useRef(null);
  const [myAcceptedFriends, setMyAcceptedFriends] = useState([]);
  const messageContent = useSelector((state) => state.messageContent);
  const selectedUser = useSelector((state) => state?.selectedUser);
  const changeHeader = useSelector((state) => state?.changeHeader);
  const checkMyCreator = useSelector((state) => state?.checkMyCreator);
  console.log(checkMyCreator, "==>checkMyCreator");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setIsOptionsVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleOptions = () => {
    setIsOptionsVisible(!isOptionsVisible);
  };

  const handleGetBack = () => {
    navigate("/chat");
    dispatch(setChangeHeader(false));
    dispatch(setClickedEdit(false));
  };
  const handleEdit = (event) => {
    event.preventDefault();

    dispatch(setClickedEdit(true));

    setIsOptionsVisible(false);
  };

  const handleDeleteMessage = async () => {
    try {
      const response = await chatting.deleteMessage(messageContent?.id);

      if (response?.status === true) {
        dispatch(setDeleteMessge(true));
        dispatch(setChangeHeader(false));
      }

      console.log("Message deleted successfully", response);
    } catch (error) {
      console.error("Failed to delete message", error);
    }
  };

  const handleDeleteMessageForBothusers = async (recipientId, messageId) => {
    try {
      const response = await chatting.deleteMessageForBothUsers(
        selectedUser.userId,
        messageContent?.id
      );
      if (response?.status === true) {
        dispatch(setDeleteMessge(true));
        dispatch(setChangeHeader(false));
      }
      // Handle success response here (optional)
      console.log("Message deleted for both users successfully", response);
    } catch (error) {
      console.error("Failed to delete message for both users", error);
    }
  };
  const recentChats = [
    { name: "Ranjith", lastMessage: "Available", avatar: Images.chatpic2 },
    {
      name: "Nani",
      lastMessage: "Wipro Hiring Freshers...",
      avatar: Images.chatpic2,
    },
  ];

  const getMyFriendsAll = async () => {
    const response = await chatting.getMyFriendsList();

    setMyAcceptedFriends(response?.data);
  };

  useEffect(() => {
    getMyFriendsAll();
    getPrivatePhotos();
  }, []);

  const getPrivatePhotos = async () => {
    try {
      const response = await personalPhotos.getThePersonalPhotos(
        selectedUser.userId
      );

      console.log("Private photos:", response?.data);
      if (response.status === true) {
        setPersonalStatus(response.status);
      } else {
        setPersonalStatus(false);
      }
    } catch (error) {
      console.error("Error fetching private photos:", error);
    }
  };

  return (
    <>
      {changeHeader ? (
        <div className="header">
          <div className="white-bg d-flex flex-row justify-content-between align-items-center w-100 py-1 border-bottom-grey">
            <div className="d-flex flex-row p-2 w-75 align-items-center">
              <FaArrowCircleLeft
                className="font-30 grey-text mr-1"
                onClick={handleGetBack}
              />
              <img
                src={Images?.chatpic2}
                className="online-chat-profile"
                alt="user"
              />
              <div className="d-flex flex-column mx-2">
                <div className="password-clr font-14 fw-600">
                  {selectedUser?.userName}
                </div>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center w-25 justify-content-around">
              <TbCornerUpRightDouble
                className="font-24 grey-text"
                onClick={() => setIsModalOpen(true)}
              />
              <HiOutlineDotsVertical
                className="font-24 grey-text"
                onClick={toggleOptions}
              />
              <IonModal
                isOpen={isOptionsVisible}
                cssClass="edit-popup"
                onDidDismiss={() => setIsOptionsVisible(false)}
              >
                <IonList>
                  <IonItem button onClick={handleEdit}>
                    Edit
                  </IonItem>
                  <IonItem button onClick={handleDeleteMessage}>
                    Delete
                  </IonItem>
                  <IonItem button onClick={handleDeleteMessageForBothusers}>
                    Delete All
                  </IonItem>
                </IonList>
              </IonModal>
            </div>
          </div>
        </div>
      ) : (
        <div className="header">
          <div className="white-bg d-flex flex-row justify-content-between align-items-center w-100 py-1 border-bottom-grey">
            <div className="d-flex flex-row p-2 w-75 align-items-center">
              <FaArrowCircleLeft
                className="font-30 grey-text mr-1"
                onClick={() => navigate("/chat")}
              />
              <img
                src={Images?.chatpic2}
                className="online-chat-profile"
                alt="user"
              />
              <div className="d-flex flex-column mx-2">
                <div className="password-clr font-14 fw-600">
                  {selectedUser?.userName}
                </div>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center w-25 justify-content-around">
              {!checkMyCreator &&
                (personalStatus === true ? (
                  <GrGallery onClick={() => navigate("/private-images")} />
                ) : (
                  <LuFolderLock
                    className="font-24 grey-text"
                    onClick={() => setShowPhotosRequest(true)}
                  />
                ))}

              <MdBlock
                className="font-24 grey-text"
                onClick={() => setBlockUser(true)}
              />
            </div>
          </div>
          <PhotosRequest
            showPhotosRequest={showPhotosRequest}
            setShowPhotosRequest={setShowPhotosRequest}
          />
          <BlockUser blockUser={blockUser} setBlockUser={setBlockUser} />
          <ForwardMessage
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            myAcceptedFriends={myAcceptedFriends}
          />
        </div>
      )}
    </>
  );
}

export default ChatHeader;
