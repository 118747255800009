import { createStore } from "redux";

const initialState = {
  userDetailsData: [],
  uploadPosterId: null,
  tourCategoryData: [],
  selectedUser: {},
  changeHeader: null,
  clickedEdit: null,
  messageContent: {},
  selectedMessageId: "",
  sendMessageUpdate: "",
  deleteMessage: null,
  checkMyCreator: null,
  selectedPackageName: "",
  totalGroupMembers: 0,
  totalPackageCost: 0,
  totalSelectedMembers: 0,
  members: [],
  packages: [],
  totalGroupCost: 0,
  selectedOption: "",
  selectedPackagesCount: 0,
  withdrawData: [],
  privatePhotos: [],
  imagePaymentId: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_DETAILS_DATA":
      return {
        ...state,
        userDetailsData: action.payload,
      };
    case "SET_UPLOAD_POSTER_ID":
      return {
        ...state,
        uploadPosterId: action.payload,
      };
    case "SET_TOUR_CATEGORY_DATA":
      return {
        ...state,
        tourCategoryData: action.payload,
      };
    case "SET_SELECTED_USER":
      return {
        ...state,
        selectedUser: action.payload,
      };
    case "SET_CHANGE_HEADER":
      return {
        ...state,
        changeHeader: action.payload,
      };
    case "SET_CLICKED_EDIT":
      return {
        ...state,
        clickedEdit: action.payload,
      };
    case "SET_MESSAGE_CONTENT":
      return {
        ...state,
        messageContent: action.payload,
      };
    case "SET_MESSAGE_ID":
      return {
        ...state,
        selectedMessageId: action.payload,
      };
    case "SET_MESSAGE_SEND":
      return {
        ...state,
        sendMessageUpdate: action.payload,
      };
    case "SET_MESSAGE_DELETE":
      return {
        ...state,
        deleteMessage: action.payload,
      };
    case "SET_CHECK_MYCREATOR":
      return {
        ...state,
        checkMyCreator: action.payload,
      };
    case "SET_SELECTED_PACKAGE_NAME":
      return {
        ...state,
        selectedPackageName: action.payload,
      };
    case "SET_TOTAL_GROUP_MEMBERS":
      return {
        ...state,
        totalGroupMembers: action.payload,
      };
    case "SET_TOTAL_PACKAGE_COST":
      return {
        ...state,
        totalPackageCost: action.payload,
      };

    case "SET_TOTAL_SELECTED_MEMBERS":
      return {
        ...state,
        totalSelectedMembers: action.payload,
      };
    case "SET_MEMBERS_DATA":
      return {
        ...state,
        members: action.payload,
      };
    case "SET_PACKAGES_DATA":
      return {
        ...state,
        packages: action.payload,
      };
    case "SET_TOTAL_GROUP_COST":
      return {
        ...state,
        totalGroupCost: action.payload,
      };
    case "SET_SELECTED_OPTION":
      return {
        ...state,
        selectedOption: action.payload,
      };
    case "SET_SELECTED_PACKAGES_COUNT":
      return {
        ...state,
        selectedPackagesCount: action.payload,
      };
    case "SET_WITHDRAW_DATA":
      return {
        ...state,
        withdrawData: action.payload,
      };
    case "SET_PRIVATE_PHOTOS":
      return {
        ...state,
        privatePhotos: action.payload,
      };
    case "SET_IMAGE_PAYMENT_ID":
      return {
        ...state,
        imagePaymentId: action.payload,
      };
    default:
      return state;
  }
};

const store = createStore(authReducer);

export default store;
