import { IonModal } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";
import { IoMdDownload } from "react-icons/io";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function Documents({ showDocuments, setShowDocumnets, selectedImage }) {
  const handleDocumentClose = () => {
    setShowDocumnets(false);
  };

  return (
    <IonModal
      isOpen={showDocuments}
      className="custom-modal"
      backdropDismiss={false}
    >
      <div className="document-modal p-3">
        <div className="text-end" onClick={() => handleDocumentClose()}>
          <RxCross2 className="font-30" />
        </div>
        <h2 className="clr-black w-100 text-center fw-600">Documents</h2>
        <select className="input-bg br-10 my-2 px-2 grey-text py-2 w-100">
          Select Name
          <option>Sangram</option>
          <option>Shrikanth</option>
          <option>Ranjit</option>
          <option>Jyothi Babu</option>
        </select>
        <div className="clr-black mt-1 large-font">Travel Booking</div>
        <div className="border-grey input-bg py-2 d-flex flex-row align-items-center grey-bg br-10 px-2">
          <div className="grey-text w-75 tour-report-text">
            {selectedImage?.travel || "No document available"}
          </div>
          <div className="grey-bg1 p-1 br-50 mx-2">
            <AiFillEye className="font-20 fw-600 clr-white" />
          </div>
          <div className="grey-bg1 p-1 br-50">
            <IoMdDownload className="font-20 fw-600 clr-white" />
          </div>
        </div>
        <div className="clr-black mt-2 large-font">Hotel Booking</div>
        <div className="border-grey input-bg py-2 d-flex flex-row align-items-center grey-bg br-10 px-2">
          <div className="grey-text w-75 tour-report-text">
            {selectedImage?.hotel || "No document available"}
          </div>
          <div className="grey-bg1 p-1 br-50 mx-2">
            <AiFillEye className="font-20 fw-600 clr-white" />
          </div>
          <div className="grey-bg1 p-1 br-50 ">
            <IoMdDownload className="font-20 fw-600 clr-white" />
          </div>
        </div>
        <div className="clr-black mt-2 large-font">Tour Guidance</div>
        <div className="border-grey input-bg py-2 d-flex flex-row align-items-center grey-bg br-10 px-2">
          <div className="grey-text w-75 tour-report-text">
            {selectedImage?.tour || "No document available"}
          </div>
          <div className="grey-bg1 p-1 br-50 mx-2">
            <AiFillEye className="font-20 fw-600 clr-white" />
          </div>
          <div className="grey-bg1 p-1 br-50">
            <IoMdDownload className="font-20 fw-600 clr-white" />
          </div>
        </div>
      </div>
    </IonModal>
  );
}

export default Documents;
