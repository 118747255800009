import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { updateUserWithdrawDetailsByID } from "../../api/apiMethods";
import SubmitPopup from "../popups/SubmitPopup";
import { IonCol, IonModal, IonRow } from "@ionic/react";

function ViewWithdrawlDetailsPopup({
  viewWithdrawDetails,
  setViewWIthdrawDetails,
  withdrawalDetails,
  viewWithdrawMode,
  setViewWithdrawMode,
  selectWithdrawId,
}) {
  console.log(selectWithdrawId, "selectWithdrawId");
  const [zoomedImage, setZoomedImage] = useState(null);

  const handleImageClick = (imagePath) => {
    setZoomedImage(imagePath);
  };

  const closeZoomModal = () => {
    setZoomedImage(null);
  };

  return (
    <>
      <IonModal
        isOpen={viewWithdrawDetails}
        onDidDismiss={() => setViewWIthdrawDetails(false)}
        className="custom-modal"
        centered
      >
        <div className="p-2 br-10 white-bg">
          <div className="flex-between">
            <div className="text-black large-font fw-bold">
              {viewWithdrawMode
                ? "View Withdraw Details"
                : "Edit Withdraw Details"}
            </div>
            <RxCross2
              className="pointer font-2rem"
              onClick={() => setViewWIthdrawDetails(false)}
            />
          </div>

          <IonRow className="row mt-2 font-14 fw-600">
            <IonCol size="6">
              <div className="flex-column">
                {" "}
                <label className="mb-1">Role</label>
                <input
                  type="text"
                  value={withdrawalDetails?.role_name || ""}
                  placeholder="Customer"
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  readOnly
                />
              </div>
            </IonCol>
            <IonCol size="6">
              <div className="flex-column">
                <label className="mb-1">Name</label>
                <input
                  type="text"
                  value={withdrawalDetails?.name || ""}
                  placeholder="Srinivas"
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  readOnly
                />
              </div>
            </IonCol>
          </IonRow>

          <div className="row mt-2 font-14 fw-600">
            <div className="col-6">
              <input
                type="text"
                placeholder="Balance"
                value={withdrawalDetails?.balance_amount || ""}
                className="input-box1 br-10 p-2 w-fill fw-500"
                readOnly
              />
            </div>
            <div className="col-6">
              <input
                type="text"
                placeholder="Withdraw Balance"
                value={withdrawalDetails?.withdrawAmount || ""}
                className="input-box1 br-10 p-2 w-fill fw-500"
                readOnly
              />
            </div>
          </div>
          {viewWithdrawMode ? null : (
            <div className="row mt-2 font-14 fw-600">
              <div className="col-6">
                <label className="mb-1">Enter Amount</label>
                <input
                  type="number"
                  placeholder="Amount"
                  value={withdrawalDetails?.withdrawAmount || ""}
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  min={1000}
                  readOnly
                />
              </div>
              <div className="col-6">
                <label className="mb-1">Payment Mode</label>
                <input
                  type="text"
                  placeholder="Payment Mode"
                  value={withdrawalDetails?.payment_mode || ""}
                  className="input-box1 br-10 p-2 w-fill fw-500"
                  readOnly
                />
              </div>
            </div>
          )}

          <div className="flex-column w-100 mt-2 font-14 fw-600">
            <label className="mb-1">UPI/Bank Details</label>
            <textarea
              className="input-box1 br-10 p-2 w-fill fw-500"
              value={withdrawalDetails?.bank_details || "N/A"}
              placeholder="Enter your UPI or Bank details here"
              readOnly
            />
          </div>
          <div className="flex-column w-100 mt-2 font-14 fw-600">
            <label className="mb-1">Payment Receipt</label>
            <img
              className="w-100 h-10vh cursor-pointer"
              src={withdrawalDetails?.path}
              onClick={() => handleImageClick(withdrawalDetails?.path)}
              alt="Receipt"
            />
          </div>
        </div>
      </IonModal>

      {/* Zoom Image Modal */}
      {zoomedImage && (
        <IonModal size="lg" show={true} centered onHide={closeZoomModal}>
          <img
            src={zoomedImage}
            alt="Zoomed Receipt"
            className="w-100"
            style={{ maxHeight: "90vh", objectFit: "contain" }}
          />
          <button
            onClick={closeZoomModal}
            className="btn btn-secondary position-absolute top-0 end-0 m-3"
          >
            Close
          </button>
        </IonModal>
      )}
    </>
  );
}

export default ViewWithdrawlDetailsPopup;
