import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { MdCloudUpload } from "react-icons/md";
import { Images } from "../../images";
import { useDispatch, useSelector } from "react-redux";
import {
  bookingUser,
  getWalletStatus,
  uploadImage,
} from "../../api/apiMethods";
import { uploadFilesToS3 } from "../../images/images";
import { setImagePaymentId } from "../../redux/action";

function PaymentDetails({
  setActiveStep,
  selectedTourData,
  handleBookingUser,
  totalMembers,
  totalPrice,
  filledMemberNames,
  selectedPayments,
  handlePaymentsBtn,
  showPayments,
  handlePaymentsSelection,
  bankData,
  handleCountry,
  showCountryName,
  selectedCountry,
  handleCountrySelection,
  filteredGateways,
}) {
  console.log(bankData, "bankData");
  const paymentDetails = bankData?.find(
    (item) => item?.gateway_name === selectedPayments
  );
  console.log(paymentDetails, "paymentDetails");
  const countryOptions = [
    ...new Set(bankData.map((item) => item.country_name)),
  ];
  const totalSelectedMembers = useSelector(
    (state) => state.totalSelectedMembers
  );
  const uniqueCountries = [
    ...new Set(bankData.map((item) => item.country_name)),
  ];
  const uniqueGateways = [
    ...new Set(bankData.map((item) => item.gateway_name)),
  ];
  const totalGroupMembers = useSelector((state) => state.totalGroupMembers);
  const totalPackageCost = useSelector((state) => state.totalPackageCost);
  const members = useSelector((state) => state.members);
  const packages = useSelector((state) => state.packages);
  const UserID = useSelector((state) => state.userDetailsData.id);
  const selectedOption = useSelector((state) => state.selectedOption);
  const totalGroupCost = useSelector((state) => state.totalGroupCost);
  const [selectedGateway, setSelectedGateway] = useState(null);

  const handleGatewaySelection = (gatewayName) => {
    const gateway = bankData.find((g) => g.gateway_name === gatewayName);
    setSelectedGateway(gateway);
  };
  const handleSportsBookingUser = () => {
    if (!selectedGateway) {
      console.error("No gateway selected!");
      return;
    }

    const sportsTourPayload = {
      upload_id: 1,
      bookedFor_id: UserID,
      member: members,
      total_price: totalPackageCost,
      banking_gateway_id: selectedGateway.id,
      package: packages,
    };

    bookingUser(sportsTourPayload)
      .then((response) => {
        if (response.status === true) {
          console.log(response);
          setActiveStep(3);
        } else {
          console.log("Something Went Wrong");
        }
      })
      .catch((err) => console.log(err));
  };
  const handleClick = () => {
    document.getElementById("fileInput").click();
  };
  const dispatch = useDispatch();
  const handleFileChange = (event) => {
    const files = event.target.files;
    console.log("Selected files:", files); // Do something with the selected files
  };
  // const [imagePaymentId, setImagePaymentId] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [uploadProgress, setUploadProgress] = useState(null);
  const [error, setError] = useState();
  const handlePosterSubmit = (url) => {
    uploadImage({ path: url, type: "image" })
      .then((response) => {
        if (response?.status === true) {
          const posterId = response.data.map((item) => item.id);
          // setImagePaymentId(posterId);
          dispatch(setImagePaymentId(posterId));
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => setError(error?.message));
  };
  const handlePhotoFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("Please select a file first");
      return;
    }
    setSelectedFile(file);
    try {
      const url = await uploadFilesToS3([file], setUploadProgress);
      handlePosterSubmit(url);
    } catch (error) {
      console.log(error);
      setError("Error uploading file");
    }
  };
  const [userWalletStatsData, setUserWalletsData] = useState();
  console.log(userWalletStatsData, "userWalletStatsData");

  const userWalletStatus = () => {
    getWalletStatus()
      .then((response) => {
        if (response.status === true) {
          console.log(response.data);
          setUserWalletsData(response.data);
        } else {
          console.log("Some Error is Happening");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    userWalletStatus();
  }, []);
  const [finalPrice, setFinalPrice] = useState(totalPrice);

  const handleWalletCheckbox = (isChecked) => {
    if (isChecked) {
      setFinalPrice(totalPrice - userWalletStatsData.balance);
    } else {
      setFinalPrice(totalPrice);
    }
  };

  return (
    <div className="mt-2 pos-relative">
      <h1 className="password-clr font-20 my-2 fw-700 w-100 text-center">
        Payment Details
      </h1>
      <div className="booknow-table-header py-1 clr-white  p-2 br-10">
        <div className="small-font fw-600 d-flex flex-row clr-white border-bottom-grey d-flex align-items-center justify-content-between py-1">
          <div>Select Members</div>
          <div>
            {selectedTourData?.slug === "casino-tour" ? (
              <>{totalMembers} </>
            ) : (
              <>{totalSelectedMembers}</>
            )}
          </div>
        </div>
        <div className="d-flex flex-row clr-white border-bottom-grey d-flex align-items-center justify-content-between py-1">
          <div className="d-flex flex-column small-font fw-600">
            {Object.entries(filledMemberNames).map(([key, name]) => (
              <div key={key}>
                {key}: "{name}"
              </div>
            ))}
            <div className="d-flex flex-column small-font">
              {members.map((member, index) => (
                <div key={index}>{member.name}</div>
              ))}
            </div>
          </div>
          <div className="w-20 pink-bg py-1 br-20 small-font fw-600 text-center">
            View ID
          </div>
        </div>
        <div className="small-font fw-600 d-flex flex-row clr-white border-bottom-grey d-flex align-items-center justify-content-between py-1">
          <div>Package Amount</div>
          {selectedTourData?.slug === "casino-tour" ? (
            <> {totalPrice.toFixed(2)}</>
          ) : (
            <>
              {selectedOption === "group" ? (
                <>{totalGroupCost.toFixed(2)}</>
              ) : (
                <> {totalPackageCost.toFixed(2)} </>
              )}
            </>
          )}
        </div>
        <div className="small-font fw-600 d-flex flex-row clr-white border-bottom-grey d-flex align-items-center justify-content-between py-1">
          <div>Total Members</div>
          <div>
            {selectedTourData?.slug === "casino-tour" ? (
              <>{totalMembers}</>
            ) : (
              <>
                {selectedOption === "group" ? (
                  <>{totalSelectedMembers}</>
                ) : (
                  <> {totalGroupMembers} </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="br-10 mt-2 input-bg p-2 d-flex align-items-center justify-content-between password-clr fw-600 small-font">
        <div>Total Amount to Pay</div>
        {selectedTourData?.slug === "casino-tour" ? (
          <> {totalPrice.toFixed(2)}</>
        ) : (
          <>
            {selectedOption === "group" ? (
              <>{totalGroupCost.toFixed(2)}</>
            ) : (
              <> {totalPackageCost.toFixed(2)} </>
            )}
          </>
        )}
      </div>
      <div className="br-10 mt-2 input-bg p-2 d-flex align-items-center justify-content-between password-clr fw-600 small-font">
        <div className="d-flex justify-content-start">
          <input
            type="checkbox"
            className="me-1"
            onChange={(e) => handleWalletCheckbox(e.target.checked)}
          />
          <div>Wallet Balance</div>
        </div>

        {selectedTourData?.slug === "casino-tour" ? (
          <> {userWalletStatsData?.balance.toFixed(2)}</>
        ) : (
          <>
            <> {userWalletStatsData?.balance.toFixed(2)}</>
          </>
        )}
      </div>
      {selectedTourData?.slug === "casino-tour" ? (
        <div className="my-2">
          <div className="d-flex flex-column">
            <div className="small-font fw-600 ">Country</div>
            <div
              className="input-bg br-10 py-2 d-flex flex-row justify-content-between align-items-center px-2 my-1"
              onClick={() => handleCountry()}
            >
              <div className="password-clr small-font fw-600">
                {selectedCountry || "select Country"}
              </div>
              <IoIosArrowDown className="font-24 fw-600 password-clr" />
            </div>
            {showCountryName && (
              <div className="input-bg grey-text br-10 payment-dropdown">
                {countryOptions.map((item, index) => (
                  <div
                    key={index}
                    className={`red-border medium-font fw-600 p-2 ${
                      index < countryOptions.length - 1
                        ? "border-bottom-grey"
                        : ""
                    }`}
                    onClick={() => handleCountrySelection(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            )}

            <div className="small-font fw-600 ">Payment Mode</div>
            <div
              className="input-bg br-10 py-2 d-flex flex-row justify-content-between align-items-center px-2 my-1"
              onClick={() => handlePaymentsBtn()}
            >
              <div className="password-clr small-font fw-600">
                {selectedPayments || "Select payments"}
              </div>
              <IoIosArrowDown className="font-24 fw-600 password-clr" />
            </div>
            {showPayments && (
              <div className="white-bg password-clr border-pink package-dropdown br-10 px-2 pointer">
                {filteredGateways.length > 0 ? (
                  filteredGateways.map((option, index) => (
                    <div
                      key={index}
                      className="small-font fw-600 py-1 pointer border-bottom-grey"
                      onClick={() =>
                        handlePaymentsSelection(
                          option?.gateway_name,
                          option?.id
                        )
                      }
                    >
                      {option?.gateway_name}
                    </div>
                  ))
                ) : (
                  <div className="small-font fw-600 py-1">
                    No payment options available
                  </div>
                )}
              </div>
            )}
            <div className="fw-600 px-1 my-1 medium-font password-clr">
              Upload Photo
            </div>
            <input
              type="file"
              className="input-bg d-flex align-items-center justify-content-between px-2 br-10 py-2 w-100"
              onChange={handlePhotoFileChange} // Remove the parentheses to pass the event automatically
            />

            {selectedPayments === "phone_pe" ||
            selectedPayments === "g_pay" ||
            selectedPayments === "paytm" ? (
              <div className="input-bg br-10 h-10vh p-2">
                <div className="small-font clr-black">
                  Mobile Number: {paymentDetails?.mobile_number}
                </div>
                <div className="small-font clr-black">
                  UPIID: {paymentDetails?.upi_id}
                </div>
                <div className="small-font clr-black">
                  UPINAME: {paymentDetails?.upi_name}
                </div>
              </div>
            ) : selectedPayments === "bankdetails" ? (
              <div className="input-bg br-10 h-10vh p-2">
                <div className="small-font clr-black">
                  Bank_Name: {paymentDetails?.bank_name}
                </div>
                <div className="small-font clr-black">
                  Account Number: {paymentDetails?.bank_acc_no}
                </div>
                <div className="small-font clr-black">
                  Bank_IFSC_Number: {paymentDetails?.bank_ifsc_code}
                </div>
              </div>
            ) : selectedPayments === "qr_code" ? (
              <div className="input-bg br-10 h-10vh p-2">
                <div className="small-font clr-black">
                  QR_Name: {paymentDetails?.qr_name}
                </div>
                <img
                  src={Images.BgBannerTwo || paymentDetails?.qr_upload_path}
                  alt="QR Code"
                  className="mt-1 br-10 h-5h"
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="my-2">
          <div className="d-flex flex-column">
            <div className="small-font fw-600 ">Country</div>
            <select className="input-bg br-10 py-2 d-flex border-none small-font flex-row justify-content-between align-items-center px-2 my-1">
              <option>select Country</option>
              {uniqueCountries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>

            <div className="small-font fw-600 ">Payment Mode</div>
            <select
              onChange={(e) => handleGatewaySelection(e.target.value)}
              className="input-bg br-10 py-2 d-flex border-none small-font flex-row justify-content-between align-items-center px-2 my-1"
            >
              <option>select Payment</option>
              {uniqueGateways.map((gateway, index) => (
                <option key={index} value={gateway}>
                  {gateway}
                </option>
              ))}
            </select>

            <div className="input-bg br-10 py-2 d-flex flex-row justify-content-between align-items-center px-2 my-1">
              <input
                type="file"
                id="fileInput"
                // style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <div
                className="input-bg br-10 py-2 d-flex flex-row justify-content-between align-items-center px-2 my-1"
                onClick={handleClick}
              >
                <div className="grey-text small-font fw-600">
                  Upload Documents
                </div>
                <MdCloudUpload className="font-24 fw-600 password-clr" />
              </div>
              {/* <div className="grey-text small-font fw-600">
                Upload Documents
              </div>
              <MdCloudUpload className="font-24 fw-600 password-clr" /> */}
            </div>

            {selectedGateway && (
              <>
                {selectedGateway.gateway_name === "phone_pe" ||
                selectedGateway.gateway_name === "g_pay" ||
                selectedGateway.gateway_name === "paytm" ? (
                  <div className="input-bg br-10 h-10vh p-2">
                    <div className="small-font clr-black">
                      Mobile Number: {selectedGateway.mobile_number}
                    </div>
                    <div className="small-font clr-black">
                      UPIID: {selectedGateway.upi_id}
                    </div>
                    <div className="small-font clr-black">
                      UPINAME: {selectedGateway.upi_name}
                    </div>
                  </div>
                ) : selectedGateway.gateway_name === "bankdetails" ? (
                  <div className="input-bg br-10 h-10vh p-2">
                    <div className="small-font clr-black">
                      Bank_Name: {selectedGateway.bank_name}
                    </div>
                    <div className="small-font clr-black">
                      Account Number: {selectedGateway.bank_acc_no}
                    </div>
                    <div className="small-font clr-black">
                      Bank_IFSC_Number: {selectedGateway.bank_ifsc_code}
                    </div>
                  </div>
                ) : selectedGateway.gateway_name === "qr_code" ? (
                  <div className="input-bg br-10 h-10vh p-2">
                    <div className="small-font clr-black">
                      QR_Name: {selectedGateway.qr_name}
                    </div>
                    <img
                      src={Images.BgBannerTwo || selectedGateway.qr_upload_path}
                      alt="QR Code"
                      className="mt-1 br-10 h-5h"
                    />
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      )}
      {selectedTourData?.slug === "casino-tour" ? (
        <div className="d-flex justify-content-between w-100 align-items-center">
          <div className="clr-black fw-100">
            Amount To Pay <br />{" "}
            <span className="fw-600">{finalPrice.toFixed(2)}</span>{" "}
          </div>
          <div
            className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2 w-50"
            onClick={() => handleBookingUser()}
          >
            Pay
          </div>
        </div>
      ) : (
        <div
          className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2"
          onClick={() => handleSportsBookingUser()}
        >
          Proceed To Pay
        </div>
      )}
    </div>
  );
}

export default PaymentDetails;
