import React, { useEffect, useState } from "react";

import { HiOutlineEye } from "react-icons/hi2";
import { BiMoneyWithdraw } from "react-icons/bi";
import {
  deleteUserDepositDetailsByID,
  deleteUserWithdrawDetailsByID,
  getUserDeposit,
  getUserDepositDetailsByID,
  getUserWithdrawDetailsByID,
  getWalletStatus,
  getWalletTable,
} from "../../api/apiMethods";
import Table from "../../components/Table";
import { IonCol, IonRow } from "@ionic/react";
import moment from "moment";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import AddAmountPopup from "./AddAmountPopup";
import ViewDepositViewPopup from "./ViewDepositViewPopup";
import EditDepositDetails from "./EditDepositDetails";
import Withdraw from "./Withdraw";
import ViewWithdrawlDetailsPopup from "./ViewWithdrawlDetailsPopup";
import EditWithdrawDetailsPopup from "./EditWithdrawDetailsPopup";
import DeletePopup from "./DeletePopup";

function Wallet() {
  const [activeBtn, setActiveBtn] = useState(0);
  const [withdraw, setWithdraw] = useState(false);
  const handleActiveBtn = (index) => {
    setActiveBtn(index);
  };
  const handleWithdrawPopupOpen = () => {
    setWithdraw(true);
  };

  const [userWalletStatsData, setUserWalletsData] = useState();
  console.log(userWalletStatsData, "userWalletStatsData");

  const userWalletStatus = () => {
    getWalletStatus()
      .then((response) => {
        if (response.status === true) {
          console.log(response.data);
          setUserWalletsData(response.data);
        } else {
          console.log("Some Error is Happening");
        }
      })
      .catch((error) => console.log(error));
  };
  const [userWalletWithdrawTable, setUserWalletWithdrawTable] = useState();
  const [userWalletDepositTable, setuserWalletDepositTable] = useState();
  console.log(userWalletDepositTable, "userWalletDepositTable");
  const userWalletTable = () => {
    getWalletTable()
      .then((response) => {
        if (response.status === true) {
          console.log(response.data);
          setUserWalletWithdrawTable(response.data.withdraw);
          setuserWalletDepositTable(response.data.deposit);
        } else {
          console.log("Some Error is Happening");
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    userWalletStatus();
    userWalletTable();
  }, []);
  const userDeposits = () => {
    getUserDeposit()
      .then((response) => {
        if (response.status === true) {
          console.log(response.data);
        } else {
          console.log("Some Error is Happening");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    userDeposits();
  }, []);
  const buttons = ["Deposit", "Withdraw"];
  const withdrawData = [
    {
      amount: userWalletStatsData?.totalDeposit,
      name: "Ttl Deposit",
    },
    {
      amount: userWalletStatsData?.totalWithdrawal,
      name: "Ttl Withdraw",
    },
    {
      amount: userWalletStatsData?.balance,
      name: "Balance",
      icon: <BiMoneyWithdraw />,
      withdraw: "Withdraw",
    },
  ];

  const TICKETS_COLUMNS = [
    {
      header: <div className="text-start">Date/Time</div>,
      field: "date_time",
    },
    { header: "Tnx", field: "tnx" },
    { header: "Amount", field: "amount" },
    { header: "Status", field: "status" },
  ];
  const [withdrawalDetails, setWithdrawalDetails] = useState(null);
  const [depositDetails, setDepositDetails] = useState(null);
  console.log(depositDetails, "depositDetails");

  console.log(withdrawalDetails, "withdrawalDetails");

  const [viewWithdrawDetails, setViewWIthdrawDetails] = useState(false);
  const [viewDepositDetails, setViewDepositDetails] = useState(false);

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [selectWithdrawId, setSelectedWithdrawId] = useState();

  const [selectDepositId, setSelectedDepositId] = useState();

  const [editWithdrawDetailPopup, setEditWithdrawDetailPopup] = useState(false);
  const [viewWithdrawMode, setViewWithdrawMode] = useState(true);
  const [viewDepositMode, setViewDepositMode] = useState(true);
  const [editDepositDetail, setEditDepositDetail] = useState(false);

  const getUserWithdrawData = (id) => {
    getUserWithdrawDetailsByID(id)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setWithdrawalDetails(response.data);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getUserWithdrawData();
  }, []);

  const getUserDepositData = (id) => {
    getUserDepositDetailsByID(id)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setDepositDetails(response.data);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getUserDepositData();
  }, []);
  const handleEditDepositDetailPopup = (id) => {
    getUserDepositData(id);
    setEditDepositDetail(true);
    setSelectedDepositId(id);
  };
  const handleEditWithdrawPopupOpen = (id) => {
    getUserWithdrawData(id);
    setEditWithdrawDetailPopup(true);
    setSelectedWithdrawId(id);
  };
  console.log(selectDepositId, "selectDepositId");
  const handleViewClick = (id) => {
    getUserWithdrawData(id);
    setSelectedWithdrawId(id);
    setViewWithdrawMode(true);
    setViewWIthdrawDetails(true);
  };

  const handleShowDeletePopup = (type, id) => {
    setShowDeletePopup(true);
    if (type === "withdraw") {
      setSelectedWithdrawId(id);
      setSelectedDepositId(null);
    } else if (type === "deposit") {
      setSelectedDepositId(id);
      setSelectedWithdrawId(null);
    }
  };

  const handleDepositViewClick = (id) => {
    getUserDepositData(id);
    setSelectedDepositId(id);
    setViewDepositDetails(true);
  };
  const deleteUserWithdrawData = () => {
    deleteUserWithdrawDetailsByID(selectWithdrawId)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setShowDeletePopup(false);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    deleteUserWithdrawData();
  }, []);
  const deleteUserdepositData = () => {
    deleteUserDepositDetailsByID(selectDepositId)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setShowDeletePopup(false);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    deleteUserdepositData();
  }, []);
  const handleConfirmDelete = () => {
    if (selectWithdrawId !== null) {
      deleteUserWithdrawData(selectWithdrawId);
    } else if (selectDepositId !== null) {
      deleteUserdepositData(selectDepositId);
    }
    setShowDeletePopup(false);
  };
  const WITHDRAWL_DATA =
    userWalletWithdrawTable &&
    userWalletWithdrawTable?.map((item) => ({
      date_time: (
        <div className="text-start">
          {moment(item.time_date).format("DD-MM-YYYY")}
          <br />
          {moment(item.time_date).format("hh:mm A")}
        </div>
      ),
      tnx: (
        <div className="d-flex flex-column">
          <span>{item.type}</span>
          <span>{item.tnx_id}</span>
        </div>
      ),

      amount: item.amount,
      status: (
        <div className="flex-column">
          <div className="flex-center w-100">
            <div
              className={`fit-width p-1 br-10 ${
                item.status === "approved"
                  ? "approved-btn "
                  : item.status === "pending"
                  ? "pending-bg pending-clr"
                  : item.status === "rejected"
                  ? "reject-bg reject-clr"
                  : ""
              }`}
            >
              {item.status}
            </div>
          </div>
          <div className="w-100 flex-center">
            <div className="d-flex w-75 justify-content-around align-items-center">
              <div className="icon-container cursor-pointer" title="View">
                <HiOutlineEye
                  className="large-font"
                  onClick={() => {
                    handleViewClick(item.withdraw_id);
                  }}
                />
              </div>
              <div className="icon-container cursor-pointer" title="Edit">
                <CiEdit
                  className="large-font"
                  onClick={() => {
                    handleEditWithdrawPopupOpen(item.withdraw_id);
                  }}
                />
              </div>
              <div className="icon-container cursor-pointer" title="Delete">
                <RiDeleteBin6Line
                  className="large-font"
                  onClick={() =>
                    handleShowDeletePopup("withdraw", item.withdraw_id)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ),
    }));
  const DEPOSIT_DATA =
    userWalletDepositTable &&
    userWalletDepositTable?.map((item) => ({
      date_time: (
        <div className="text-start">
          {moment(item.time_date).format("DD-MM-YYYY")}
          <br />
          {moment(item.time_date).format("hh:mm A")}
        </div>
      ),
      tnx: (
        <div className="d-flex flex-column">
          <span>{item.type}</span>
          <span>{item.tnx_id}</span>
        </div>
      ),
      amount: item.deposit_amount,
      view: <HiOutlineEye className="icon-font" />,
      status: (
        <div className="flex-column">
          <div className="flex-center w-100">
            <div
              className={`fit-width p-1 br-10 ${
                item.status === "approved"
                  ? "approved-btn "
                  : item.status === "pending"
                  ? "pending-bg pending-clr"
                  : item.status === "rejected"
                  ? "reject-bg reject-clr"
                  : ""
              }`}
            >
              {item.status}
            </div>
          </div>
          <div className="w-100 flex-center">
            <div className="d-flex w-75 justify-content-around align-items-center">
              <div className="icon-container cursor-pointer" title="View">
                <HiOutlineEye
                  className="large-font"
                  onClick={() => {
                    handleDepositViewClick(item.deposit_id);
                  }}
                />
              </div>
              <div className="icon-container cursor-pointer" title="Edit">
                <CiEdit
                  className="large-font"
                  onClick={() => {
                    handleEditDepositDetailPopup(item.deposit_id);
                  }}
                />
              </div>
              <div className="icon-container cursor-pointer" title="Delete">
                <RiDeleteBin6Line
                  className="large-font"
                  onClick={() =>
                    handleShowDeletePopup("deposit", item.deposit_id)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ),
    }));

  const [addAmountPopup, setShowAddAmountPopup] = useState(false);
  const handleAddAmountPopup = () => {
    setShowAddAmountPopup(true);
  };
  return (
    <div className="mt-2 p-1">
      <div className="flex-between w-100">
        <div className="d-flex font-14 w-50 fw-600">
          {buttons.map((btn, index) => (
            <div
              className={`w-50 text-center py-2 ms-3 rounded
        ${
          activeBtn === index
            ? "pink-bg text-white "
            : "white-bg text-black pointer"
        }`}
              onClick={() => handleActiveBtn(index)}
            >
              {btn}
            </div>
          ))}
        </div>
        <div className="flex-end w-40">
          {activeBtn === 0 ? (
            <div
              className="px-2 py-2 br-10 font-14 pink-bg clr-white cursor-pointer"
              onClick={handleAddAmountPopup}
            >
              Add Amount
            </div>
          ) : (
            <>
              <div
                className="px-2 py-2 br-10 font-14 pink-bg clr-white cursor-pointer"
                onClick={handleWithdrawPopupOpen}
              >
                Withdraw
              </div>
            </>
          )}
        </div>
      </div>

      <IonRow className="px-2 w-100 flex-between my-4">
        {withdrawData.map((item, index) => (
          <IonCol
            key={index}
            className="white-bg br-10 flex-center flex-column"
            size="3.8"
          >
            <div className="font-16 fw-bold text-black">{item.amount}</div>
            <div className="font-14 fw-500 text-hash">{item.name}</div>
          </IonCol>
        ))}
      </IonRow>
      <div className="p-1">
        {activeBtn === 1 && (
          <Table
            data={WITHDRAWL_DATA}
            columns={TICKETS_COLUMNS}
            itemsPerPage={10}
          />
        )}
        {activeBtn === 0 && (
          <Table
            data={DEPOSIT_DATA}
            columns={TICKETS_COLUMNS}
            itemsPerPage={10}
          />
        )}
      </div>

      <AddAmountPopup
        addAmountPopup={addAmountPopup}
        setShowAddAmountPopup={setShowAddAmountPopup}
      />
      <ViewDepositViewPopup
        selectDepositId={selectDepositId}
        viewDepositDetails={viewDepositDetails}
        setViewDepositDetails={setViewDepositDetails}
        viewDepositMode={viewDepositMode}
        setViewDepositMode={setViewDepositMode}
        depositDetails={depositDetails}
      />
      <EditDepositDetails
        editDepositDetail={editDepositDetail}
        setEditDepositDetail={setEditDepositDetail}
        depositDetails={depositDetails}
        userWalletStatsData={userWalletStatsData}
        selectDepositId={selectDepositId}
      />
      <Withdraw
        withdraw={withdraw}
        setWithdraw={setWithdraw}
        userWalletStatsData={userWalletStatsData}
      />
      <ViewWithdrawlDetailsPopup
        selectWithdrawId={selectWithdrawId}
        viewWithdrawDetails={viewWithdrawDetails}
        setViewWIthdrawDetails={setViewWIthdrawDetails}
        withdrawalDetails={withdrawalDetails}
        viewWithdrawMode={viewWithdrawMode}
        setViewWithdrawMode={setViewWithdrawMode}
      />
      <EditWithdrawDetailsPopup
        editWithdrawDetailPopup={editWithdrawDetailPopup}
        setEditWithdrawDetailPopup={setEditWithdrawDetailPopup}
        withdrawalDetails={withdrawalDetails}
        userWalletStatsData={userWalletStatsData}
        selectWithdrawId={selectWithdrawId}
      />
       <DeletePopup
        showConfirmationPopup={showDeletePopup}
        setShowConfirmationPopup={setShowDeletePopup}
        handleConfirm={handleConfirmDelete}
        description="Are You Want To Delete data from Here?"
      />
    </div>
  );
}

export default Wallet;
