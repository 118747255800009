import React, { useEffect, useState } from "react";
import { Images } from "../../images";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { IonCol, IonModal, IonRow } from "@ionic/react";
import { useNavigate } from "react-router";
import { FaHeart, FaTrash } from "react-icons/fa6";
import { FaCommentDots } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import UploadVideos from "../popups/UploadVideos";
import { HiDotsVertical } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import {
  getAllMemories,
  getMyMemories,
  postLike,
  dislike,
  memoryPrivacyRequest,
  deleteMemoryById,
} from "../../api/apiMethods";
import DeletePopup from "../popups/DeletePopup";
import moment from "moment";

function Memories() {
  const [like, setlike] = useState();
  const isAuthorized = localStorage?.getItem("isAuthorized");
  const login_userid = localStorage.getItem("user_id");
  const loginUserId = Number(login_userid);
  const [report, setreport] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();
  const [photoStatus, setPhotoStatus] = useState("");
  const [show, setShow] = useState(false);
  const [showMemoryId, setShowMemoryId] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = (memoryId, status) => {
    setShow(true);
    setShowMemoryId(memoryId);
    setreport(false);
    setPhotoStatus(status);
  };
  const handlereport = (memoryId) => {
    setreport(!report);
    setShowMemoryId(memoryId);
  };

  const [openUploadVideos, setShowOpenVideos] = useState(false);
  const [isVideoUpload, setIsVideoUpload] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const handleUploadVideos = () => {
    setShowOpenVideos(true);
    setIsVideoUpload(true);
  };
  const handleShowPhotos = () => {
    setIsVideoUpload(false);
    setShowOpenVideos(true);
  };

  const memoriesButtons = ["All Photos", "Latest", "Popular", "My Photos"];

  const [activeMemoryButton, setActiveMemoryButton] = useState(0);
  const handleMemoryButton = (index) => {
    setActiveMemoryButton(index);

    if (index === 1) {
      fetchAllMemories({ userMemories: "latest" });
    } else if (index === 2) {
      fetchAllMemories({ userMemories: "popular" });
    } else if (index === 3) {
      fetchAllMyMemories();
    } else {
      fetchAllMemories();
    }
  };

  const [getMemories, setGetMemories] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  console.log(videos, "videos");
  const fetchAllMemories = (userMemories = {}) => {
    getAllMemories({ userMemories })
      .then((response) => {
        if (response.status === true) {
          console.log(response, "res", userMemories);
          setGetMemories(response?.data?.record);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  useEffect(() => {
    fetchAllMemories();
  }, []);

  useEffect(() => {
    const imgData = [];
    const videosData = [];
    getMemories.forEach((memory) => {
      if (memory.type === "image") {
        imgData.push(memory);
      } else if (memory.type === "video") {
        videosData.push(memory);
      }
    });

    setPhotos(imgData);
    setVideos(videosData);
  }, [getMemories]);

  const postPrivacyReqMyPhotos = (memoryId, privacy) => {
    console.log(memoryId, privacy, "45678");
    memoryPrivacyRequest(memoryId, { privacy: privacy })
      .then((response) => {
        if (response.status === true) {
          console.log("privacy staus requset", response);
          handleClose();
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  const handleSubmitPrivacy = () => {
    const privacy =
      photoStatus === "public" ? "privaterequest" : "publicrequest";
    postPrivacyReqMyPhotos(showMemoryId, privacy);
  };

  const [myMemories, setMyMemories] = useState([]);
  console.log(myMemories, "user my photos");
  const fetchAllMyMemories = () => {
    getMyMemories({})
      .then((response) => {
        if (response.status === true) {
          console.log(response, "my user images");
          setMyMemories(response?.data);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  useEffect(() => {
    fetchAllMyMemories();
  }, []);

  const [likedStatus, setLikedStatus] = useState({});
  useEffect(() => {
    const initialLikeStatus = {};
    photos.forEach((photo) => {
      initialLikeStatus[photo.memory_id] = !!photo.like_id;
    });
    setLikedStatus(initialLikeStatus);
  }, [photos]);

  const handleLikes = (memoryId, likedId) => {
    if (likedStatus[memoryId]) {
      dislike(likedId)
        .then((response) => {
          if (response.status === true) {
            setLikedStatus((prevStatus) => ({
              ...prevStatus,
              [memoryId]: false,
            }));
            setPhotos((prevPhotos) =>
              prevPhotos.map((photo) =>
                photo.memory_id === memoryId
                  ? {
                      ...photo,
                      likes_count: photo.total_likes_count - 1,
                      like_id: null,
                    }
                  : photo
              )
            );
            fetchAllMemories();
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error?.message));
    } else {
      postLike({ memories_id: memoryId })
        .then((response) => {
          if (response.status === true) {
            setLikedStatus((prevStatus) => ({
              ...prevStatus,
              [memoryId]: true,
            }));
            setPhotos((prevPhotos) =>
              prevPhotos.map((photo) =>
                photo.memory_id === memoryId
                  ? {
                      ...photo,
                      likes_count: photo.total_likes_count + 1,
                      like_id: response?.data?.like_id,
                    }
                  : photo
              )
            );
            fetchAllMemories();
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error.message));
    }
  };

  const [selectedCard, setSelectedCard] = useState(null);
  const [videoSrc, setVideoSrc] = useState("");

  const handleVideo = (videoPath) => {
    setVideoSrc(videoPath);
  };

  const [videoPlay, setVideoPlay] = useState(false);

  const videoPlayModal = () => {
    setVideoPlay(true);
  };

  const [memoryDelId, setMemoryDelId] = useState(null);
  const handleDeletePopup = (memoryId) => {
    setShowConfirmationPopup(true);
    setMemoryDelId(memoryId);
  };

  const handleDeleteMemory = () => {
    deleteMemoryById(memoryDelId)
      .then((response) => {
        if (response.status === true) {
          setShowConfirmationPopup(false);
          fetchAllMyMemories();
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };
  console.log(memoryDelId, " DelMemoryId");

  return (
    <div className="input-bg homecontent-sec">
      <div className="px-2 py-1">
        <div className="d-flex justify-content-between flex-row align-items-center">
          <div className="fw-bold font-20">Tour Videos</div>
          <div
            className="font-16 clr-pink border-pink py-1 px-2 br-20 my-2"
            onClick={() => handleUploadVideos()}
          >
            Upload Videos
          </div>
        </div>
        <div className="d-flex flex-column">
          {
            <div className="video-player">
              {videoSrc ? (
                <video
                  className="video-container"
                  controls
                  src={videoSrc}
                  title="Selected Video"
                >
                  Your browser does not support the video tag.
                </video>
              ) : (
                <p className="no-video-text">
                  Select a tour to watch its video
                </p>
              )}
            </div>
          }
          {/* {videoPlayModal && (
            <IonModal
              isOpen={videoPlayModal}
              onDidDismiss={() => setVideoPlayModal(false)}
              canDismiss={true}
              centered
              className="custom-modal"
            >
              <div className="border-grey br-10">
                <div
                  className="flex-end"
                  onClick={() => setVideoPlayModal(false)}
                >
                  <IoClose className="font-30 clr-white" />
                </div>

                <div>
                  <iframe
                    src={
                      "https://www.youtube.com/embed/2DQ4arfP-ls?si=QqT2gJGkV10VVhtL" ||
                      videoPlaying
                    }
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: "100%", height: "500px", border: "none" }}
                  ></iframe>
                </div>
              </div>
            </IonModal>
          )} */}

          <IonRow
            className="horizontal-scroll-container"
            style={{ overflowX: "auto" }}
          >
            {videos.map((trip, i) => (
              <IonCol
                size="6"
                className={`p-2 bg-white clr-black d-flex radius-10 mb-2 mr-2 
                
                  ${selectedCard === i ? "border-pink2" : ""}
                }`}
                onClick={() => {
                  setSelectedCard(i);
                  handleVideo(trip?.path);
                }}
              >
                <img
                  class=""
                  src={trip?.path || Images.trip_img_01}
                  alt=""
                  // onClick={() => handleVideos(trip?.path)}
                />
                <div className="d-flex flex-column">
                  <div class="fw-bold mt-1">{trip.tour_title}</div>
                  <div className="mt-1">
                    <MdOutlineCalendarMonth class="mr-1" />
                    {moment(trip.created_at).format("DD-MM-YYYY")}
                  </div>
                </div>
              </IonCol>
            ))}
          </IonRow>
        </div>
      </div>
      <div className="white-bg p-1">
        <div className="d-flex justify-content-between flex-row align-items-center mt-2">
          <div className="fw-bold font-20">Tour Photos</div>
          <div
            className="font-16 clr-pink border-pink py-1 px-2 br-20 my-2"
            onClick={() => handleShowPhotos()}
          >
            Upload Photos
          </div>
        </div>
        <div>
          <IonRow className="d-flex justify-content-between my-1">
            {memoriesButtons.map((btn, index) => (
              <IonCol
                key={index}
                size="3"
                className={`text-center font-16 fw-bold br-20 py-1 ${
                  activeMemoryButton === index
                    ? "deep-grey-bg clr-white"
                    : "light-grey-bg photos-clr"
                }`}
                onClick={() => handleMemoryButton(index)}
              >
                {btn}
              </IonCol>
            ))}
          </IonRow>
        </div>
        {activeMemoryButton === 0 && (
          <div>
            <IonRow className="d-flex w-100">
              {photos.map((photo, index) => (
                <IonCol key={index} size="5.8" className="">
                  <img
                    src={photo?.path || Images.AllPhoto1}
                    className="w-100 m-1 h-17vh"
                    alt=""
                    onClick={() => navigate(`/likes/${photo?.memory_id}`)}
                  />

                  {photo?.user_id === loginUserId && (
                    <div
                      className="pos-dots clr-white"
                      onClick={() =>
                        handlereport(photo?.memory_id, photo?.memory_privacy)
                      }
                    >
                      <HiDotsVertical className="clr-white"/>
                    </div>
                  )}
                  {report === true &&
                    photo?.user_id === loginUserId &&
                    showMemoryId === photo?.memory_id && (
                      <div className="report-mode font-14 pointer d-flex flex-column">
                        <div
                          className="px-2 py-2"
                          onClick={() => handleShow(photo?.memory_id)}
                        >
                          {photo?.memory_privacy === "public"
                            ? "Make it Private"
                            : "Make it Public"}
                        </div>
                      </div>
                    )}
                  <div className="w-100 px-2 clr-white d-flex justify-content-between pos-like-sec">
                    <div
                      className="d-flex"
                      onClick={() =>
                        handleLikes(photo?.memory_id, photo?.like_id)
                      }
                    >
                      <FaHeart
                        className={`font-24 ${
                          likedStatus[photo?.memory_id]
                            ? "clr-red"
                            : "clr-white"
                        } mr-1`}
                      />

                      {photo?.total_likes_count}
                    </div>
                    <div className="d-flex">
                      <FaCommentDots className="font-24 clr-white mr-1" />

                      {photo?.comments_count}
                    </div>
                  </div>
                </IonCol>
              ))}
            </IonRow>
          </div>
        )}
        {activeMemoryButton === 1 && (
          <div>
            <IonRow className="d-flex w-100">
              {photos.flat().map((photo, index) => (
                <IonCol key={index} size="6" className="">
                  <img
                    src={photo?.path || Images?.AllPhoto2}
                    className="w-100 m-1 h-17vh"
                    alt=""
                    onClick={() => navigate("/likes")}
                  />

                  {photo?.user_id === loginUserId && (
                    <div
                      className="pos-dots clr-black"
                      onClick={() =>
                        handlereport(photo?.memory_id, photo?.memory_privacy)
                      }
                    >
                      <HiDotsVertical className="clr-white"/>
                    </div>
                  )}
                  {report === true &&
                    photo?.user_id === loginUserId &&
                    showMemoryId === photo?.memory_id && (
                      <div className="report-mode font-14 pointer d-flex flex-column">
                        <div
                          className="px-2 py-2"
                          onClick={() => handleShow(photo?.memory_id)}
                        >
                          {photo?.memory_privacy === "public"
                            ? "Make it Private"
                            : "Make it Public"}
                        </div>
                      </div>
                    )}

                  <div className="w-90 px-2 clr-white d-flex justify-content-between pos-like-sec">
                    <div
                      className="d-flex"
                      onClick={() =>
                        handleLikes(photo?.memory_id, photo?.like_id)
                      }
                    >
                      <FaHeart
                        className={`font-24 ${
                          likedStatus[photo?.memory_id]
                            ? "clr-red"
                            : "clr-white"
                        } mr-1`}
                      />

                      {photo?.total_likes_count}
                    </div>
                    <div className="d-flex">
                      <FaCommentDots
                        className="font-24
                          clr-white mr-1"
                      />

                      {photo?.comments_count}
                    </div>
                  </div>
                </IonCol>
              ))}
            </IonRow>
          </div>
        )}
        {activeMemoryButton === 2 && (
          <div>
            <IonRow className="d-flex w-100">
              {photos.map((photo, index) => (
                <IonCol key={index} size="6" className="pos-relative">
                  <img
                    src={photo?.path || Images.AllPhoto3}
                    className="w-100 m-1 h-17vh"
                    alt="popular"
                    onClick={() => navigate("/likes")}
                  />

                  {photo?.user_id === loginUserId && (
                    <div
                      className="pos-dots clr-black"
                      onClick={() =>
                        handlereport(photo?.memory_id, photo?.memory_privacy)
                      }
                    >
                      <HiDotsVertical className="clr-white"/>
                    </div>
                  )}
                  {report === true &&
                    photo?.user_id === loginUserId &&
                    showMemoryId === photo?.memory_id && (
                      <div className="report-mode font-14 pointer d-flex flex-column">
                        <div
                          className="px-2 py-2"
                          onClick={() => handleShow(photo?.memory_id)}
                        >
                          {photo?.memory_privacy === "public"
                            ? "Make it Private"
                            : "Make it Public"}
                        </div>
                      </div>
                    )}

                  <div className="w-90 px-2 clr-white d-flex justify-content-between pos-like-sec">
                    <div
                      className="d-flex"
                      onClick={() =>
                        handleLikes(photo?.memory_id, photo?.like_id)
                      }
                    >
                      <FaHeart
                        className={`font-24 ${
                          likedStatus[photo?.memory_id]
                            ? "clr-red"
                            : "clr-white"
                        } mr-1`}
                      />

                      {photo?.total_likes_count}
                    </div>

                    <div className="d-flex">
                      <FaCommentDots
                        className="font-24
                          clr-white mr-1"
                      />

                      {photo?.comments_count}
                    </div>
                  </div>
                </IonCol>
              ))}
            </IonRow>
          </div>
        )}
        {activeMemoryButton === 3 && (
          <div>
            <IonRow className="d-flex w-100">
              {myMemories.flat().map((photo, index) => (
                <IonCol key={index} size="6" className="">
                  <img
                    src={photo?.path || Images.AllPhoto3}
                    className="w-100 m-1 h-17vh"
                    alt="popular"
                  />

                  <div className="delete-icon clr-black">
                    <FaTrash
                      onClick={() => handleDeletePopup(photo?.memory_id)}
                    />
                  </div>

                  <div
                    className="pos-dots clr-black"
                    onClick={() =>
                      handlereport(photo?.memory_id, photo?.memory_privacy)
                    }
                  >
                    <HiDotsVertical />
                  </div>
                  {report === true && showMemoryId === photo?.memory_id && (
                    <div className="report-mode font-14 pointer d-flex flex-column">
                      <div
                        className="px-2 py-2"
                        onClick={() => handleShow(photo?.memory_id)}
                      >
                        {photo?.memory_privacy === "private"
                          ? "Make it Public"
                          : "Make it Private"}
                      </div>
                    </div>
                  )}
                </IonCol>
              ))}
            </IonRow>
          </div>
        )}
      </div>
      <UploadVideos
        showUploadPopup={openUploadVideos}
        setShowUploadPopup={setShowOpenVideos}
        isVideoUpload={isVideoUpload}
      />

      <DeletePopup
        showConfirmationPopup={showConfirmationPopup}
        setShowConfirmationPopup={setShowConfirmationPopup}
        handleConfirm={handleDeleteMemory}
        description="Are you sure to delete your memeory from your private folder"
      />

      <div>
        <IonModal
          isOpen={show}
          size=""
          onDidDismiss={handleClose}
          centered
          // className="make-privacy br-10 border-grey"
          className="custom-modal"
        >
          <div className="white-bg p-2 br-20">
            <div className="d-flex flex-end mx-2 my-2 clr-black ">
              <IoMdClose className="font-24" onClick={handleClose} />
            </div>
            <div className="mx-2 text-center clr-black">{`Do You Want to Post Your Photo In ${
              photoStatus === "public" ? "Private" : "Public"
            } View?`}</div>
            <div className="d-flex flex-between justify-content-center mb-3">
              <div
                className="cursor pink-bg px-2 w-30 py-2 br-5 mx-2 text-center clr-white "
                onClick={handleSubmitPrivacy}
              >
                Yes
              </div>
              <div
                className="cursor input-bgg w-30 px-2 py-2 br-5 mx-2 text-center"
                onClick={handleClose}
              >
                No
              </div>
            </div>
          </div>
        </IonModal>
      </div>
    </div>
  );
}

export default Memories;
