import React from "react";
import { IonRow, IonCol } from "@ionic/react";
import "./styles.css";

function Table(props) {
  const { data, columns, title } = props;

  const columnSize = Math.floor(12 / columns.length);

  return (
    <div style={{ width: "100%" }}> {/* Ensures full width for the table */}
      {title && (
        <IonRow className="py-2">
          <IonCol size="12" className="text-center fw-600">
            {title}
          </IonCol>
        </IonRow>
      )}
      <IonRow className="border-top font-16 d-flex align-items-center white-bg py-2">
        {columns?.map((column, index) => (
          <IonCol key={index} className="text-center fw-600 ">
            {column.header}
          </IonCol>
        ))}
      </IonRow>

      {data?.length > 0 &&
        data.map((row, rowIndex) => (
          <IonRow key={rowIndex} className="border-top border-bottom-grey d-flex align-items-center">
            {columns.map((column, colIndex) => (
              <IonCol
                key={colIndex}
                className="py-2 font-14 text-center fw-600"
              >
                {row[column.field]}
              </IonCol>
            ))}
          </IonRow>
        ))}
    </div>
  );
}

export default Table;
