import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { IonCol, IonRow } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";
import { FaCheckCircle, FaHourglassHalf, FaTimesCircle } from "react-icons/fa";
import Documents from "../popups/Documents";

function CompletetedTrips({
  handleDocuments,
  showDocuments,
  setShowDocumnets,
  completedTours,
  formatDateToIndian,
}) {
  console.log(
    completedTours,
    "==================>upComingata<=================="
  );

  return (
    <div className="p-2">
      <div className="white-bg my-1">
        <h4 className="password-clr px-2 my-1 py-1 fw-700">Completed Tours</h4>
        <div className="overflow-auto p-1">
          <IonRow class="password-clr font-16 fw-600 border-grey">
            <IonCol size="3" className="text-center">
              S NO
            </IonCol>
            <IonCol size="4" className="text-start">
              Tour Title
            </IonCol>
            <IonCol size="3" className="text-center">
              net P/L
            </IonCol>
            <IonCol size="2" className="text-center">
              Docs
            </IonCol>
          </IonRow>
          {completedTours?.map((tour, index) => (
            <IonRow
              className="py-1 password-clr font-14 fw-500 border-bottom-grey d-flex align-items-center"
              key={index}
            >
              <IonCol size="3" className="text-center">
                1<div>{formatDateToIndian(tour.schedule_from)}</div>
              </IonCol>
              <IonCol size="4" className="text-center">
                <div className="d-flex flex-column">
                  <div>{tour.tour_title}</div>
                </div>
              </IonCol>
              <IonCol size="3" className="text-center">
                <div className="clr-green">{tour.total_price}</div>
                {/* <AiFillEye
                    className="font-30 clr-pink"
                  /> */}
              </IonCol>
              <IonCol
                size="2"
                className="grey-text"
                onClick={() => handleDocuments(tour)}
              >
                
                VIew
              </IonCol>
            </IonRow>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CompletetedTrips;
