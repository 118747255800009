import axios from "axios";

const baseUrl = "http://localhost:5001";
const jwt_token = localStorage.getItem("jwt_token");



const personalPhotos = {
 requestForPersonalPhotos: async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/memories/request-access/${id}`,
        {},
        
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },
  acceptPersonalPhotosRequest: async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/memories/accept-request/${id}`,
        
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },
  rejectPersonalPhotosRequest: async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/memories/reject-request/${id}`,
        
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },

  getThePersonalPhotos: async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/memories/access/${id}`,
        
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },





 

};

export default personalPhotos;
