import React from "react";
import { FaThumbsUp } from "react-icons/fa";
import { IonModal } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";

function SubmitPopup({ successPopup, setSuccessPopup, heading, title }) {
  const handleSubmitClose = () => {
    setSuccessPopup(false);
  };

  return (
    <IonModal
      isOpen={successPopup}
      // cssClass="otp-popup auto-height-modal"
      // backdropDismiss={false}
      className="custom-modal"
    >
      <div className="white-bg p-3">
        <div className="text-end" onClick={() => handleSubmitClose()}>
          <RxCross2 className="font-30" />
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div className="w-30 green-bg p-4 text-center br-50">
            <FaThumbsUp className="clr-white font-40" />
          </div>
        </div>

        <h2 className="password-clr w-100 text-center fw-600">{heading}</h2>
        <div className="font-14 fw-500 w-100 text-center grey-text">
          {title}
        </div>

        <div
          className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2"
          onClick={() => handleSubmitClose()}
        >
          Go Back
        </div>
      </div>
    </IonModal>
  );
}

export default SubmitPopup;
