import React, { useEffect, useRef, useState } from "react";
import {
  updateImageById,
  updateUserProfile,
  uploadImage,
  userEditProfile,
} from "../../api/apiMethods";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Images } from "../../images";
import { RiArrowLeftLine } from "react-icons/ri";
import { IoAddCircleOutline } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";
import { uploadFilesToS3 } from "../../images/images";
import { setUserDetailsData } from "../../redux/action";

function EditProfile() {
  const [error, setError] = useState();
  const fileInputRef = useRef();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const [editProfileData, setEditProfileData] = useState({
    name: "",
    phone: "",
    country_code: "",
    profile_image: "",
    upload_id: "",
  });
  const userDetailsData = useSelector((state) => state?.userDetailsData);
  console.log(editProfileData, "editProfileData");
  console.log(userDetailsData, "userDetailsData");

  useEffect(() => {
    if (userDetailsData) {
      setEditProfileData({
        name: userDetailsData?.name || "",
        phone: userDetailsData?.phone || "",
        country_code: userDetailsData?.country_code || "",
        profile_image: userDetailsData?.profile_image || "",
        upload_id: userDetailsData?.upload_id || "",
      });
    }
  }, [userDetailsData]);

  const [uploadProgress, setUploadProgress] = useState({});
  const [loading, setLoading] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(
    userDetailsData?.profile_image || Images.profile_img
  );
  const [uploadProfileImg, setUploadProfileImg] = useState();
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setEditProfileData((prev) => ({
      ...prev,
      [name]:
        name === "phone" ? value.slice(0, 10) : files ? files[0].name : value,
    }));
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    console.log("fileee", file);
    if (file) {
      try {
        const url = await uploadFilesToS3([file], setUploadProgress);

        console.log([url], "urllll");

        if (!url) throw new Error("Image upload failed");

        const uploadData = editProfileData.upload_id
          ? updateImageById(editProfileData.upload_id, {
              path: url[0],
              type: "image",
            })
          : uploadImage({ path: [url], type: "image" });

        const response = await uploadData;

        if (response?.status) {
          setEditProfileData((prevData) => ({
            ...prevData,
            profile_image: url[0],
            upload_id: editProfileData.upload_id || response.data[0]?.id,
          }));

          setProfileImageUrl(url);
        } else {
          throw new Error("Failed to upload/update image");
        }
      } catch (err) {
        console.error(
          "Image upload failed:",
          err.response?.data || err.message || "Unexpected error"
        );
      }
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleCountryCodeChange = (e) => {
    setEditProfileData((prev) => ({
      ...prev,
      country_code: e.target.value,
    }));
  };
  const handleSubmit = async () => {
    try {
      const uploadProfileId = userDetailsData?.upload_id;

      const response = await updateUserProfile({
        name: editProfileData?.name || userDetailsData?.name,
        country_code:
          editProfileData?.country_code || userDetailsData?.country_code,
        phone: editProfileData?.phone || userDetailsData?.phone,
        upload_id: uploadProfileId,
      });

      if (response?.status === true) {
        setMessage({
          text: "Your Profile Updated Successfully",
          type: "success",
        });

        setUserDetailsData((prevDetails) => ({
          ...prevDetails,
          name: editProfileData?.name || prevDetails.name,
          country_code:
            editProfileData?.country_code || prevDetails.country_code,
          phone: editProfileData?.phone || prevDetails.phone,
          profile_image:
            editProfileData?.profile_image || prevDetails.profile_image,
        }));

        setEditProfileData({});
        setTimeout(() => {
          setMessage("");
        }, 500);
      } else {
        setError("Something went wrong while updating the profile");
      }
    } catch (error) {
      setError(error?.message || "An error occurred");
      setMessage({ text: "Your Profile not Updated", type: "error" });
    }
  };
  const countryCodes = [
    { code: "+91", name: "India" },
    { code: "+971", name: "Dubai" },
    { code: "+1", name: "USA" },
    { code: "+44", name: "UK" },
    { code: "+61", name: "Australia" },
  ];

  return (
    <>
      <div>
        <div className="pink-bg h-6vh clr-white d-flex flex-between align-items-center">
          <div>
            <span onClick={() => navigate("/home")}>
              <RiArrowLeftLine className="font-30 mx-2" />
            </span>
          </div>
          <div>
            <h5 className="mr-4">Edit Profile</h5>
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="flex-center mt-2 postion-relative ">
            <img
              src={editProfileData.profile_image}
              alt="profile"
              className="img-height mt-3 mb-3 br-50"
            />

            <div className="clr-lightpink bg-addicon edit-icon">
              <IoMdAdd className="font-30" onClick={handleIconClick} />
            </div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <div className="d-flex flex-column">
            <div className="password-clr fw-600 font-16 mx-2 px-1">Name</div>
            <input
              type="text"
              placeholder="Enter Your Name"
              className="input-box mx-2 br-5 py-custom w-fill fw-500 mt-1 px-2"
              name="name"
              value={
                editProfileData?.name !== undefined
                  ? editProfileData?.name
                  : userDetailsData?.name || ""
              }
              onChange={handleChange}
            />
          </div>
          <div className="d-flex flex-column mt-2">
            <div className="password-clr fw-600 font-16 mx-2 mb-2 px-1 mt-2">
              Phone number
            </div>
            <div className="d-flex">
              <select
                className="input-box br-5 py-custom mx-2 px-2 w-34 fw-500 mt-1 "
                value={
                  editProfileData?.country_code ||
                  userDetailsData?.country_code ||
                  ""
                }
                onChange={handleCountryCodeChange}
              >
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name} ({country.code})
                  </option>
                ))}
              </select>

              <input
                type="tel"
                placeholder="Enter Phone Number"
                className="input-box br-5 py-custom mx-2 w-fill fw-500 mx-2 px-2 mt-1 w-57"
                name="phone"
                value={
                  editProfileData?.phone !== undefined
                    ? editProfileData?.phone
                    : userDetailsData?.phone || ""
                }
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="footer d-flex flex-column flex-center">
          <div
            className="border pink-bg w-90 text-center clr-white py-2 br-20 font-20"
            onClick={handleSubmit}
          >
            Save
          </div>
          {message && (
            <span
              className={` py-3 ${
                message?.type === "success" ? "clr-green" : "clr-red"
              }`}
            >
              {message.text}
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default EditProfile;
