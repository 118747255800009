import { IonModal } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import chatting from "../../api/chatting";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck, FaCheckDouble } from "react-icons/fa";
import {
  setChangeHeader,
  setMessageContent,
  setSelectedMessageId,
} from "../../redux/action";
import {
  connectSocket,
  disconnectSocket,
  onEvent,
  emitEvent,
  offEvent,
} from "./../../utils/socketio";
import { RxCross2 } from "react-icons/rx";
import moment from "moment";

function ChatScreen() {
  const id = useParams();
  const selectedUser = useSelector((state) => state.selectedUser);
  const deleteMessage = useSelector((state) => state.deleteMessage);
  const myId = localStorage.getItem("userId");
  const [userMessages, setUserMessages] = useState([]);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [longPressTimeout, setLongPressTimeout] = useState(null);
  const sendMessageText = useSelector((state) => state.sendMessageUpdate);
  const [socketConnected, setSocketConnected] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [selectedMediaUrl, setSelectedMediaUrl] = useState(null);

  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);

  const openSelectedImage = (url) => {
    setShowImage(true);
    setSelectedMediaUrl(url);
  };

  console.log(selectedMediaUrl, "==>selectedMediaUrl");
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [userMessages]);
  const handleChatMessages = async () => {
    try {
      const response = await chatting.getChatMessages(selectedUser.userId);

      const filteredMessages = response.data.filter(
        (message) => !message.deletedBy
      );

      setUserMessages(filteredMessages);
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    }
  };

  useEffect(() => {
    handleChatMessages();
  }, [id, deleteMessage]);
  console.log(deleteMessage, "==>deleteMessage");

  useEffect(() => {
    if (sendMessageText) {
      const messageWithTimestamp = {
        ...sendMessageText,
        timestamp: sendMessageText.timestamp || new Date().toISOString(),
      };

      setUserMessages((prevMessages) => [
        ...prevMessages,
        messageWithTimestamp,
      ]);
    }
  }, [sendMessageText]);

  useEffect(() => {
    let registeredHandler;
    let messageHandler;

    if (selectedUser.userId) {
      if (!socketConnected) {
        connectSocket();
        emitEvent("register", selectedUser.userId);

        registeredHandler = (data) => {
          console.log("User registered 123:", data);
        };

        messageHandler = (message) => {
          console.log("New message received:", message);
          setUserMessages((prevMessages) => [...prevMessages, message]);
        };

        offEvent("registered", registeredHandler);
        onEvent("registered", registeredHandler);

        offEvent("chat:receiveMessage", messageHandler);
        onEvent("chat:receiveMessage", messageHandler);

        console.log(`Connected with user: ${selectedUser.userId}`);
        setSocketConnected(true);
      }
    } else if (socketConnected) {
      disconnectSocket();
      setSocketConnected(false);
      console.log("Disconnected due to no chatting user");
    }

    return () => {
      if (socketConnected) {
        offEvent("registered", registeredHandler);
        offEvent("chat:receiveMessage", messageHandler);
        disconnectSocket();
        setSocketConnected(false);
        console.log(`Disconnected from user: ${selectedUser.userId}`);
      }
    };
  }, [selectedUser, socketConnected]);

  useEffect(() => {
    setSelectedMessageIndex(null);
  }, [deleteMessage]);
  const handleLongPressStart = (index, content, id) => {
    const timeout = setTimeout(() => {
      setSelectedMessageIndex(index);
      dispatch(setChangeHeader(true));
      dispatch(setMessageContent({ id: id, content: content }));
      console.log(index, content, id, "===>message details in chat screen");
    }, 1000);
    setLongPressTimeout(timeout);
  };

  const handleLongPressEnd = () => {
    clearTimeout(longPressTimeout);
  };

  const handleMessageClick = (index) => {
    if (selectedMessageIndex === index) {
      setSelectedMessageIndex(null);
      dispatch(setChangeHeader(false));
    }
  };

  const handleDeleteForMe = () => {
    const updatedMessages = userMessages.filter(
      (msg, index) => index !== selectedMessageIndex
    );
    setUserMessages(updatedMessages);
    setShowDeletePopup(false);
    setSelectedMessageIndex(null);
  };

  const handleDeleteFromBothSides = () => {
    const updatedMessages = userMessages.filter(
      (msg, index) => index !== selectedMessageIndex
    );
    setUserMessages(updatedMessages);
    setShowDeletePopup(false);
    setSelectedMessageIndex(null);
  };

  return (
    <div>
      <div className="d-flex flex-column chat-scroll px-4">
        <div>
          <>
            {userMessages.length > 0 &&
              userMessages.map((msg, index) => {
               
                const messageDate = moment(msg.timestamp).format("YYYY-MM-DD");
                const today = moment().format("YYYY-MM-DD");
                const yesterday = moment()
                  .subtract(1, "days")
                  .format("YYYY-MM-DD");

                const prevMessageDate =
                  index > 0
                    ? moment(userMessages[index - 1].timestamp).format(
                        "YYYY-MM-DD"
                      )
                    : null;

                const displayDate = messageDate !== prevMessageDate;
                const displayDateText =
                  messageDate === today
                    ? "Today"
                    : messageDate === yesterday
                    ? "Yesterday"
                    : moment(msg.timestamp).format("MMMM DD, YYYY");

                return (
                  <React.Fragment key={index}>
                    {displayDate && (
                      <div className="text-center font-weight-bold mt-3 mb-2">
                        <span className="font-10">{displayDateText}</span>
                      </div>
                    )}

                    <div
                      className={`d-flex flex-column w-100 position-relative mt-3 ${
                        selectedMessageIndex === index
                          ? "highlighted-message"
                          : ""
                      }`}
                      onClick={() => handleMessageClick(index)}
                    >
                      <div
                        className={
                          Number(msg?.senderId) === Number(myId)
                            ? "w-100 d-flex justify-content-start"
                            : "w-100 d-flex justify-content-end"
                        }
                        onTouchStart={() =>
                          handleLongPressStart(index, msg?.content, msg?.id)
                        }
                        onTouchEnd={handleLongPressEnd}
                      >
                        <div className="d-flex flex-column">
                          <div
                            className={`${
                              selectedMessageIndex === index
                                ? "highlighted-message"
                                : ""
                            } ${
                              Number(msg?.senderId) === Number(myId)
                                ? "grey-text grey-bg2 font-14 p-2 br-10 d-flex flex-column"
                                : "outgoing-chat-clr lightgreen-bg font-14 p-2 br-10"
                            }`}
                          >
                            {msg.messageType === "image" ||
                            (msg.content && msg.content.includes("http")) ? (
                              <img
                                src={msg.mediaUrl || msg.content}
                                alt="Message Content"
                                className="chatImagesize"
                                onError={(e) =>
                                  (e.target.style.display = "none")
                                }
                                onClick={() =>
                                  openSelectedImage(msg.mediaUrl || msg.content)
                                }
                              />
                            ) : (
                              <div>{msg.content}</div>
                            )}
                            <span className="font-10">
                              {msg.edited === true ? "edited" : ""}
                            </span>
                          </div>
                          <div>
                            <div className="grey-text font-12 d-flex flex-between mx-2">
                              <span className="timestamp font-14">
                                {new Date(msg.timestamp).toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  }
                                )}
                              </span>
                              {Number(msg?.senderId) !== Number(myId) && (
                                <FaCheckDouble className="font-10" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </>

          <div ref={messagesEndRef} />

          <IonModal
            isOpen={showDeletePopup}
            onDidDismiss={() => setShowDeletePopup(false)}
            cssClass="otp-popup auto-height-modal"
          >
            <div className="d-flex flex-column justify-content-center align-items-center p-2">
              <span
                className="font-14 border-grey border-none py-2 hover-effect cursor-pointer"
                onClick={() => setShowDeletePopup(false)}
              >
                Cancel
              </span>
              <span
                className="font-14 border-grey border-none py-2 hover-effect cursor-pointer"
                onClick={handleDeleteForMe}
              >
                Delete for Me
              </span>
              <span
                className="font-14 border-grey border-none py-2 hover-effect cursor-pointer"
                onClick={handleDeleteFromBothSides}
              >
                Delete from Both Sides
              </span>
            </div>
          </IonModal>
          <IonModal
            isOpen={showImage}
            onDidDismiss={() => setShowImage(false)}
            cssClass="show-image auto-height-modal"
          >
            <div className="show-image-bg">
              <div className="w-100 d-flex justify-content-end">
                <RxCross2
                  className="font-24 grey-text cursor-pointer"
                  onClick={() => setShowImage(false)}
                />
              </div>
              <div>
                <img src={selectedMediaUrl} alt="Error To Show Image" />
              </div>
            </div>
          </IonModal>
        </div>
      </div>
    </div>
  );
}

export default ChatScreen;
