import { IonModal } from "@ionic/react";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

function DeletePopup({
  showConfirmationPopup,
  setShowConfirmationPopup,
  handleConfirm,
  description,
}) {
  return (
    <IonModal
    isOpen={showConfirmationPopup}
    onDidDismiss={() => setShowConfirmationPopup(false)}
    className="custom-modal"
    centered
    >
      <div className="w-100 px-2 white-bg d-flex flex-column justify-content-center align-items-center br-5">
        <div className="w-100 text-end">
          <RxCross2
            className="clr-black font-20 cursor-pointer mt-1"
            onClick={() => setShowConfirmationPopup(false)}
          />
        </div>
        <div className="my-3">
          <AiOutlineDelete className="big-delete-icon clr-danger" />
        </div>
        <div className="w-100 d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex w-85 flex-column  password-clr text-center my-2 medium-font fw-600">
            {description}
          </div>
          <div className="my-2 d-flex w-100">
            <div
              className="br-20 w-50 fw-600 medium-font text-center py-2 m-2 pink-bg clr-white px-2 cursor-pointer"
              onClick={handleConfirm}
            >
              Yes
            </div>
            <div className="br-20 w-50 fw-600 medium-font text-center py-2 m-2 px-2 white-bg grey-text border-greyy cursor-pointer"
            onClick={() => setShowConfirmationPopup(false)}>
              No
            </div>
          </div>
        </div>
      </div>
    </IonModal>
  );
}

export default DeletePopup;
