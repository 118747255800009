import React from "react";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

function Successful() {
  return (
    <div className="mt-2 px-2">
      <div className="d-flex justify-content-center w-100 my-2">
        <IoCheckmarkCircleSharp
          className="font-40 clr-green"
          style={{ fontSize: "100px" }}
        />
      </div>
      <div className="px-2">
        <h3 className="w-100 password-clr text-center fw-700 my-1">
          Congratulation!
        </h3>
        <h5 className="w-100 password-clr text-center fw-700 my-1">
          Your Booking is Completed
        </h5>
        <span className="font-10 grey-text my-1 fw-600">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries,
        </span>
        <div className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2">
          Go Back to Message
        </div>
      </div>
    </div>
  );
}

export default Successful;
