import React, { useState } from "react";
import { IonModal } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";
import { updatePassword } from "../../api/apiMethods";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function ForgotPassword({
  userId,
  showForgotPassword,
  setShowForgotPassword,
  handleLoginModal,
}) {
  const handlePasswordClose = () => {
    setShowForgotPassword(false);
  };
  const [forgotPassword, setForgotPassword] = useState();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setForgotPassword({
      ...forgotPassword,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdatePassword = () => {
    updatePassword({
      userId,
      new_password: forgotPassword?.new_password,
      confirm_password: forgotPassword?.confirm_password,
    })
      .then((response) => {
        if (response.status === true) {
          console.log(response.data);
          setForgotPassword({});
          setTimeout(() => {
            handleLoginModal();
          }, 3000);
        } else {
          setError("Something Went Wrong");
        }
      })
      .catch((error) => setError(error?.message));
  };
  return (
    <IonModal
      isOpen={showForgotPassword}
      onDidDismiss={() => handlePasswordClose()}
      className="custom-modal"
    >
      <div className="white-bg p-3 br-20">
        <div className="text-end" onClick={() => handlePasswordClose()}>
          <RxCross2 className="font-30" />
        </div>
        <h2 className="password-clr w-100 text-center fw-600">
          Forgot Password
        </h2>

        <div className="d-flex flex-column">
          <div className="black-text font-16 fw-600 my-1">
            New Password <span className="star">*</span>
          </div>
          <div className="input-bg br-10 my-1 d-flex align-items-center">
            <input
              type={showNewPassword ? "text" : "password"}
              name="new_password"
              placeholder="Enter New Password"
              className="grey-text w-100 p-2 bg-none"
              value={forgotPassword?.new_password}
              onChange={handleChange}
            />
            {showNewPassword ? (
              <AiFillEyeInvisible
                className="font-30 clr-grey cursor-pointer"
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
            ) : (
              <AiFillEye
                className="font-30 clr-grey cursor-pointer"
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
            )}
          </div>
        </div>

        <div className="d-flex flex-column">
          <div className="black-text font-16 fw-600 my-1">
            Confirm Password <span className="star">*</span>
          </div>
          <div className="input-bg br-10 my-1 d-flex align-items-center">
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              placeholder="Confirm New Password"
              className="grey-text w-100 p-2 bg-none"
              value={forgotPassword?.confirm_password}
              onChange={handleChange}
            />
            {showConfirmPassword ? (
              <AiFillEyeInvisible
                className="font-30 clr-grey cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            ) : (
              <AiFillEye
                className="font-30 clr-grey cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            )}
          </div>
        </div>
        <div
          className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2"
          onClick={handleUpdatePassword}
        >
          Update Password
        </div>
        {error && (
          <div className="red-text font-14 text-center mt-2">{error}</div>
        )}
      </div>
    </IonModal>
  );
}

export default ForgotPassword;
