import { IonModal } from "@ionic/react";
import React, { useState } from "react";
import { FaThumbsUp } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import TotalExpenesesPopup from "./TotalExpenesesPopup";

function CancelPopup({
  showCancel,
  setShowCancel,
  heading,
  title,
  bookingIdToCancel,
}) {
  const handleCancelClose = () => {
    setShowCancel(false);
  };
  const [showExpense, setShowExpense] = useState(false);
  const handleExpenseButton = () => {
    setShowExpense(true);
    setShowCancel(false);
  };
  return (
    <>
      <IonModal
        isOpen={showCancel}
        // cssClass="cancel-popup auto-height-modal"
        // backdropDismiss={false}
        className="custom-modal"
      >
        <div className="white-bg p-3 br-10">
          <div className="text-end" onClick={() => handleCancelClose()}>
            <RxCross2 className="font-30" />
          </div>

          <div className="password-clr w-100 text-center fw-600 font-14">{heading}</div>
          <div className="font-14 fw-500 w-100 text-center grey-text">
            {title}
          </div>
          <div className="d-flex flex-row w-100 justify-content-between align-items-center">
            <div
              className="pink-bg w-48 py-2 br-20 clr-white fw-700 text-center my-2"
              onClick={() => handleExpenseButton()}
            >
              Yes
            </div>
            <div
              className="pink-bg w-48 py-2 br-20 clr-white fw-700 text-center my-2"
              onClick={() => handleCancelClose()}
            >
              No
            </div>
          </div>
        </div>
      </IonModal>
      <TotalExpenesesPopup
        bookingIdToCancel={bookingIdToCancel}
        showExpense={showExpense}
        setShowExpense={setShowExpense}
      />
    </>
  );
}

export default CancelPopup;
