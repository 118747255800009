import { useEffect, useState } from "react";
import { IonCol, IonImg, IonRow } from "@ionic/react";
import { IoLocationOutline } from "react-icons/io5";
import { FcLike } from "react-icons/fc";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getBestDestination, getTourCategories } from "../../api/apiMethods";
import { Images } from "../../images";
import ChatButton from "../chat/ChatButton";
import moment from "moment";

function HomeContentTwo() {
  const [categories, setCategories] = useState([]);
  const [bestDestinationData, setBestDestinationData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jwt_token = localStorage.getItem("jwt_token");

  useEffect(() => {
    getTourCategories()
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Getting Data From Tourategories");
          setCategories(response?.data?.category);
        } else {
          console.log("something is Happening");
        }
      })
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    getBestDestination()
      .then((response) => {
        if (response?.status) {
          setBestDestinationData(response.data || []);
        }
      })
      .catch((error) => console.error(error?.message));
  }, []);
  const splideOptions = {
    type: "loop",
    padding: "20%",
    focus: "center",
    gap: "10px",
    pagination: true,
  };

  return (
    <div className="input-bg p-2 homecontent-sec pos-relative">
      <div className="white-bg br-10 boxShadow h-35vh p-2">
        <h3 className="password-clr px-1 py-2">Most Popular Tours</h3>
        <Splide aria-label="Tour Categories" options={splideOptions}>
          {Array.isArray(categories) &&
            categories.map((category) => (
              <SplideSlide
                key={category.category_id}
                onClick={() => navigate(`/tours/${category.slug}`)}
              >
                <div className="white-bg p-1 br-20">
                  <IonImg
                    src={category.upload_path}
                    className="w-100"
                    alt={`${category.category_name} Banner`}
                  />
                  <h3 className="password-clr font-18 p-1 fw-bold">
                    {category.category_name}
                  </h3>
                </div>
              </SplideSlide>
            ))}
        </Splide>

        {/* <Splide aria-label="Tour Categories" options={splideOptions}>
          {Array.isArray(categories) &&
            categories.map((category) => (
              <SplideSlide
                key={category.category_id}
                onClick={() => navigate(`/tours/${category.slug}`)}
              >
                <div className="white-bg p-1 br-20">
                  <IonImg
                    src={category.upload_path}
                    className="w-100"
                    alt={`${category.category_name} Banner`}
                  />
                  <h3 className="password-clr font-18 p-1 fw-bold">
                    {category.category_name}
                  </h3>
                </div>
              </SplideSlide>
            ))}
        </Splide> */}
      </div>

      <div className="mt-2">
        <h1 className="clr-black">Best Destinations</h1>
        <div className="d-flex flex-column">
          {bestDestinationData?.length > 0 ? (
            <>
              {bestDestinationData.map((item, index) => (
                <div key={index} className="white-bg br-10 my-1 boxShadow">
                  <IonRow>
                    <IonCol className="d-flex align-items-center" size="4">
                      <iframe
                        className="br-10"
                        width="120"
                        height="80"
                        src="https://www.youtube.com/embed/ahy5o5nT4oI?si=SMZfKW9SD6vtSiR_"
                        title="YouTube video"
                      />
                    </IonCol>
                    <IonCol size="8">
                      <div className="d-flex flex-column justify-content-between">
                        <h3 className="font-18 fw-500">{item.tour_title}</h3>
                        <div className="d-flex flex-row align-items-center mt-1">
                          <FaRegCalendarAlt className="font-20" />
                          <span className="font-16">
                            {moment(item?.schedule_from).format("DD-MM-YYYY")}
                          </span>
                          <IoLocationOutline className="font-20" />
                          <span className="font-16">{item.tour_location}</span>
                        </div>
                        <div className="d-flex flex-row align-items-center mt-1">
                          <FcLike className="font-20" />
                          <span className="font-16">I am Interested</span>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              ))}
            </>
          ) : (
            <div className="large-font fw-600">No Destinations Available</div>
          )}
        </div>
      </div>

      <div className="join-now-sec flex-column w-100 br-20">
        <div className="w-100 d-flex justify-content-center my-2">
          <h2 className="font-22 fw-700 clr-white text-center w-75">
            Let’s explore the beautiful experience
          </h2>
        </div>
        <p className="clr-white font-16 text-center my-2">
          1000 destinations found
        </p>
        <div className="d-flex justify-content-center">
          <button className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2 w-50">
            Book Now
          </button>
        </div>
      </div>
      <ChatButton />
    </div>
  );
}

export default HomeContentTwo;
