import axios from "axios";

const baseUrl = "http://localhost:5001";
const jwt_token = localStorage.getItem("jwt_token");

const chatting = {
  sendFriendRequest: async (id, data) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/users/request/${id}  `,
        data,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },

  getFriendRequest: async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/users/requests`, {
        headers: {
          Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching creator details:", error);
    }
  },

  acceptFriendRequest: async (id) => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/users/request/accept/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error accept request :", error);
    }
  },

  rejectFriendRequest: async (id) => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/users/request/decline/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error reject request :", error);
    }
  },

  getMyCreatorDetails: async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/creater/user-creaters`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching creator details:", error);
    }
  },

  getChatMessages: async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/chats/messages/${id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );

      console.log(response, "creator messages");
      return response.data;
    } catch (error) {
      console.error("Error fetching creator details:", error);
    }
  },
  getMyTourMemberDetails: async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/users/joined-tour-users  `,

        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },

  sendMessage: async (id, data) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/chats/messages/${id}  `,
        data,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );
      console.log(response.data, "send message");
      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },

  getMyFriendsList: async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/users/friends  `,

        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },
  blockTheUser: async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/users/block/${id}  `,

        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },
  chatWithTeam: async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/creater/marketing-team`,
        {
          headers: { Authorization: `Bearer ${jwt_token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching team chat messages:", error);
    }
  },
  editMessage: async (recipientId, messageId, editedContent) => {
    console.log(recipientId,messageId,editedContent,"===>in api call edit ");
    
    try {
      const response = await axios.put(
        `${baseUrl}/api/chats/messages/${recipientId}/${messageId}  `,
        {
          content: editedContent, // Send the new message content in the request body
        },
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );
      console.log(response.data, "edit message on main code");
      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },
  deleteMessage: async (messageId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/api/chats/messages/me/${messageId}  `,

        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );
      console.log(response.data, "delete message");
      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },

  deleteMessageForBothUsers: async (recipientId, messageId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/api/chats/messages/${recipientId}/${messageId}  `,

        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );
      console.log(response.data, "delete message for all");
      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },
  forwardMessages: async (messageId, recipientIds, data) => {
    console.log(messageId, recipientIds, data, "==>in api method");

    try {
      const response = await axios.post(
        `${baseUrl}/api/chats/messages/forward/${messageId}/${recipientIds}  `,
        data,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );
      console.log(response.data, "send message");
      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },
 
};

export default chatting;
