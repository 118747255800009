import React, { useEffect, useMemo, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { uploadImage } from "../../api/apiMethods";
import {
  setMembersData,
  setPackagesData,
  setSelectedOption,
  setSlectedPackageName,
  setTotalGroupCost,
  setTotalGroupMembers,
  setTotalPackageCost,
  setTotalSelectedMembers,
  setUploadPosterId,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { uploadFilesToS3 } from "../../images/images";

function InputDetailsPage({
  error,
  setError,
  isFormVisible,
  setActiveStep,
  handleFileChange,
  formData,
  selectedTourData,
  selectedTourPackages,
  setShowPackage,
  selectedPackage,
  currentFormIndex,
  handleFormSubmit,
  errorMessage,
  handleInputChange,
  handleGenderSelection,
  setShowOptions,
  showOptions,
  selectIDProof,
  handlePackageSelect,
  showGender,
  setShowGender,
  showIDProof,
  setShowIdProof,
}) {
  const dispatch = useDispatch();
  const selectedOption = useSelector((state) => state.selectedOption);

  const selectedPackageName = useSelector(
    (state) => state?.selectedPackageName
  );
  console.log(selectedPackageName, "===>selectedPackageName/INPUT");
  console.log(selectedTourData, "===>selectedTourData");
  // const [showGender, setShowGender] = useState(false);
  const handleGenderBtn = () => {
    setShowPackage(false);
    setShowGender(!showGender);
    setShowIdProof(false);
  };
  const genderOptions = ["Male", "Female", "None"];
  // const [showIDProof, setShowIdProof] = useState(false);
  const handleIdProofBtn = () => {
    setShowPackage(false);
    setShowGender(false);
    setShowIdProof(!showIDProof);
  };
  const idProofs = [
    "Aadhar Card",
    "PanCard",
    "Driving License",
    "Bank Passbook",
  ];

  const [showGuest, setShowGuest] = useState(false);
  const handleShowOptions = () => {
    setShowOptions(!showOptions);
  };
  const displaySelectedPackages = () => {
    if (selectedTourPackages.length === 0) {
      return "Select Option";
    }

    return selectedTourPackages[0].package_name;
  };

  const [totalPackagesSelected, setTotalPackagesSelected] = useState(0);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const getFilteredPackages = () => {
    return selectedTourData.packages.filter((pkg) => {
      if (selectedOption === "individual") {
        return pkg.package_name === "individual_package";
      }
      if (selectedOption === "family") {
        return pkg.package_name === "family_package";
      }
      return true;
    });
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    dispatch(setSlectedPackageName(value));
    dispatch(setSelectedOption(value));
    setTotalPackagesSelected((prev) => prev + 1);
  };

  useEffect(() => {
    const filteredPackages = getFilteredPackages();

    if (filteredPackages.length > 0) {
      const packageToSet = filteredPackages[0];
      setCurrentPackage(packageToSet);
      setSelectedPackageId(packageToSet.package_id);

      const totalPackageCost = filteredPackages.reduce(
        (total, pkg) => total + pkg.price,
        0
      );
      dispatch(setTotalPackageCost(totalPackageCost));
    } else {
      setCurrentPackage(null);
      setSelectedPackageId(null);
      dispatch(setTotalPackageCost(0));
    }
  }, [selectedOption]);

  const totalGroupMembers = useMemo(() => {
    const totalMembers = currentPackage
      ? currentPackage.member_adult_count + currentPackage.member_child_count
      : 0;

    console.log(totalMembers, "totalMembers");

    dispatch(setTotalGroupMembers(totalMembers));

    return totalMembers;
  }, [currentPackage, dispatch]);
  const filteredPackagesList = getFilteredPackages();
  const [additionalData, setAdditionalData] = useState({
    adultCount: "",
    childCount: "",
    singleRooms: "",
    doubleRooms: "",
    tripleRooms: "",
    extraBed: "",
  });
  const [errors, setErrors] = useState({});
  const [members, setMembers] = useState([]);
  console.log("=====================>memebrs<====================", members);
  const handleMemberChange = (index, field, value) => {
    const updatedMembers = [...members];
    updatedMembers[index] = { ...updatedMembers[index], [field]: value };
    setMembers(updatedMembers);
  };
  const handleCountChange = (type, count) => {
    const adultCount = parseInt(additionalData.adultCount) || 0;
    const childCount = parseInt(additionalData.childCount) || 0;

    if (type === "adult") {
      const newAdults = Array.from(
        { length: parseInt(count) || 0 },
        (_, i) => ({
          name: "",
          DOB: "",
          gender: "",
          ID_proof: "",
        })
      );

      const updatedMembers = [...newAdults, ...members.slice(childCount)];
      setMembers(updatedMembers);
    } else if (type === "child") {
      const newChildren = Array.from(
        { length: parseInt(count) || 0 },
        (_, i) => ({
          name: "",
          DOB: "",
          gender: "",
          ID_proof: "",
        })
      );

      const updatedMembers = [...members.slice(0, adultCount), ...newChildren];
      setMembers(updatedMembers);
    }

    setAdditionalData({
      ...additionalData,
      [type + "Count"]: count,
    });

    const totalSelectedMembers =
      (type === "adult" ? parseInt(count) : adultCount) +
      (type === "child" ? parseInt(count) : childCount);

    dispatch(setTotalSelectedMembers(totalSelectedMembers));
  };
  const getTotalSelectedMembers = () => {
    const adultCount = parseInt(additionalData.adultCount) || 0;
    const childCount = parseInt(additionalData.childCount) || 0;
    return adultCount + childCount;
  };
  useEffect(() => {
    const totalSelectedMembers = getTotalSelectedMembers();
    dispatch(setTotalSelectedMembers(totalSelectedMembers));
  }, [additionalData, dispatch]);
  const validateForm = () => {
    const newErrors = {};
    const adultCount = parseInt(additionalData.adultCount || 0);
    const childCount = parseInt(additionalData.childCount || 0);

    for (let i = 0; i < adultCount; i++) {
      if (!members[i]?.name)
        newErrors[`adultName${i}`] = `Adult Member ${i + 1} Name is required.`;
      if (!members[i]?.DOB)
        newErrors[`adultDob${i}`] = `Adult Member ${i + 1} DOB is required.`;
      if (!members[i]?.gender)
        newErrors[`adultGender${i}`] = `Adult Member ${
          i + 1
        } Gender is required.`;
      if (!members[i]?.ID_proof)
        newErrors[`adultIDProof${i}`] = `Adult Member ${
          i + 1
        } ID Proof is required.`;
      if (!members[i]?.upload_id)
        newErrors[`adultPhoto${i}`] = `Adult Member ${
          i + 1
        } Photo is required.`;
    }

    for (let i = 0; i < childCount; i++) {
      if (!members[adultCount + i]?.name)
        newErrors[`childName${i}`] = `Child Member ${i + 1} Name is required.`;
      if (!members[adultCount + i]?.DOB)
        newErrors[`childDob${i}`] = `Child Member ${i + 1} DOB is required.`;
      if (!members[adultCount + i]?.gender)
        newErrors[`childGender${i}`] = `Child Member ${
          i + 1
        } Gender is required.`;
      if (!members[adultCount + i]?.ID_proof)
        newErrors[`childIDProof${i}`] = `Child Member ${
          i + 1
        } ID Proof is required.`;
      if (!members[adultCount + i]?.upload_id)
        newErrors[`childPhoto${i}`] = `Child Member ${
          i + 1
        } Photo is required.`;
    }

    return newErrors;
  };
  const submitFormData = () => {
    const validationErrors = validateForm();
    console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    console.log("Members Data:", members);
    console.log("Additional Data:", additionalData);

    const submittedData = {
      members,
      additionalData,
    };
    dispatch(setMembersData(members));

    console.log("Submitted Data:", submittedData);
    alert(
      `Form submitted successfully!\n${JSON.stringify(submittedData, null, 2)}`
    );

    setActiveStep(1);
  };
  const [imageSportsId, setImageSportsId] = useState(null);
  console.log(
    imageSportsId,
    "=======================>imageSportsId<================"
  );
  const [file, setFile] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});
  // const [error, setError] = useState(null);
  const [fileNames, setFileNames] = useState({});

  const handlePhotoChange = async (event, memberIndex) => {
    const file = event.target.files[0];
    if (!file) {
      setError((prev) => ({
        ...prev,
        [memberIndex]: "Please select a file first",
      }));
      return;
    }

    setSelectedFile((prev) => ({ ...prev, [memberIndex]: file }));

    setFileNames((prev) => ({ ...prev, [memberIndex]: file.name }));

    try {
      const url = await uploadFilesToS3([file], (progress) =>
        setUploadProgress((prev) => ({ ...prev, [memberIndex]: progress }))
      );

      if (url) {
        const response = await uploadImage({ type: "image", path: url });
        if (response.status === true) {
          const uploadId = response.data.map((item) => item.id)[0];

          const updatedMembers = [...members];
          updatedMembers[memberIndex].upload_id = uploadId;
          setMembers(updatedMembers);
        } else {
          console.error("Upload failed:", response.message);
          setError((prev) => ({
            ...prev,
            [memberIndex]: response.message || "Image upload failed.",
          }));
        }
      }
    } catch (error) {
      console.error("File upload error:", error);
      setError((prev) => ({
        ...prev,
        [memberIndex]: "File upload failed. Please try again.",
      }));
    }
  };

  console.log(uploadProgress, "uploadProgress");

  const [packages, setPackages] = useState([]);
  useEffect(() => {
    if (selectedPackageId !== null) {
      const newPackage = {
        package_quantity: totalPackagesSelected,
        tour_packages_id: selectedPackageId,
      };

      setPackages((prevPackages) => {
        const existingPackage = prevPackages.find(
          (pkg) => pkg.tour_packages_id === selectedPackageId
        );

        let updatedPackages;
        if (existingPackage) {
          updatedPackages = prevPackages.map((pkg) =>
            pkg.tour_packages_id === selectedPackageId
              ? { ...pkg, package_quantity: totalPackagesSelected }
              : pkg
          );
        } else {
          updatedPackages = [...prevPackages, newPackage];
        }

        dispatch(setPackagesData(updatedPackages));
        return updatedPackages;
      });
    }
  }, [totalPackagesSelected, selectedPackageId, dispatch]);

  const calculateTotalCost = () => {
    if (selectedOption !== "group") return 0;

    const {
      adultCount,
      childCount,
      doubleRooms,
      singleRooms,
      tripleRooms,
      extraBed,
    } = additionalData;
    const travelChargePerPerson = selectedTourData.packages.find(
      (pkg) => pkg.package_name === "travel_charges_per_person"
    ).price;
    const childCharges = selectedTourData.packages.find(
      (pkg) => pkg.package_name === "child_charges"
    ).price;
    const singleOccupancy = selectedTourData.packages.find(
      (pkg) => pkg.package_name === "single_occupancy"
    ).price;
    const doubleOccupancy = selectedTourData.packages.find(
      (pkg) => pkg.package_name === "double_occupancy"
    ).price;
    const tripleOccupancy = selectedTourData.packages.find(
      (pkg) => pkg.package_name === "triple_occupancy"
    ).price;
    const extraBedCharge = selectedTourData.packages.find(
      (pkg) => pkg.package_name === "extra_bed"
    ).price;

    const totalCost =
      adultCount * travelChargePerPerson +
      childCount * childCharges +
      singleRooms * singleOccupancy +
      doubleRooms * doubleOccupancy +
      tripleRooms * tripleOccupancy +
      extraBed * extraBedCharge;

    return totalCost;
  };
  const totalGroupCost = calculateTotalCost();
  console.log(totalGroupCost, "totalGroupCost");

  useEffect(() => {
    dispatch(setTotalGroupCost(totalGroupCost));
  }, [totalGroupCost]);

  const calculateDefaultDOB = (isAdult) => {
    const today = new Date();
    if (isAdult) {
      return new Date(
        today.getFullYear() - 13,
        today.getMonth(),
        today.getDate()
      )
        .toISOString()
        .split("T")[0];
    } else {
      const randomYears = Math.floor(Math.random() * 14); // 0 to 13 years
      return new Date(
        today.getFullYear() - randomYears,
        today.getMonth(),
        today.getDate()
      )
        .toISOString()
        .split("T")[0];
    }
  };

  const getDateRange = (isAdult) => {
    const today = new Date();
    if (isAdult) {
      const maxDate = new Date(
        today.getFullYear() - 13,
        today.getMonth(),
        today.getDate()
      );
      const minDate = new Date(
        today.getFullYear() - 120,
        today.getMonth(),
        today.getDate()
      ); // Example: 120 years max age
      return {
        min: minDate.toISOString().split("T")[0],
        max: maxDate.toISOString().split("T")[0],
      };
    } else {
      const maxDate = today; // Today for a 0-year-old
      const minDate = new Date(
        today.getFullYear() - 13,
        today.getMonth(),
        today.getDate()
      );
      return {
        min: minDate.toISOString().split("T")[0],
        max: maxDate.toISOString().split("T")[0],
      };
    }
  };
  return (
    <div className="mt-2 px-2">
      <div className="pos-relative">
        {selectedTourData?.slug === "casino-tour" ? (
          <div
            className="input-bg br-10 py-2 d-flex flex-row justify-content-between align-items-center px-2 my-1"
            onClick={handleShowOptions}
          >
            <div className="password-clr small-font fw-600">
              {displaySelectedPackages()}
            </div>
            <IoIosArrowDown className="font-24 fw-600 password-clr" />
          </div>
        ) : (
          <div className="d-flex flex-column small-font">
            <label htmlFor="packageFilter" className="fw-bold medium-font">
              Select Package Type:
            </label>
            <select
              id="packageFilter"
              value={selectedOption}
              onChange={handleFilterChange}
              className="input-bg br-10 py-2 border-none d-flex flex-row justify-content-between align-items-center px-2 my-1"
            >
              <option value="">Select Package</option>
              <option value="individual">Individual Package</option>
              <option value="family">Family Package</option>
              <option value="group">Group Package</option>
            </select>
          </div>
        )}
        {showOptions && (
          <div className="white-bg password-clr border-pink tour-packages-dropdown br-10 px-2">
            {selectedTourData.packages?.map((packageType, index) => (
              <div
                key={index}
                className={`small-font py-1 password-clr border-bottom-grey ${
                  selectedTourPackages.includes(packageType) ? "selected" : ""
                }`}
                onClick={() => handlePackageSelect(packageType)}
              >
                {packageType.package_name} Packages
              </div>
            ))}
          </div>
        )}
      </div>

      {selectedTourData?.slug === "casino-tour" ? (
        <div>
          {selectedTourPackages.length > 0 && (
            <div className="selected-package-details">
              {selectedTourPackages.map((pkg) => {
                const totalAdults = pkg.member_adult_count;
                const totalChildren = pkg.member_child_count;

                const adults = Array.from(
                  { length: totalAdults },
                  (_, index) => `Adult ${index + 1}`
                );
                const children = Array.from(
                  { length: totalChildren },
                  (_, index) => `Child ${index + 1}`
                );
                const allMembers = [...adults, ...children];

                return (
                  <div key={pkg.package_id} className="package-details">
                    {allMembers?.map(
                      (member, index) =>
                        index === currentFormIndex &&
                        isFormVisible && (
                          <div key={member} className="member-form">
                            <div className="d-flex w-100">
                              <div className="d-flex flex-column w-50 me-1">
                                <div className="fw-600 px-1 my-1 medium-font password-clr">
                                  {member} Name
                                </div>
                                <input
                                  placeholder={`Enter ${member} Name`}
                                  type="text"
                                  className="input-bg border-none password-clr px-2 small-font fw-600 br-10 py-2"
                                  value={formData[member]?.name || ""}
                                  onChange={handleInputChange(member, "name")}
                                />
                              </div>
                              <div className="d-flex flex-column w-50">
                                <div className="fw-600 px-1 my-1 medium-font password-clr">
                                  DOB
                                </div>
                                <input
                                  type="date"
                                  className="input-bg border-none px-2 password-clr small-font fw-600 br-10 py-2"
                                  value={formData[member]?.dob || ""}
                                  defaultValue={calculateDefaultDOB(
                                    adults.includes(member)
                                  )}
                                  min={
                                    getDateRange(adults.includes(member)).min
                                  }
                                  max={
                                    getDateRange(adults.includes(member)).max
                                  }
                                  // value={
                                  //   formData[member]?.dob ||
                                  //   calculateDefaultDOB(adults.includes(member))
                                  // }
                                  onChange={handleInputChange(member, "dob")}
                                />
                              </div>
                            </div>

                            <div className="fw-600 px-1 my-1 medium-font password-clr">
                              Gender
                            </div>
                            <div
                              className="input-bg d-flex align-items-center justify-content-between px-2 br-10 py-2"
                              onClick={() => handleGenderBtn(member)}
                            >
                              <div className="grey-text small-font fw-600">
                                {formData[member]?.selectedGender || "Select"}
                              </div>
                              <IoIosArrowDown className="font-24 fw-600 password-clr" />
                            </div>
                            {showGender && (
                              <div className="gender-dropdown input-bg br-10 w-50">
                                {genderOptions.map((option) => (
                                  <div
                                    key={option}
                                    className="small-font py-1 border-bottom-grey px-2"
                                    onClick={() =>
                                      handleGenderSelection(option, member)
                                    }
                                  >
                                    {option}
                                  </div>
                                ))}
                              </div>
                            )}
                            <div className="fw-600 px-1 my-1 medium-font password-clr">
                              ID Proof
                            </div>
                            <div
                              className="input-bg d-flex align-items-center justify-content-between px-2 br-10 py-2"
                              onClick={() => handleIdProofBtn(member)}
                            >
                              <div className="grey-text small-font fw-600">
                                {formData[member]?.selectedIDProof || "Select"}
                              </div>
                              <IoIosArrowDown className="font-24 fw-600 password-clr" />
                            </div>
                            {showIDProof && (
                              <div className="id-dropdown input-bg br-10 w-50">
                                {idProofs.map((proof) => (
                                  <div
                                    key={proof}
                                    className="small-font py-1 border-bottom-grey px-2"
                                    onClick={() => selectIDProof(proof, member)}
                                  >
                                    {proof}
                                  </div>
                                ))}
                              </div>
                            )}

                            <div className="fw-600 px-1 my-1 medium-font password-clr">
                              Upload Photo
                            </div>
                            <input
                              type="file"
                              className="input-bg d-flex align-items-center justify-content-between px-2 br-10 py-2 w-100"
                              onChange={(e) => handleFileChange(e, member)}
                            />
                            <div
                              className="pink-bg py-2 br-20 clr-white w-100 fw-700 text-center my-2"
                              onClick={handleFormSubmit}
                            >
                              Save Data
                            </div>
                            <div className="clr-red">{error}</div>
                          </div>
                        )
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="d-flex flex-column">
            {filteredPackagesList?.length > 0
              ? filteredPackagesList?.map((pkg) => (
                  <div
                    key={pkg.package_id}
                    className="d-flex flex-column small-font fw-bold"
                  ></div>
                ))
              : null}
          </div>
        </div>
      )}
      {(selectedOption === "individual" ||
        selectedOption === "family" ||
        selectedOption === "group") && (
        <div className="mt-1">
          {selectedOption === "individual" && (
            <div className="clr-red small-font fw-bold w-100 text-center">
              Max {totalGroupMembers} Are Allowed Selected Members
            </div>
          )}

          <div className="d-flex">
            <div className="col-2">
              <div className="d-flex flex-column">
                <label className="small-font fw-bold">Adult</label>
                <input
                  type="number"
                  min="0"
                  placeholder="0"
                  className={`input-bg w-100 border-none py-1 br-10 text-center small-font fw-bold ${
                    errors.adultCount ? "border-danger" : ""
                  }`}
                  value={additionalData.adultCount}
                  onChange={(e) => handleCountChange("adult", e.target.value)}
                />
                {errors.adultCount && (
                  <div className="text-danger small-font">
                    {errors.adultCount}
                  </div>
                )}
              </div>
            </div>

            {(selectedOption === "family" || selectedOption === "group") && (
              <>
                <div className="col-2">
                  <div className="d-flex flex-column">
                    <label className="small-font fw-bold">Child</label>
                    <input
                      type="number"
                      min="0"
                      placeholder="0"
                      className={`input-bg w-100 border-none py-1 br-10 text-center small-font fw-bold ${
                        errors.childCount ? "border-danger" : ""
                      }`}
                      value={additionalData.childCount}
                      onChange={(e) =>
                        handleCountChange("child", e.target.value)
                      }
                    />
                    {errors.childCount && (
                      <div className="text-danger small-font">
                        {errors.childCount}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-2">
                  <div className="d-flex flex-column">
                    <label className="small-font fw-bold">Double</label>
                    <input
                      type="text"
                      placeholder="0"
                      className={`input-bg w-100 border-none py-1 br-10 text-center small-font fw-bold ${
                        errors.doubleRooms ? "border-danger" : ""
                      }`}
                      value={additionalData.doubleRooms}
                      onChange={(e) =>
                        setAdditionalData({
                          ...additionalData,
                          doubleRooms: e.target.value,
                        })
                      }
                    />
                    {errors.doubleRooms && (
                      <div className="text-danger small-font">
                        {errors.doubleRooms}
                      </div>
                    )}
                  </div>
                </div>

                {selectedOption === "group" && (
                  <>
                    <div className="col-2">
                      <div className="d-flex flex-column">
                        <label className="small-font fw-bold">Single</label>
                        <input
                          type="text"
                          placeholder="0"
                          className={`input-bg w-100 border-none py-1 br-10 text-center small-font fw-bold ${
                            errors.singleRooms ? "border-danger" : ""
                          }`}
                          value={additionalData.singleRooms}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              singleRooms: e.target.value,
                            })
                          }
                        />
                        {errors.singleRooms && (
                          <div className="text-danger small-font">
                            {errors.singleRooms}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-2">
                      <div className="d-flex flex-column">
                        <label className="small-font fw-bold">Triple</label>
                        <input
                          type="text"
                          placeholder="0"
                          className={`input-bg w-100 border-none py-1 br-10 text-center small-font fw-bold ${
                            errors.tripleRooms ? "border-danger" : ""
                          }`}
                          value={additionalData.tripleRooms}
                          onChange={(e) =>
                            setAdditionalData({
                              ...additionalData,
                              tripleRooms: e.target.value,
                            })
                          }
                        />
                        {errors.tripleRooms && (
                          <div className="text-danger small-font">
                            {errors.tripleRooms}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className="col-2">
                  <div className="d-flex flex-column">
                    <label className="small-font fw-bold">Xtra Bed?</label>
                    <input
                      type="text"
                      placeholder="0"
                      className={`input-bg w-100 border-none py-1 br-10 text-center small-font fw-bold ${
                        errors.extraBed ? "border-danger" : ""
                      }`}
                      value={additionalData.extraBed}
                      onChange={(e) =>
                        setAdditionalData({
                          ...additionalData,
                          extraBed: e.target.value,
                        })
                      }
                    />
                    {errors.extraBed && (
                      <div className="text-danger small-font">
                        {errors.extraBed}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            <div className="mt-1">
              <div className="d-flex overflow-scroll mt-3">
                {members.map((member, index) => (
                  <div
                    key={index}
                    className="d-flex rounded my-3 flex-column p-2 w-75 box-shadow white-bg mx-1"
                  >
                    <div className="fw-600 px-1 my-1">
                      {index < additionalData.adultCount
                        ? `(Adult ${index + 1})`
                        : `(Child ${index - additionalData.adultCount + 1})`}
                    </div>
                    <div className="d-flex w-100">
                      <div className="d-flex flex-column w-50 me-1">
                        <div className="fw-600 px-1 my-1 medium-font password-clr">
                          Member Name{" "}
                        </div>
                        <input
                          placeholder={`Enter Member Name`}
                          type="text"
                          className={`input-bg border-none password-clr px-2 small-font fw-600 br-10 py-2 ${
                            errors[`adultName${index}`] ||
                            errors[
                              `childName${index - additionalData.adultCount}`
                            ]
                              ? "border-danger"
                              : ""
                          }`}
                          value={member.name || ""}
                          onChange={(e) =>
                            handleMemberChange(index, "name", e.target.value)
                          }
                        />
                        {errors[`adultName${index}`] && (
                          <div className="text-danger small-font">
                            {errors[`adultName${index}`]}
                          </div>
                        )}
                      </div>

                      <div className="d-flex flex-column w-50">
                        <div className="fw-600 px-1 my-1 medium-font password-clr">
                          DOB
                        </div>
                        <input
                          type="date"
                          className={`input-bg border-none px-2 password-clr small-font fw-600 br-10 py-2 ${
                            errors[`adultDob${index}`] ||
                            errors[
                              `childDob${index - additionalData.adultCount}`
                            ]
                              ? "border-danger"
                              : ""
                          }`}
                          value={member.DOB || ""}
                          onChange={(e) =>
                            handleMemberChange(index, "DOB", e.target.value)
                          }
                        />
                        {errors[`adultDob${index}`] && (
                          <div className="text-danger small-font">
                            {errors[`adultDob${index}`]}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="fw-600 px-1 my-1 medium-font password-clr">
                      Gender
                    </div>
                    <select
                      className="input-bg br-10 py-2 w-100 border-none px-2 small-font"
                      value={member.gender || ""}
                      onChange={(e) =>
                        handleMemberChange(index, "gender", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      {genderOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {errors[`adultGender${index}`] && (
                      <div className="text-danger small-font">
                        {errors[`adultGender${index}`]}
                      </div>
                    )}

                    <div className="fw-600 px-1 my-1 medium-font password-clr">
                      ID Proof
                    </div>
                    <select
                      className="input-bg br-10 py-2 w-100 border-none px-2 small-font"
                      value={member.ID_proof || ""}
                      onChange={(e) =>
                        handleMemberChange(index, "ID_proof", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      {idProofs.map((proof) => (
                        <option key={proof} value={proof}>
                          {proof}
                        </option>
                      ))}
                    </select>
                    {errors[`adultIDProof${index}`] && (
                      <div className="text-danger small-font">
                        {errors[`adultIDProof${index}`]}
                      </div>
                    )}

                    <div className="fw-600 px-1 my-1 medium-font password-clr">
                      Upload Photo
                    </div>
                    <input
                      type="file"
                      className={`input-bg d-flex align-items-center justify-content-between px-2 br-10 py-2 w-100 ${
                        errors[`adultPhoto${index}`] ||
                        errors[`childPhoto${index - additionalData.adultCount}`]
                          ? "border-danger"
                          : ""
                      }`}
                      onChange={(e) => handlePhotoChange(e, index)}
                    />
                    {errors[`adultPhoto${index}`] && (
                      <div className="text-danger small-font">
                        {errors[`adultPhoto${index}`]}
                      </div>
                    )}
                    {fileNames[index] && (
                      <div className="small-font">
                        Selected File: {fileNames[index]}
                      </div>
                    )}
                    {uploadProgress[index] !== undefined ? (
                      <div className="small-font">
                        Upload Progress: {uploadProgress[index]}%
                      </div>
                    ) : (
                      <div className="small-font">Upload Progress: 0%</div>
                    )}
                    {/* {uploadProgress !== null && (
                      <div className="small-font">
                        Upload Progress: {uploadProgress[index]}%
                      </div>
                    )} */}
                    {error && (
                      <div className="text-danger small-font">{error}</div>
                    )}
                  </div>
                ))}
              </div>
              <div
                className="pink-bg py-2 br-20 clr-white w-100 fw-700 text-center my-2"
                onClick={submitFormData}
              >
                Save Data
              </div>
            </div>
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="clr-green x-small-font fw-600 mt-2">
          * {errorMessage}
        </div>
      )}
    </div>
  );
}

export default InputDetailsPage;
