import React, { useState, useEffect } from "react";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { IoBriefcaseOutline } from "react-icons/io5";
import { IoImageOutline } from "react-icons/io5";
import { RiMenuFill } from "react-icons/ri";
import { RiHome6Fill } from "react-icons/ri";
import "./common.css";
import "./styles.css";
import { RxCross2 } from "react-icons/rx";
import { IonCol, IonRow } from "@ionic/react";
import { useNavigate, useLocation } from "react-router";
import ResetPassword from "../pages/popups/ResetPassword";
import { useSelector } from "react-redux";

function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  const tourCategoryData = useSelector((state) => state?.tourCategoryData);

  const [activeButton, setActiveButton] = useState("home");
  const [showTours, setShowTours] = useState(false);
  const [showTrips, setShowTrips] = useState(false);
  const [showHome, setShowHome] = useState(false);
  const [showMemories, setShowMemories] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);

  useEffect(() => {
    switch (location.pathname) {
      case "/trips":
        setActiveButton("trips");
        break;
      case "/home":
        setActiveButton("home");
        break;
      case "/memories":
        setActiveButton("memories");
        break;
      case "/":
        setActiveButton("home");
        break;
      default:
        setActiveButton("");
    }
  }, [location.pathname]);

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const handleTours = () => {
    handleButtonClick("tours");
    setShowTours(!showTours);
    setShowMore(false);
  };

  const handleTrips = () => {
    navigate("/trips");
    handleButtonClick("trips");
    setShowTrips(!showTrips);
  };

  const handleHome = () => {
    navigate("/home");
    handleButtonClick("home");
    setShowHome(!showHome);
  };

  const handleMemories = () => {
    navigate("/memories");
    handleButtonClick("memories");
    setShowMemories(!showMemories);
  };

  const handleMore = () => {
    handleButtonClick("menu");
    setShowMore(!showMore);
    setShowTours(false);
  };

  const handleResetPassword = () => {
    setOpenResetPassword(true);
    setShowMore(false);
  };

  const handleTickets = () => {
    navigate("/tickets");
    setShowMore(false);
  };

  const handleChat = () => {
    navigate("/chat");
    setShowMore(false);
  };

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  console.log(tourCategoryData, "===>REDUX_CATEGORY");

  return (
    <div className="footer">
      <IonRow>
        <IonCol size="2.4">
          <div
            className={`d-flex flex-column justify-content-center align-items-center ${
              activeButton === "tours"
                ? "clr-pink border-top-pink py-1"
                : "py-1 clr-grey"
            }`}
            onClick={() => handleTours()}
          >
            <BiSolidPlaneAlt className="font-30" />
            <div className="font-16">Tours</div>
          </div>
        </IonCol>
        <IonCol size="2.4">
          <div
            className={`d-flex flex-column justify-content-center align-items-center ${
              activeButton === "trips"
                ? "clr-pink border-top-pink py-1"
                : "py-1 clr-grey"
            }`}
            onClick={() => handleTrips()}
          >
            <IoBriefcaseOutline className="font-30" />
            <div className="font-16">Trips</div>
          </div>
        </IonCol>
        <IonCol size="2.4">
          <div
            className={`home-icon flex-center br-50 p-2 clr-grey ${
              activeButton === "home" ? "pink-bg" : "bg-grey"
            }`}
            onClick={() => handleHome()}
          >
            <RiHome6Fill className="font-35 clr-white" />
          </div>
        </IonCol>
        <IonCol size="2.4">
          <div
            className={`d-flex flex-column justify-content-center align-items-center ${
              activeButton === "memories"
                ? "clr-pink border-top-pink py-1"
                : "py-1 clr-grey"
            }`}
            onClick={() => handleMemories()}
          >
            <IoImageOutline className="font-30" />
            <div className="font-16">Memories</div>
          </div>
        </IonCol>
        <IonCol size="2.4">
          <div
            className={`d-flex flex-column justify-content-center align-items-center ${
              activeButton === "menu"
                ? "clr-pink border-top-pink py-1"
                : "py-1 clr-grey"
            }`}
            onClick={() => handleMore()}
          >
            <RiMenuFill className="font-30" />
            <div className="font-16">Menu</div>
          </div>
        </IonCol>
        {showTours && (
          <div className="white-bg clr-black tours-popup border-grey br-20 w-40 px-2">
            <div className="d-flex justify-content-center align-items-center mtn-16p">
              <div className="grey-bg1 w-22 text-center br-50 p-1">
                <RxCross2
                  className="font-20 fw-600 clr-white mb-1"
                  onClick={() => setShowTours(false)}
                />
              </div>
            </div>
            <div className="overflow-auto h-17vh">
              {tourCategoryData?.map((item, index) => (
                <div
                  className="grey-text p-1 border-bottom-grey"
                  key={index}
                  onClick={() => {
                    navigate(`/tours/${item?.slug}`);
                    setShowTours(false);
                  }}
                >
                  {item?.category_name}
                </div>
              ))}
            </div>
          </div>
        )}
        {showMore && (
          <div className="white-bg clr-black more-popup border-grey br-20 w-40 px-2">
            <div className="d-flex justify-content-center align-items-center mtn-16p">
              <div className="grey-bg1 w-22 text-center br-50 p-1">
                <RxCross2
                  className="font-20 fw-600 clr-white mb-1"
                  onClick={() => setShowMore(false)}
                />
              </div>
            </div>
            <div
              className="grey-text p-1  border-bottom-grey"
              onClick={() => handleTickets()}
            >
              Tickets
            </div>
            <div
              className="grey-text p-1  border-bottom-grey"
              onClick={() => navigate("/wallet")}
            >
              Wallet
            </div>
            <div
              className="grey-text p-1  border-bottom-grey"
              onClick={() => handleChat()}
            >
              Chat
            </div>
            <div
              className="grey-text p-1  border-bottom-grey"
              onClick={() => {
                navigate("/privacy");
                setShowMore(false);
              }}
            >
              Privacy Policy
            </div>
            <div
              className="grey-text p-1  border-bottom-grey"
              onClick={() => handleResetPassword()}
            >
              Reset Password
            </div>

            <div
              className="grey-text p-1  border-bottom-grey"
              onClick={() => handleLogOut()}
            >
              Logout
            </div>
          </div>
        )}
      </IonRow>
      <ResetPassword
        openResetPassword={openResetPassword}
        setOpenResetPassword={setOpenResetPassword}
      />
    </div>
  );
}

export default Footer;
