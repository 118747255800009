import React, { useState } from "react";
import { MdMessage } from "react-icons/md";
import ChatPage from "./ChatPage";

function ChatButton() {
  const [showChatPage, setShowChatPage] = useState(false);
  const handleChatPage = () => {
    setShowChatPage(!showChatPage);
  };
  
  return (
    <div className="w-100 text-end chat-position">
      <div
        className="pink-bg w-17 py-2 px-1 chat-button  text-center"
        onClick={() => handleChatPage()}
      >
        <MdMessage className="font-30 clr-white" />
      </div>
      {showChatPage && (
        <ChatPage
          showChatPage={showChatPage}
          setShowChatPage={setShowChatPage}
        />
      )}
    </div>
  );
}

export default ChatButton;
