import { IonModal } from "@ionic/react";
import React from "react";
import { IoClose } from "react-icons/io5";

function QRCodeModal({ showQrCode, setShowQrCode, qrCodeImage }) {
  const handleCloseQrCode = () => {
    setShowQrCode(false);
  };
  return (
    <IonModal
      isOpen={showQrCode}
      cssClass="img-popup"
      backdropDismiss={false}
    >
      <div className="flex-between mb-2 p-1">
        <h6 className="fw-bold">QR Code</h6>
        <IoClose className="font-24" onClick={handleCloseQrCode} />
      </div>
      <div className="p-2">
        <img className="qr-code-img2" src={qrCodeImage} alt="" />
      </div>
    </IonModal>
  );
}

export default QRCodeModal;
