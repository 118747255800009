import { IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import React, { useCallback, useEffect, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import { FaRegBell } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { IoIosLogOut } from "react-icons/io";
import Message from "./Message";
import { useNavigate } from "react-router";
import { getUserDetails } from "../api/apiMethods";
import { useDispatch, useSelector } from "react-redux";
import UserDetails from "./../pages/booknowpopup/UserDetails";
import { setUserDetailsData } from "./../redux/action";
import { Images } from "../images";

function CustomHeader() {
  const navigate = useNavigate();
  const token = localStorage.getItem("jwt_token");
  const userDetailsData = useSelector((state) => state?.userDetailsData);
  
  const [error, setError] = useState();

  const [showProfileDropDown, setShowProfileDropDown] = useState(false);
  const handleShowProfileDropDown = () => {
    setShowProfileDropDown(!showProfileDropDown);
  };
  const [showSearchbox, setShowSearchBox] = useState(false);
  const handleSearchBox = () => {
    setShowSearchBox(!showSearchbox);
  };
  const [showMessagebox, setShowMessageBox] = useState(false);
  const handleMessageBox = () => {
    setShowMessageBox(!showMessagebox);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  const [showEditProfile, setShowEditProfile] = useState(false);
  const handleEditProfile = () => {
    setShowEditProfile(true);
    setShowProfileDropDown(false);
  };

  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState();
  const handleGetUserDetails = useCallback(
    () =>
      getUserDetails({ token })
        .then((response) => {
          if (response.status === true) {
            console.log(response?.data);
            setUserDetails(response?.data);
            dispatch(setUserDetailsData(response?.data));
          } else {
            console.log("Somenthing went wrong");
          }
        })
        .catch((err) => console.log(err?.message), [token]),
    []
  );

  useEffect(() => {
    handleGetUserDetails();
  }, [handleGetUserDetails]);
  // console.log(userDetails, "User Details Mil Raha HAi");

  return (
    <IonGrid className="header p-1 pos-relative">
      <IonRow>
        <IonCol size="7">
          <IonImg
            src={Images.HeaderLogo}
            className="tours-logo"
            alt=""
            onClick={() => navigate("/home")}
          />
        </IonCol>
        <IonCol size="5" className="flex-center">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <BiSearchAlt
              className="font-35 grey-text"
              onClick={() => handleSearchBox()}
            />
            <FaRegBell
              className="font-30 grey-text"
              onClick={() => handleMessageBox()}
            />
            <div className="br-50 images-logo">
              <img
                src={userDetailsData?.profile_image || Images.ProfileImage}
                className="br-50"
                alt="EditProfile"
                onClick={() => navigate("/edit-profile")}
              />
            </div>
          </div>
        </IonCol>
      </IonRow>
      {showSearchbox && (
        <div className="search-section white-bg w-98 d-flex flex-row align-items-center justify-content-between border-grey p-1 br-10">
          <input
            type="search"
            placeholder="Search Here"
            className="border-none input-none cross-bg py-2 clr-black w-90 br-10 px-1 h-5vh"
          />
          <RxCross2
            className="font-24"
            onClick={() => {
              setShowSearchBox(false);
            }}
          />
        </div>
      )}
      {showMessagebox && <Message setShowMessageBox={setShowMessageBox} />}
    </IonGrid>
  );
}

export default CustomHeader;
