import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { IoArrowBack, IoClose } from "react-icons/io5";
import { FaFlag, FaHeart } from "react-icons/fa6";
import { FaCommentDots } from "react-icons/fa6";
import { Images } from "../../images";
import { HiDotsVertical } from "react-icons/hi";
import { useNavigate, useParams } from "react-router";
import {
  commnetDelReq,
  deleteComment,
  editComment,
  getMemoryById,
  replyComment,
  sendComments,
} from "../../api/apiMethods";
import { LuReply, LuSendHorizonal } from "react-icons/lu";

import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import moment from "moment";
import { IonContent, IonHeader, IonModal } from "@ionic/react";
import DeletePopup from "./../popups/DeletePopup";

function LikesPage() {
  const { id } = useParams();
  console.log(id, "id===");
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("like");

  const login_userid = localStorage.getItem("userId");
  const loginUserId = Number(login_userid);
  console.log(loginUserId, "login user id");

  const [comment, setComment] = useState();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [openedCommentId, setOpenedCommentId] = useState(0);
  const [editingCommentId, setEditingCommentId] = useState(0);

  const handleOptions = (commentId) => {
    setOpenedCommentId(openedCommentId === commentId ? null : commentId);
  };

  const handleEdit = (commentId, commentText) => {
    setOpenedCommentId(null);
    setEditingCommentId(commentId);
    setComment(commentText);
    console.log("edit");
  };
  // const [report, setreport] = useState();
  const [commentIdForReport, setCommentIdForReport] = useState(null);

  const handleShow = (commnetDelReqId) => {
    setShow(true);
    // setreport(false);
    setCommentIdForReport(commnetDelReqId);
  };

  const [getMemorydata, setGetMemorydata] = useState([]);
  console.log(getMemorydata, "get memory data");
  const getAllMemoriesById = () => {
    getMemoryById(id)
      .then((response) => {
        if (response.status === true) {
          setGetMemorydata(response?.data);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };
  useEffect(() => {
    getAllMemoriesById();
  }, [id]);

  const createComment = () => {
    if (editingCommentId) {
      editComment(editingCommentId, { comment })
        .then((response) => {
          if (response.status === true) {
            console.log(response, "ress");
            setEditingCommentId(null);
            setComment("");
            getAllMemoriesById();
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error?.message));
    } else {
      sendComments(id, { comment })
        .then((response) => {
          if (response.status === true) {
            console.log("respo", response);
            setComment("");
            getAllMemoriesById();
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error?.message));
    }
  };

  const formatDateTime = (time) => {
    const dataObj = new Date(time);
    const formatDate = dataObj.toLocaleDateString("en-GB");
    const formatTime = dataObj.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formatDate}, ${formatTime}`;
  };

  const handleothers = () => {
    setothers(!others);
  };

  const [customReason, setCustomReason] = useState("");
  console.log(customReason);
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value !== "others") {
      setothers(false);
    }
  };
  const [show, setShow] = useState(false);
  const [others, setothers] = useState();
  const handleClose = () => setShow(false);

  console.log(selectedOption, "option");
  // del comment req report
  const handleCommentReportSubmit = () => {
    const deleteReason = {
      delete_reason:
        selectedOption === "others" ? customReason : selectedOption,
    };
    console.log(deleteReason, "deleteReason");
    console.log(commentIdForReport, "com del req id");
    commnetDelReq(commentIdForReport, deleteReason)
      .then((response) => {
        if (response.status === true) {
          console.log("Comment reported:", response);
        } else {
          console.log("Error in reporting comment");
        }
      })
      .catch((error) => console.log(error.message));
    handleClose();
  };

  const [commentid, setCommentid] = useState(null);
  const deleteCommentById = () => {
    if (commentid) {
      deleteComment(commentid)
        .then((response) => {
          if (response.status === true) {
            console.log(response, "res delete comment");
            getAllMemoriesById();
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error?.message));
    }
    setShowDeletePopup(false);
  };

  const handleDeletePopup = (deleteCommentId) => {
    setOpenedCommentId(null);
    setShowDeletePopup(true);
    setCommentid(deleteCommentId);
    console.log("delete");
  };

  const [replyingCommentId, setReplyingCommentId] = useState(null);
  const [replyText, setReplyText] = useState("");
  const handleReplyClick = (commentId) => {
    setReplyingCommentId(replyingCommentId === commentId ? null : commentId);
  };

  const [showReplies, setShowReplies] = useState();
  const openReplies = (commentId) => {
    setShowReplies(showReplies === commentId ? null : commentId);

    console.log("show replies");
  };

  // reply to comment

  const handleSubmitReply = (parentcommentId) => {
    const replyData = {
      // memories_id: id,
      parent_comment_id: parentcommentId,
      comment: replyText,
    };
    console.log(replyData, "replyData");
    replyComment(id, replyData)
      .then((response) => {
        if (response.status === true) {
          console.log(response, "relpy to comments");
          setReplyText("");
          getAllMemoriesById();
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };

  const handleClosereplySection = () => {
    setReplyingCommentId(null);
    setReplyText("");
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenedCommentId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  console.log(getMemorydata, "getMemorydata");
  return (
    <>
      <div>
        <div className="h-6vh pink-bg fw-bold font-20 clr-white flex-start-center ">
          <IoArrowBack
            className="font-30 mx-2"
            onClick={() => navigate("/memories")}
          />
          People who likes and comments
        </div>
        <div className="p-2  d-flex flex-center">
          <img
            src={getMemorydata?.memory_image || Images?.Tour1}
            alt=""
            className="w-90 h-17vh"
          />
        </div>
        <div className="w-100 d-flex flex-row align-items-center border-bottom-like input-bg">
          <div
            className={`flex-row-jc-c w-50 font-20 fw-bold py-2 ${
              activeButton === "like" ? "border-bottom-pink2" : ""
            }`}
            onClick={() => setActiveButton("like")}
          >
            <FaHeart className="font-24 mr-1 clr-red" />
            Like ({getMemorydata?.total_likes_count})
          </div>
          <div
            className={`flex-row-jc-c w-50 font-20 fw-bold py-2 ${
              activeButton === "comments" ? "border-bottom-pink2" : ""
            }`}
            onClick={() => setActiveButton("comments")}
          >
            <FaCommentDots className="font-24 mr-1 comment-clr" />
            Comments ({getMemorydata?.comments_count})
          </div>
        </div>

        {activeButton === "like" && (
          <div className="white-bg message-sec">
            {getMemorydata?.likes?.map((like, index) => (
              <div
                key={index}
                className="d-flex flex-row border-bottom-grey p-2"
              >
                <div className="w-20 d-flex justify-content-center pos-relative">
                  <img
                    src={Images.Boy || like?.like_user_profile_image}
                    alt="profile"
                  />
                  <div className="like-pink-bg br-50 like-highlight pos-like">
                    <FaHeart
                      className="clr-white"
                      style={{ fontSize: "8px" }}
                    />
                  </div>
                </div>
                <div className="w-80 d-flex flex-column">
                  <div className="font-14 fw-bold">{like?.like_user_name}</div>
                  <div>{formatDateTime(like?.created_at)}</div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="h-73vh scroll">
          {activeButton === "comments" &&
            getMemorydata?.comments?.map((comment, index) => (
              <div className="d-flex flex-column px-2">
                <div className="d-flex flex-row  align-items-start my-2 w-100">
                  <div className="w-17 d-flex justify-content-center">
                    <img
                      src={Images.Boy || comment?.comment_user_profile_image}
                      alt=""
                      className="h-5vh"
                    />
                  </div>
                  <div className="d-flex flex-column w-80">
                    <div className="d-flex flex-column font-14 input-bg br-10 p-1 position-relative">
                      <div className="d-flex flex-between">
                        <div className="ms-1">{comment?.comment_user_name}</div>
                        <div>
                          {comment?.comment_user_id !== loginUserId && (
                            <div
                              className="clr-black py-1 border-bottom-grey font-14"
                              onClick={() => handleShow(comment?.comment_id)}
                            >
                              <FaFlag className="me-1" />
                              Report
                            </div>
                          )}
                          {comment?.comment_user_id === loginUserId && (
                            <div>
                              <span>
                                <HiDotsVertical
                                  className="cursor"
                                  onClick={() =>
                                    handleOptions(comment?.comment_id)
                                  }
                                />
                              </span>
                            </div>
                          )}

                          <div className="d-flex flex-end">
                            {openedCommentId === comment?.comment_id && (
                              <div
                                className="d-flex flex-column pos-delete-option p-2 br-10"
                                ref={dropdownRef}
                              >
                                <div className="clr-black py-1 border-bottom-grey font-14">
                                  <FaTrashAlt className="me-1" />

                                  <span
                                    onClick={() =>
                                      handleDeletePopup(comment?.comment_id)
                                    }
                                  >
                                    Delete
                                  </span>
                                </div>
                                <div
                                  className="clr-black py-1 border-bottom-grey font-14"
                                  onClick={() =>
                                    handleEdit(
                                      comment?.comment_id,
                                      comment?.comment
                                    )
                                  }
                                >
                                  <FaEdit className="me-1" />
                                  Edit
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="grey-text mt-1 ms-1">
                        {comment?.comment}
                        {comment?.edit === 1 && (
                          <span className="font-14 clr-grey mx-1">
                            (edited)
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-row font-14 px-2 justify-content-between grey-text mt-1">
                      <div>
                        <div>{formatDateTime(comment?.created_at)}</div>
                      </div>
                      <div>
                        {comment?.comment_user_id !== loginUserId && (
                          <div>
                            <span
                              onClick={() =>
                                handleReplyClick(comment?.comment_id)
                              }
                            >
                              <LuReply className="clr-black" />
                              Reply
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex flex-column mt-1">
                      {replyingCommentId === comment?.comment_id && (
                        <div>
                          <div className="d-flex w-20rem px-3 py-3 bottom-line">
                            <input
                              className="input-none px-1 py-1 border-bottom-pink2"
                              type="text"
                              placeholder="Write a reply..."
                              value={replyText}
                              onChange={(e) => setReplyText(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleSubmitReply(comment?.comment_id);
                                }
                              }}
                            />
                          </div>

                          <div className="d-flex flex-end">
                            <div
                              className="input-bg px-2 br-20 py-1"
                              onClick={() =>
                                handleSubmitReply(comment?.comment_id)
                              }
                            >
                              Reply
                            </div>
                            <div
                              className=" px-2 ms-1 mt-1"
                              onClick={handleClosereplySection}
                            >
                              cancel
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mt-2 mb-2 d-flex flex-start flex-column">
                      <span
                        className={`clr-blue cursor-pointer px-1 py-1 br-10 mt-1 ms-3 ${
                          comment?.replies?.length > 0 ? "input-reply-bg" : ""
                        }`}
                        onClick={() => openReplies(comment?.comment_id)}
                      >
                        {comment?.replies?.length > 0 && (
                          <>
                            {showReplies ? (
                              <span>
                                <IoIosArrowUp className="large-font" />
                                {comment?.replies?.length} replies
                              </span>
                            ) : (
                              <span>
                                <IoIosArrowDown className="large-font" />
                                {comment?.replies?.length} replies
                              </span>
                            )}
                          </>
                        )}
                      </span>

                      {showReplies === comment?.comment_id &&
                        comment?.replies?.map((reply, index) => (
                          <div className="d-flex input-bg p-1 mt-2 ">
                            <img
                              className="h-5vh"
                              src={
                                Images.chatpic1 ||
                                reply?.comment_user_profile_image
                              }
                              alt="profile"
                            />
                            <div className="d-flex flex-column">
                              <div className="d-flex flex-row">
                                <div className="mx-1 font-14 grey-text">
                                  {reply?.comment_user_name}
                                </div>
                                <div className="mx-1 font-10 mt-1">
                                  {moment(reply?.created_at).fromNow()}
                                </div>
                              </div>
                              <div>
                                <div className="ms-2 font-14 grey-text">
                                  {reply?.comment}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="footer">
          <div className="w-93 border-black py-1 br-10 d-flex mx-3 mt-1 input-bg align-items-center ">
            <div className="w-90">
              <input
                type="text"
                placeholder="write a comment....."
                className="input-none py-2 w-100 mx-1"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    createComment();
                  }
                }}
              />
            </div>

            <div className="border-right-line text-center clr-pink font-medium mx-2 white-bg br-20 px-2 py-1">
              <IoClose
                onClick={() => {
                  setReplyText("");
                  setComment("");
                }}
              />
            </div>
            <div
              className="font-medium text-center clr-pink mx-2 white-bg br-20 px-2 py-1"
              onClick={createComment}
            >
              <LuSendHorizonal />
            </div>
          </div>
        </div>
      </div>

      <DeletePopup
        showConfirmationPopup={showDeletePopup}
        setShowConfirmationPopup={setShowDeletePopup}
        handleConfirm={deleteCommentById}
        description="Are you sure to delete this comment?"
      />

      <div>
        <IonModal
          isOpen={show}
          size="md"
          onDidDismiss={handleClose}
          // cssClass="delete-popup border-grey br-10"
          className="custom-modal"
          centered
        >
          <div className="white-bg br-20">
            <div className="d-flex flex-between m-2 border-bottom-grey">
              <div className="mx-1 font-20">Select Option</div>
              <IoClose onClick={handleClose} className="mx-1 font-24" />
            </div>

            <div className="mx-2 m-2">
              <div>
                <input
                  className="mx-1 curosor-pointer"
                  type="radio"
                  id="option1"
                  name="options"
                  value="Offensive Language"
                  checked={selectedOption === "option1"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="option1">Offensive Language</label>
              </div>
              <div>
                <input
                  className="mx-1 cursor-pointer"
                  type="radio"
                  id="option2"
                  name="options"
                  value="Harassment or Bullying"
                  checked={selectedOption === "option2"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="option2">Harassment or Bullying</label>
              </div>
              <div>
                <input
                  className="mx-1 cursor-pointer"
                  type="radio"
                  id="option3"
                  name="options"
                  value="Spam or Misleading"
                  checked={selectedOption === "option3"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="option3">Spam or Misleading</label>
              </div>
              <div>
                <input
                  className="mx-1 cursor-pointer"
                  type="radio"
                  id="option2"
                  name="options"
                  value="others"
                  checked={selectedOption === "others"}
                  onClick={handleOptionChange}
                />
                <label>Others</label>
                {selectedOption === "others" && (
                  <div>
                    <textarea
                      className="w-100 cursor-pointer"
                      placeholder="Enter your custom report"
                      value={customReason}
                      onChange={(e) => setCustomReason(e.target.value)}
                    ></textarea>
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex flex-end pb-2">
              <div
                className="pink-bg px-2 py-2 br-5 w-20 mx-3 mb-2"
                onClick={() => handleCommentReportSubmit()}
              >
                Submit
              </div>
            </div>
          </div>
        </IonModal>
      </div>
    </>
  );
}

export default LikesPage;
