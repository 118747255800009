import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Login from "../pages/popups/Login";
import { IonModal } from "@ionic/react";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loginModal, setLoginModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("jwt_token")
  );

  useEffect(() => {
    if (!isAuthenticated) {
      setLoginModal(true); 
      navigate("/", { state: { from: location } });
    }
  }, [isAuthenticated, navigate, location]);

  if (isAuthenticated) {
    return children;
  }

  return (
    <IonModal
      isOpen={loginModal}
      onDidDismiss={() => setLoginModal(false)}
      cssClass="auto-height-modal"
    >
      <Login
        loginModal={loginModal}
        setLoginModal={setLoginModal}
        setIsAuthenticated={setIsAuthenticated} // Pass function to update auth status
      />
    </IonModal>
  );
};

export default PrivateRoute;
