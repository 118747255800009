import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { IonCol, IonRow } from "@ionic/react";
import { getTicketByUserId } from "../../api/apiMethods";
import ViewTicketsPopup from "./ViewTicketsPopup";
import moment from "moment";

function Tickets() {
  const userID = localStorage.getItem("userId");
  const [getTicketData, setTicketData] = useState([]);
  const [showTickets, setShowTickets] = useState(false);

  const getTripTickets = () => {
    getTicketByUserId(userID)
      .then((response) => {
        if (response?.status === true) {
          console.log(response, "Ticket Response");
          setTicketData(response.data.data);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => console?.log(error));
  };

  useEffect(() => {
    getTripTickets();
  }, []);
  const [ticketDetails, setTicketDetails] = useState(null);

  const handleTickets = (ticket) => {
    setTicketDetails(ticket);
    setShowTickets(true);
  };
  return (
    <div className="input-bg p-2">
      <div className="white-bg my-1 h-80vh">
        <h4 className="password-clr px-2 my-1 py-1 fw-700">Tickets</h4>
        <IonRow class="password-clr font-16 fw-600 border-grey">
          <IonCol size="3" className="text-center">
            S NO
          </IonCol>
          <IonCol size="4" className="text-start">
            Tour Title
          </IonCol>
          <IonCol size="4" className="text-center">
            Amount
          </IonCol>
          <IonCol size="1"></IonCol>
        </IonRow>
        <div className="overflow-auto h-35vh p-1">
          {getTicketData.map((ticket, index) => (
            <IonRow
              class="py-1 password-clr font-14 fw-500 border-bottom-grey d-flex align-items-center"
              key={ticket.id}
            >
              <IonCol size="3" className="text-center">
                <div>{index + 1}</div>
                <div className="my-1">
                  {moment(ticket.start_date).format("DD-MM-YYYY")}
                </div>
                {/* <div>{new Date(ticket.start_date).toLocaleDateString()}</div> */}
              </IonCol>
              <IonCol size="4" className="text-center">
                <div className="d-flex flex-column">
                  <div className="fw-600">{ticket.tour_title}</div>
                  <div className="my-1">
                    {ticket.tickets_status === "approved" && (
                      <div className="approved py-1 br-20">Approved</div>
                    )}
                    {ticket.tickets_status === "pending" && (
                      <div className="pending py-1 br-20">Pending</div>
                    )}
                    {ticket.tickets_status === "rejected" && (
                      <div className="rejected py-1 br-20">Rejected</div>
                    )}
                  </div>
                </div>
              </IonCol>
              <IonCol size="4" className="text-center">
                {ticket.ticket_price}
              </IonCol>
              <IonCol size="1">
                <AiFillEye
                  className="font-20 clr-pink"
                  onClick={() => handleTickets(ticket)}
                />
              </IonCol>
            </IonRow>
          ))}
        </div>
      </div>
      <ViewTicketsPopup
        showTickets={showTickets}
        setShowTickets={setShowTickets}
        ticketDetails={ticketDetails}
      />
    </div>
  );
}

export default Tickets;
