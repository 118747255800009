import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { updateUserWithdrawDetailsByID } from "../../api/apiMethods";
import { useSelector } from "react-redux";
import SubmitPopup from "../popups/SubmitPopup";
import { IonCol, IonModal, IonRow } from "@ionic/react";

function EditWithdrawDetailsPopup({
  editWithdrawDetailPopup,
  setEditWithdrawDetailPopup,
  withdrawalDetails,
  userWalletStatsData,
  selectWithdrawId,
}) {
  console.log(selectWithdrawId, "selectWithdrawId");
  console.log(withdrawalDetails, "withdrawalDetails");
  const UserID = useSelector((state) => state.userDetailsData.id);

  const [editedDetails, setEditedDetails] = useState({
    withdrawAmount: withdrawalDetails?.withdrawAmount || "",
    balance_amount: withdrawalDetails?.balance_amount || "",
    user_id: UserID,
    status: withdrawalDetails?.status || "approved",
  });

  const [zoomedImage, setZoomedImage] = useState(null);
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);

  const handleImageClick = (imagePath) => {
    setZoomedImage(imagePath);
  };

  const closeZoomModal = () => {
    setZoomedImage(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getUserWithdrawEditedData = () => {
    const payload = {
      withdrawAmount: editedDetails.withdrawAmount,
      balance_amount: editedDetails.balance_amount,
      user_id: editedDetails.user_id,
      status: editedDetails.status,
    };

    updateUserWithdrawDetailsByID(selectWithdrawId, payload)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setEditWithdrawDetailPopup(false);
          setShowSubmitPopup(true);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };

  const closeSubmitPopup = () => {
    setShowSubmitPopup(false);
  };

  useEffect(() => {
    setEditedDetails({
      withdrawAmount: withdrawalDetails?.withdrawAmount || "",
      balance_amount: withdrawalDetails?.balance_amount || "",
      user_id: "3",
      status: withdrawalDetails?.status || "approved",
    });
  }, [withdrawalDetails]);

  return (
    <div>
      <IonModal
        isOpen={editWithdrawDetailPopup}
        onDidDismiss={() => setEditWithdrawDetailPopup(false)}
        className="custom-modal"
        centered
      >
        <div className="p-2 br-10 white-bg">
          <div className="flex-between">
            <div className="text-black large-font fw-bold">
              Edit Withdraw Details
            </div>
            <RxCross2
              className="pointer font-2rem"
              onClick={() => setEditWithdrawDetailPopup(false)}
            />
          </div>

          <IonRow>
            <IonCol className="col-6">
              <div className="flex-column">
                <label className="mb-1">Role</label>
                <input
                  type="text"
                  value={withdrawalDetails?.role_name || ""}
                  placeholder="Customer"
                  className="input-box1 br-10 p-2  fw-500"
                  readOnly
                />
              </div>
            </IonCol>
            <IonCol className="col-6">
              <div className="flex-column">
                
                <label className="mb-1">Name</label>
                <input
                  type="text"
                  value={withdrawalDetails?.name || ""}
                  placeholder="Srinivas"
                  className="input-box1 br-10 p-2  fw-500"
                  readOnly
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="6">
              <input
                type="text"
                placeholder="Balance"
                value={userWalletStatsData?.balance || ""}
                className="input-box1 br-10 p-2 fw-500 w-100"
                readOnly
              />
            </IonCol>
            <IonCol size="6">
              <input
                type="text"
                placeholder="Withdraw Balance"
                value={withdrawalDetails?.withdrawAmount || ""}
                className="input-box1 br-10 p-2 fw-500 w-100"
                readOnly
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <div className="flex-column">
                <label className="mb-1">Enter Amount</label>
                <input
                  type="number"
                  placeholder="Amount"
                  name="withdrawAmount"
                  value={editedDetails.withdrawAmount}
                  className="input-box1 br-10 p-2  fw-500"
                  min={1000}
                  onChange={handleInputChange}
                />
              </div>
            </IonCol>
            <IonCol size="6">
              <div className="flex-column">
                <label className="mb-1">Payment Mode</label>
                <input
                  type="text"
                  placeholder="Payment Mode"
                  value={withdrawalDetails?.payment_mode || ""}
                  className="input-box1 br-10 p-2  fw-500"
                  readOnly
                />
              </div>
            </IonCol>
            <div className="flex-column w-100 mt-2 font-14 fw-600">
              <label className="mb-1">UPI/Bank Details</label>
              <textarea
                className="input-box1 br-10 p-2  fw-500"
                value={withdrawalDetails?.bank_details || "N/A"}
                placeholder="Enter your UPI or Bank details here"
                readOnly
              />
            </div>
            <div className="flex-column w-100 mt-2 font-14 fw-600">
              <label className="mb-1">Payment Receipt</label>
              <img
                className="w-100 h-10vh cursor-pointer"
                src={withdrawalDetails?.path}
                onClick={() => handleImageClick(withdrawalDetails?.path)}
                alt="Receipt"
              />
            </div>
          </IonRow>

          <div
            className="pink-bg text-center py-2 clr-white br-20"
            onClick={getUserWithdrawEditedData}
          >
            Save Changes
          </div>
        </div>
      </IonModal>

      {/* Zoom Image Modal */}
      {zoomedImage && (
        <IonModal size="lg" show={true} centered onHide={closeZoomModal}>
          <img
            src={zoomedImage}
            alt="Zoomed Receipt"
            className="w-100"
            style={{ maxHeight: "90vh", objectFit: "contain" }}
          />
          <button
            onClick={closeZoomModal}
            className="btn btn-secondary position-absolute top-0 end-0 m-3"
          >
            Close
          </button>
        </IonModal>
      )}

      <SubmitPopup
        successPopup={showSubmitPopup}
        setSuccessPopup={setShowSubmitPopup}
        title="Success !!!"
        description="Your Data Edited Successfully"
        closePopup={closeSubmitPopup}
      />
    </div>
  );
}

export default EditWithdrawDetailsPopup;
