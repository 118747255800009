import { IonModal } from "@ionic/react";
import React from "react";
import { FaThumbsUp } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

function SuccessPopup({ showSuccess, setShowSuccess, title, description }) {
  const handleSuccessClose = () => {
    window.location.reload();
    setShowSuccess(false);
  };
  return (
    <IonModal
      isOpen={showSuccess}
      // cssClass="cancel-popup auto-height-modal"
      // backdropDismiss={false}
      className="custom-modal"
    >
      
      <div className="p-3 white-bg">
        <div className="w-100 d-flex justify-content-end">
          <RxCross2 className="font-20" onClick={() => handleSuccessClose()} />
        </div>
        <div className="w-100 d-flex justify-content-center mb-2">
          <div className="w-30 green-bg p-4 text-center br-50">
            <FaThumbsUp className="clr-white font-40" />
          </div>
        </div>

        <div className="px-4 my-2">
          <h5 className="password-clr w-100 text-center fw-600">{title}</h5>
          <div className="font-14 fw-500 w-100 text-center grey-text">
            {description}
          </div>
          <div
            className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2"
            onClick={() => handleSuccessClose()}
          >
            Go Back
          </div>
        </div>
      </div>
    </IonModal>
  );
}

export default SuccessPopup;
