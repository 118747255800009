import { IonCol, IonModal, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";

function ViewDepositViewPopup({
  viewDepositDetails,
  setViewDepositDetails,
  viewDepositMode,
  depositDetails,
}) {
  return (
    <>
      <IonModal
        isOpen={viewDepositDetails}
        onDidDismiss={() => setViewDepositDetails(false)}
        className="custom-modal"
        centered
      >
        <div className="p-2 white-bg br-10">
          <div className="flex-between">
            <h6 className="text-black fw-bold">
              {viewDepositMode
                ? "View Withdraw Details"
                : "Edit Withdraw Details"}
            </h6>
            <RxCross2
              className="pointer font-2rem"
              onClick={() => setViewDepositDetails(false)}
            />
          </div>

          <IonRow className="row mt-2 font-14 ">
            <IonCol size="6">
              <div className="w-100 flex-column">
                <label className="mb-1">Payment Gateway</label>
                <input
                  type="text"
                  value={depositDetails?.gateway_name || ""}
                  placeholder="Customer"
                  className="input-box1 br-10 p-2  fw-500"
                  readOnly={viewDepositMode}
                />
              </div>
            </IonCol>
            <IonCol size="6">
              <div className="w-100 flex-column">
                <label className="mb-1">Amount</label>
                <input
                  type="text"
                  value={depositDetails?.deposit_amount || ""}
                  placeholder="Srinivas"
                  className="input-box1 br-10 p-2  fw-500"
                  readOnly={viewDepositMode}
                />
              </div>
            </IonCol>
          </IonRow>

          <div className="mt-2 font-14 ">
            <div className="w-100 flex-column">
              <label className="mb-1">Utr No</label>
              <input
                type="text"
                placeholder="Payment Mode"
                value={depositDetails?.utr_no || ""}
                className="input-box1 br-10 p-2  fw-500"
                readOnly={viewDepositMode}
              />
            </div>
            <div className="w-100 flex-column mt-2">
              <label className="mb-1">Upi Details</label>
              <textarea
                value={`${depositDetails?.mobile_number || ""}\n ${
                  depositDetails?.tnx_id || ""
                }\n ${depositDetails?.upi_id || ""}\n${
                  depositDetails?.upi_name || ""
                }`}
                className="input-box1 font-14 br-10 p-2  fw-500"
                readOnly={viewDepositMode}
              />
            </div>
            <div className="w-100 flex-column mt-2">
              <label className="mb-1">Uploaded Screenshot</label>
              <img
                src={depositDetails?.payment_image}
                alt="payment"
                className="h-10vh w-100"
              />
            </div>
          </div>
        </div>
      </IonModal>
    </>
  );
}

export default ViewDepositViewPopup;
