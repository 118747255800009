import React from "react";

function PrivacyPolicy() {
  return (
    <div className="p-1 input-bg">
      <h1 className="password-clr my-2 mx-1">Privacy Policy</h1>
      <div className="white-bg px-2 py-3 my-2 privacy-content-scroll">
        <span className="grey-text">
          This company established under the laws of Costa Rica, with registered
          address at Costa Rica and having its gaming sublicence No. 1669/KAV
          issued by Costa Rica e-Gaming and all rights to operate the gaming
          software. Freestyle is a company established under the laws of Cyprus,
          with registered address at Flamoudiou, 13, Strovolos 2036, Nicosia,
          Cyprus. These Terms & Conditions apply to you, and are binding upon
          you, if you Participate at T EXCHANGE. By Participating, you agree
          that you have read and understood these Terms & Conditions and you
          acknowledge that these Terms & Conditions shall apply to you. This
          company established under the laws of Costa Rica, Lorem Ipsum is
          simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum Lorem Ipsum is simply dummy text of the
          printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took
          a galley of type and scrambled it to make a type specimen book. It has
          survived not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. It was popularised in
          the 1960s with the release of Letraset sheets containing Lorem Ipsum
          passages, and more recently with desktop publishing software like
          Aldus PageMaker including versions of Lorem Ipsum Lorem Ipsum is
          simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum Lorem Ipsum is simply dummy text of the
          printing and typesetting industry.
        </span>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
