import React, { useEffect, useState } from "react";
import { MdOutlineFileDownload, MdRemoveRedEye } from "react-icons/md";
import { IonModal, IonButton, IonContent, IonImg } from "@ionic/react";
import BookNow from "../booknowpopup/BookNow";
import { useParams } from "react-router";
import { getPublishedTourById } from "../../api/apiMethods";
import { Images } from "../../images";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import moment from "moment";

function ToureDescriptionPage() {
  const { tourId } = useParams();
  const [selectedTourData, setSelectedTourData] = useState();
  const [showBookNow, setShowBookNow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const handleBookNowBtn = () => setShowBookNow(true);
  const closeModal = () => setShowModal(false);
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = Images.Documents;
    link.download = "protoType.pdf";
    link.click();
  };
  const handleView = () => {
    setModalImage(Images.Documents);
    setShowModal(true);
  };

  const fetchTourDataById = () => {
    getPublishedTourById(tourId)
      .then((response) => {
        if (response?.status === true) {
          setSelectedTourData(response?.data[0]);
        } else {
          console.log("Something Went Wrong");
        }
      })
      .catch((error) => console.log(error?.message));
  };
  useEffect(() => {
    fetchTourDataById();
  }, [tourId]);

  const splideOptions = {
    type: "loop",
    // padding: "20%",
    focus: "center",
    // gap: "10px",
    pagination: true,
  };

  return (
    <div className="input-bg p-2 homecontent-sec">
      <div className="white-bg px-2 py-1 br-10">
        <div className="font-20 password-clr my-1 fw-700">
          {selectedTourData?.tour_title}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Splide aria-label="Tour Categories" options={splideOptions}>
            {selectedTourData?.banners?.map((item, index) => (
              <SplideSlide key={index}>
                <img
                  src={item?.path || Images.Casino}
                  alt="Casino"
                  className="w-100"
                />
              </SplideSlide>
            ))}
          </Splide>{" "}
        </div>
      </div>
      <div className="white-bg p-1 mt-1 br-10">
        <div className="font-20 clr-black fw-700 border-bottom-greyy py-1">
          Message
        </div>
        <div className="d-flex flex-column">
          <div className="password-cle fw-600 font-16 my-1">
            Where Does It come from?
          </div>
          <div className="grey-text fw-600 border-greyy p-1 br-10 mt-1">
            {selectedTourData?.quotations}
          </div>
          <span className="font-14 fw-600 text-end w-100 grey-text">
            {/* ThursDay 08:30Pm */}
            {moment(selectedTourData?.publish_from).format("dddd YYYY h:mm A")}
            </span>
        </div>
      </div>
      <div className="d-flex justify-content-end w-100">
        <div
          className="w-40 pink-bg py-2 br-20 clr-white fw-700 text-center my-2"
          onClick={handleBookNowBtn}
        >
          Book Now
        </div>
      </div>

      <BookNow
        showBookNow={showBookNow}
        setShowBookNow={setShowBookNow}
        tourId={tourId}
        selectedTourData={selectedTourData}
      />

      <IonModal
        isOpen={showModal}
        onDidDismiss={closeModal}
        cssClass="reset-pswd-popup border-grey br-20"
      >
        <IonContent className="ion-padding">
          <IonImg src={modalImage} className="modal-image" />
          <IonButton
            expand="full"
            onClick={closeModal}
            className="ion-margin-top"
          >
            Close
          </IonButton>
        </IonContent>
      </IonModal>
    </div>
  );
}

export default ToureDescriptionPage;
