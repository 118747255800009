import React, { useEffect } from "react";
import { IonPage, isPlatform } from "@ionic/react";
import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ChatHeader from "../pages/chat/ChatHeader";
import ChatFooter from "../pages/chat/ChatFooter";
import CustomHeader from "../components/CustomHeader";
import HomeContent from "../pages/homepage/HomeContent";
import HomeContentTwo from "../pages/homepage/HomeContentTwo";
import CasinoTour from "../pages/tours/CasinoTour";
import Trips from "../pages/trips/Trips";
import Notifications from "../pages/notifications/Notifications";
import Memories from "../pages/memories/Memories";
import Tickets from "../pages/tickets/Tickets";
import Chat from "../pages/chat/Chat";
import ChatScreen from "../pages/chat/ChatScreen";
import LikesPage from "../pages/likescomment/LikesPage";
import ToureDescriptionPage from "../pages/tours/ToureDescriptionPage";
import PrivacyPolicy from "../pages/notifications/PrivacyPolicy";
import PrivatePhotos from "../pages/memories/PrivatePhotos";
import EditProfile from "../pages/editprofile/EditProfile";
import Wallet from "../pages/wallet/Wallet";

const routes = [
  { path: "/", element: <HomeContent />, exact: true },
  { path: "/home", element: <HomeContentTwo />, private: true, exact: true },
  { path: "/trips", element: <Trips />, private: true, exact: true },
  {
    path: "/tours/:tourCategory",
    element: <CasinoTour />,
    private: true,
    exact: true,
  },
  {
    path: "/notifications",
    element: <Notifications />,
    private: true,
    exact: true,
  },
  { path: "/privacy", element: <PrivacyPolicy />, exact: true },
  {
    path: "/memories",
    element: <Memories />,
    private: true,
    exact: true,
  },
  {
    path: "/Tour-Description",
    element: <ToureDescriptionPage />,
    private: true,
    exact: true,
  },
  { path: "/tickets", element: <Tickets />, private: true, exact: true },
  { path: "/chat", element: <Chat />, private: true, exact: true },
  { path: "/chat/:id", element: <ChatScreen />, private: true, exact: true },
  { path: "/likes/:id", element: <LikesPage />, private: true, exact: true },
  {
    path: "/private-images",
    element: <PrivatePhotos />,
    private: true,
    exact: true,
  },

  {
    path: "/tour/:tourCategory/:tourId",
    element: <ToureDescriptionPage />,
    private: true,
    exact: true,
  },
  {
    path: "/edit-profile",
    element: <EditProfile />,
    private: true,
    exact: true,
  },
  {
    path: "/wallet",
    element: <Wallet />,
    private: true,
    exact: true,
  },
];

function Routing() {
  const { pathname } = useLocation();
  const isChatScreen = pathname.startsWith("/chat/") && pathname !== "/chat";
  const isLikesPage = pathname.startsWith("/likes");
  const isPrivatePhotos = pathname === "/private-images";
  const isAuthorized = !!localStorage.getItem("jwt_token");
  const isEditProfile = pathname.startsWith("/edit-profile");

  const renderHeader = () => {
    if (isEditProfile) return null;
    if (isPrivatePhotos) return null;
    if (isLikesPage) return <CustomHeader />;
    if (isChatScreen) return <ChatHeader />;
    return pathname === "/" ? <Header /> : <CustomHeader />;
  };

  const renderFooter = () => {
    if (!isAuthorized || isLikesPage || isEditProfile || isPrivatePhotos)
      return null;
    return isChatScreen ? <ChatFooter /> : <Footer />;
  };
  const redirectToAppropriateView = () => {
    if (isPlatform("desktop") || window.innerWidth >= 768) {
      window.location.href = "https://top2tours.com";
    }
  };

  useEffect(() => {
    redirectToAppropriateView();
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        window.location.href = "https://top2tours.com";
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      
      {isPlatform("mobile") ? (
        <div>
          {renderHeader()}
          <IonPage
            className={
              isLikesPage
                ? "h-100vh"
                : "mt-8vh" && isEditProfile
                ? "h-100vh"
                : "mt-8vh" && isPrivatePhotos
                ? ""
                : "mt-8vh"
            }
          >
            <Routes>
              {routes.map(({ path, element, private: isPrivate, exact }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    isPrivate ? <PrivateRoute>{element}</PrivateRoute> : element
                  }
                  exact={exact}
                />
              ))}
            </Routes>
          </IonPage>
          {renderFooter()}
        </div>
      ) : null}
    </>
  );
}

export default Routing;
