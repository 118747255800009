import { IonModal } from "@ionic/react";
import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";

function DeletePopup({
  showConfirmationPopup,
  setShowConfirmationPopup,
  handleConfirm,
  description,
}) {
  const [active, setActive] = useState("yes");

  const buttons = [
    { id: "yes", label: "Yes" },
    { id: "reject", label: "No" },
  ];

  return (
    <IonModal
      isOpen={showConfirmationPopup}
      onDidDismiss={() => setShowConfirmationPopup(false)}
      centered
      // cssClass="delete-popup"
      className="custom-modal"
    >
      <div className="white-bg br-20 p-3">
      <div className="w-100 px-2 d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 text-end">
          <RxCross2
            className="clr-black font-20 cursor-pointer "
            onClick={() => setShowConfirmationPopup(false)}
          />
        </div>

        <div className="w-90 d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex w-80 flex-column  password-clr text-center my-2 large-font fw-600">
            {description}
          </div>
          <div className="w-100 d-flex flex-between">
            <div
              className="br-20 fw-600 font-14 text-center py-2 w-50 m-2 pink-bg clr-white px-2 cursor-pointer"
              onClick={handleConfirm}
            >
              Yes
            </div>
            <div
              className="br-20 fw-600 font-14 text-center w-50 py-2 m-2 px-2 white-bg clr-black border-grey cursor-pointer"
              onClick={() => setShowConfirmationPopup(false)}
            >
              No
            </div>
          </div>
        </div>
      </div>
      </div>
    </IonModal>
  );
}

export default DeletePopup;
