import { IonModal } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../api/apiMethods";

function Login({
  loginModal,
  setLoginModal,
  handleOtpModal,
  handleSignupModal,
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ user_name: "", password: "" });

  const handleLoginClose = () => {
    setLoginModal(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => {
    loginUser(formData)
      .then((response) => {
        if (response?.status) {
          localStorage.setItem("jwt_token", response.data.token);
          localStorage.setItem("user_name", response.data.user?.user_name);
          localStorage.setItem("isAuthorized", true);
          let myid = response.data.user.id;
          localStorage.setItem("userId", myid);
          setLoginModal(false);
          navigate("/home");
        } else {
          setErrorMessage("Login failed: Invalid credentials");
        }
      })
      .catch((error) => {
        setErrorMessage(error?.message || "An error occurred during login");
      });
  };

  return (
    <IonModal
      isOpen={loginModal}
      // cssClass="auto-height-modal login-popup"
      className="custom-modal"
      // backdropDismiss={false}
    >
      <div className="login p-3">
        <div className="text-end" onClick={handleLoginClose}>
          <RxCross2 className="font-30" />
        </div>
        <h2 className="password-clr w-100 text-center fw-600">Login</h2>

        <div className="d-flex flex-column">
          <div className="black-text font-16 fw-600 my-1">
            User Name <span className="star">*</span>
          </div>
          <div className="input-bg br-10 my-1">
            <input
              type="text"
              name="user_name"
              placeholder="Enter User Name"
              className="w-100 p-2 br-10 bg-none"
              onChange={handleChange}
              value={formData.user_name}
            />
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="black-text font-16 fw-600 my-1">
            Password <span className="star">*</span>
          </div>
          <div className="input-bg br-10 my-1 d-flex align-items-center">
            <input
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              className="w-90 p-2 br-10 bg-none"
              value={formData.password}
              onChange={handleChange}
            />
            {showPassword ? (
              <AiFillEyeInvisible
                className="font-30 clr-grey"
                onClick={togglePasswordVisibility}
              />
            ) : (
              <AiFillEye
                className="font-30 clr-grey"
                onClick={togglePasswordVisibility}
              />
            )}
          </div>
        </div>
        {errorMessage && <div className="red-clr my-1">{errorMessage}</div>}
        <div
          className="password-clr fw-600 my-1"
          onClick={() => handleOtpModal()}
        >
          Forget Your Password?
        </div>
        <div
          className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2"
          onClick={handleLogin}
        >
          Login
        </div>
        <div className="text-center" onClick={handleSignupModal}>
          <span className="grey-text">Don't have an account?</span>
          <span className="grey-text fw-700">Sign Up</span>
        </div>
      </div>
    </IonModal>
  );
}

export default Login;
