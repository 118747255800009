import React, { useEffect, useState } from "react";
import { IonCol, IonModal, IonRow } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";
import { FaEye, FaTimes } from "react-icons/fa";
import {
  createMemories,
  getToursBydates,
  getTourCategories,
  uploadImage,
} from "../../api/apiMethods";

function UploadVideos({ showUploadPopup, setShowUploadPopup, isVideoUpload }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles(files);
    uploadFiles(files);
  };

  const uploadFiles = (files) => {
    files.forEach((file) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "/uploadImages"); // replace with your upload endpoint

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: progress,
          }));
        }
      });

      xhr.upload.addEventListener("load", () => {
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: 100,
        }));
      });

      xhr.upload.addEventListener("error", () => {
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: "error",
        }));
      });

      const formData = new FormData();
      formData.append("file", file);
      xhr.send(formData);
    });
  };

  const handlePreview = (file) => {
    setSelectedFile(file);
    setShowModal(true);
  };
  const handleRemove = (file) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    setUploadProgress((prevProgress) => {
      const { [file.name]: _, ...rest } = prevProgress;
      return rest;
    });
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [toursData, setTourData] = useState([]);

  const getTourbydates = () => {
    getToursBydates({ StartDate: startDate, EndDate: endDate })
      .then((response) => {
        if (response.status === true) {
          console.log(response, "date wise tours---");
          setTourData(response?.data);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  useEffect(() => {
    if (startDate && endDate) {
      getTourbydates();
    }
  }, [startDate, endDate]);

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const handleCategory = (event) => {
    setSelectedCategoryId(event.target.value);
  };

  const filterTours = selectedCategoryId
    ? toursData.filter(
        (tour) => Number(tour?.category_id) === Number(selectedCategoryId)
      )
    : toursData;

  const [categories, setCategories] = useState([]);
  const getCategories = () => {
    getTourCategories({})
      .then((response) => {
        if (response.status === true) {
          console.log(response, "get categories");
          setCategories(response?.data?.category);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };
  useEffect(() => {
    getCategories();
  }, []);

  console.log(uploadedFiles, "selectedfile");
  const imgName = uploadedFiles?.map((img) => img?.name);
  console.log(imgName, "imgName");
  const ImgPath = {
    path: imgName,
    type: isVideoUpload ? "video" : "image",
  };

  const [uploaddata, setUploaddata] = useState([]);
  console.log(uploaddata, "===>uploaddata");
  console.log(ImgPath?.type, "===>ImgPath?.type");

  const uploadMemory = () => {
    uploadImage(ImgPath)
      .then((response) => {
        if (response.status === true) {
          console.log(response, "hdhs data");
          setUploaddata(response?.data);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  const imgIds = uploaddata?.map((item) => item?.id);
  console.log(imgIds, "img iddd");
  const [selectedTourId, setSelectedTourId] = useState(null);
  console.log(selectedTourId, "tour id");
  const handleTour = (e) => {
    setSelectedTourId(e.target.value);
  };

  const userId = localStorage.getItem("myid");
  const payload = {
    upload_id: imgIds,
    memory_name: "user_memory",
    tour_id: selectedTourId,
    user_id: Number(userId),
  };
  const postMemories = () => {
    createMemories(payload)
      .then((response) => {
        if (response.status === true) {
          console.log(response, "res cretae mem");
          setStartDate("");
          setEndDate("");
          setTimeout(() => {
            clearPayload({});
          }, 1000);

          setTimeout(() => {
            setShowUploadPopup(false);
          }, 1500);
          console.log("error");
        }
      })
      .catch((err) => console.log(err));
  };

  const clearPayload = () => {
    payload.upload_id = [];
    payload.memory_name = "";
    payload.tour_id = null;
    payload.user_id = null;
  };

  return (
    <>
      <IonModal
        isOpen={showUploadPopup}
        // cssClass="signup-popup auto-height-modal"
        className="custom-modal"
        backdropDismiss={false}
      >
        <div className="white-bg p-3 br-10">
          <div className="d-flex align-items-center justify-content-between">
            <h3>Upload Documents</h3>
            <RxCross2
              className="font-24 clr-black"
              onClick={() => setShowUploadPopup(false)}
            />
          </div>
          <div className="d-flex flex-column border-pink br-10 p-2 my-2">
              <IonRow>
                <IonCol size="6">
                  <div className="d-flex flex-column">
                    <label className="font-14 fw-bold password-clr">
                      Start Date
                    </label>
                    <input
                      type="date"
                      className="w-100 input-bg p-2 br-10 border-none font-14"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    ></input>
                  </div>
                </IonCol>
                <IonCol size="6">
                  <div className="d-flex flex-column">
                    <label className="font-14 fw-bold password-clr">
                      End Date
                    </label>
                    <input
                      type="date"
                      className="w-100 input-bg p-2 br-10 border-none font-14"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    ></input>
                  </div>
                </IonCol>
              </IonRow>
            <IonRow>
              <IonCol size="6">
                <div className="d-flex flex-column">
                  <label className="font-14 fw-bold password-clr">
                    Tour Category
                  </label>
                  <select
                    className="input-bg br-10 border-none font-14 w-100 py-2"
                    onChange={handleCategory}
                  >
                    <option>Select Category</option>
                    {categories?.map((category, index) => (
                      <option key={index} value={category?.category_id}>
                        {category?.category_name}
                      </option>
                    ))}
                  </select>
                </div>
              </IonCol>
              <IonCol size="6">
                <div className="d-flex flex-column">
                  <label className="font-14 fw-bold password-clr">
                    Tour Name
                  </label>
                  <select
                    className="w-100 px-1 py-2 input-bg br-10 border-none font-14"
                    onChange={handleTour}
                  >
                    <option>Select</option>
                    {filterTours?.map((tour, index) => (
                      <option key={index} value={tour?.tour_id}>
                        {tour?.tour_title}
                      </option>
                    ))}
                  </select>
                </div>
              </IonCol>
            </IonRow>
          </div>
          <div className="d-flex flex-column my-1">
            <div className="d-flex font-14 my-1  password-clr">
              Uploading
            </div>
            <div className="font-10 grey-text">
              Please wait while your file is uploaded. The upload progress will
              be displayed below. Quality is more important than time.
            </div>
          </div>
          <div className="d-flex flex-column my-1">
            <div className="d-flex font-14 my-1 fw-600 clr-pink">
              {isVideoUpload ? "Video Uploading" : "Photo Uploading"}
            </div>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              placeholder="select a file"
              className="font-14 grey-text font-14 fw-600 input-bg border-pink py-2 br-10 px-2"
            />
            <div className="mt-2">
              {uploadedFiles.length > 0 && (
                <div>
                  <div className="font-14 fw-600">Uploading Files:</div>
                  <ul className="h-10vh overflow-scroll font-14">
                    {uploadedFiles.map((file, index) => (
                      <li
                        key={index}
                        className="font-14 d-flex align-items-center justify-content-between"
                      >
                        <div className="font-14 w-20 truncated-text">
                          {file.name}
                        </div>
                        <div className="d-flex align-items-center w-80">
                          <div className="progress-bar-container">
                            <div
                              className="progress-bar"
                              style={{
                                width: `${uploadProgress[file.name] || 0}%`,
                              }}
                            ></div>
                            <div className="progress-percent">
                              {uploadProgress[file.name] === "error"
                                ? "Error"
                                : `${Math.round(
                                    uploadProgress[file.name] || 0
                                  )}%`}
                            </div>
                          </div>
                          <FaEye
                            style={{ cursor: "pointer", margin: "0 5px" }}
                            onClick={() => handlePreview(file)}
                          />
                          <FaTimes
                            style={{ cursor: "pointer", margin: "0 5px" }}
                            onClick={() => handleRemove(file)}
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="d-flex flex-end">
                    <div
                      className="mb-1 pink-bg py-1 br-10 clr-white w-25 text-center"
                      onClick={uploadMemory}
                    >
                      Upload
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex flex-column flex-end">
            <div
              className="pink-bg py-2 br-10 clr-white fw-600 text-center my-3"
              onClick={postMemories}
            >
              Submit
            </div>
          </div>
        </div>
      </IonModal>
      <IonModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        cssClass="file-preview-modal custom-modal auto-height-modal"
      >
        <div className="p-1">
          <div className="d-flex justify-content-end">
            <RxCross2
              className="font-24 clr-grey"
              onClick={() => setShowModal(false)}
            />
          </div>
          <div className="file-preview-header">
            {selectedFile && (
              <div className="file-name">
                <strong>File Name:</strong> {selectedFile.name}
              </div>
            )}
          </div>
          <div className="file-preview-content">
            {selectedFile && selectedFile.type.startsWith("image/") && (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="preview"
                className="img-preview"
              />
            )}
            {selectedFile && selectedFile.type.startsWith("video/") && (
              <video controls className="video-preview">
                <source
                  src={URL.createObjectURL(selectedFile)}
                  type={selectedFile.type}
                />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
      </IonModal>

      {/* <IonModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        cssClass="otp-popup auto-height-modal"
      >
        <div className="p-1">
          <div className="d-flex justify-content-end">
            <RxCross2
              className="font-24 clr-grey"
              onClick={() => setShowModal(false)}
            />
          </div>
          <div className="file-preview-content">
            {selectedFile && selectedFile.type.startsWith("image/") && (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="preview"
                className="img-preview"
              />
            )}
            {selectedFile && selectedFile.type.startsWith("video/") && (
              <video controls className="video-preview">
                <source
                  src={URL.createObjectURL(selectedFile)}
                  type={selectedFile.type}
                />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
      </IonModal> */}
    </>
  );
}

export default UploadVideos;
