import React, { useEffect } from 'react';
import { IonApp, IonPage, setupIonicReact } from "@ionic/react";
import "./App.css";
import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "@splidejs/splide/css";
import { BrowserRouter } from "react-router-dom";
import Routing from "./routes/Routing";
import 'bootstrap/dist/css/bootstrap.min.css';


setupIonicReact();

function App() {
  useEffect(() => {
    
    const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobileDevice) {
      
    } else {
      
      const webAppLink = 'http://top2tours.com'; 
      window.location.href = webAppLink;
    }
  }, []);  
  return (
    <IonApp className="main-bg">
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </IonApp>
  );
}

export default App;
