import { RxCross2 } from "react-icons/rx";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createUserWithdraw, uploadImage } from "../../api/apiMethods";
import { uploadFilesToS3 } from "../../images/images";
import { IonCol, IonModal, IonRow } from "@ionic/react";
import SubmitPopup from "./SubmitPopup";

const Withdraw = ({
  withdraw,
  setWithdraw,
  userWalletStatsData,
  setIsViewOnly,
  isViewOnly,
}) => {
  const userDetails = useSelector((state) => state.userDetailsData);
  console.log(userWalletStatsData, "userWalletStatsData");
  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [uploadProgress, setUploadProgress] = useState(null);
  const [paymentTypeId, setPaymentTypeId] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState();

  const handleQrCodeUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("Please select a file first");
      return;
    }
    setSelectedFile(file);
    try {
      const url = await uploadFilesToS3([file], setUploadProgress);
      handleQrcodeSubmit(url);
    } catch (error) {
      console.error("File upload error:", error);
      setError("File upload failed. Please try again.");
    }
  };

  const handleQrcodeSubmit = (url) => {
    uploadImage({ path: url, type: "image" })
      .then((response) => {
        if (response?.status === true) {
          const paymentTypeIds = response.data.map((item) => item.id);
          setPaymentTypeId(paymentTypeIds);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => setError(error?.message));
  };
  const [amount, setAmount] = useState("");
  const [withdrawBalance, setWithdrawBalance] = useState(
    userWalletStatsData?.balance || 0
  );
  const [error, setError] = useState("");
  const [isWithdrawDisabled, setIsWithdrawDisabled] = useState(false);
  const [selectedPaymentGateway, setSelectedPaymentGateway] =
    useState("Phone Pe");
  const [bankDetails, setBankDetails] = useState("");
  useEffect(() => {
    setWithdrawBalance(userWalletStatsData?.balance || 0);
  }, [userWalletStatsData?.balance]);

  const handleAmountChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setAmount("");
      setError("");
      setWithdrawBalance(userWalletStatsData?.balance || 0);
      setIsWithdrawDisabled(false);
      return;
    }

    const enteredAmount = parseFloat(value);

    if (enteredAmount < 1000) {
      setError("The amount must be at least 1000.");
      setIsWithdrawDisabled(false);
      setWithdrawBalance(userWalletStatsData?.balance || 0);
    } else if (enteredAmount > (userWalletStatsData?.balance || 0)) {
      setError("Recharge your wallet.");
      setIsWithdrawDisabled(true);
    } else {
      setError("");
      setIsWithdrawDisabled(false);
      const updatedWithdrawBalance =
        (userWalletStatsData?.balance || 0) - enteredAmount;
      setWithdrawBalance(updatedWithdrawBalance);
    }

    setAmount(value);
  };

  const handlePaymentGatewayChange = (e) => {
    setSelectedPaymentGateway(e.target.value);
  };

  const handleBankDetailsChange = (e) => {
    setBankDetails(e.target.value);
  };
  const userWithdraw = () => {
    if (!amount || parseFloat(amount) < 1000 || error) {
      console.log("Please fix the errors before submitting.");
      return;
    }

    let payload = {
      withdrawAmount: parseFloat(amount),
      balance_amount: userWalletStatsData?.balance,
      user_id: userDetails.id,
      payment_mode: selectedPaymentGateway.toLowerCase(),
    };

    if (payload === "") {
      setError("please fill all fields");
      return;
    }

    if (selectedPaymentGateway === "QR Code") {
      payload.upload_id = Number(paymentTypeId);
    } else {
      payload.bank_details = bankDetails;
    }

    createUserWithdraw(payload)
      .then((response) => {
        if (response.status === true) {
          setWithdraw(false);
          setTimeout(() => {
            setShowSuccessPopup(true);
          }, []);
          setTimeout(() => {
            setError("");
          }, 1500);
        } else {
          console.log("Error Is Happening");
        }
      })
      .catch((error) => setError(error?.message));
  };

  useEffect(() => {
    userWithdraw();
  }, []);

  return (
    <>
      <IonModal
        isOpen={withdraw}
        onDidDismiss={() => setWithdraw(false)}
        className="custom-modal"
        centered
      >
        <div className="p-2 br-10 white-bg">
          <div className="flex-between">
            <h5 className="text-black fw-bold">Withdraw</h5>
            <RxCross2
              className="pointer font-2rem"
              onClick={() => setWithdraw(false)}
            />
          </div>
          <IonRow>
            <IonCol size="6">
              <div className="d-flex flex-column">
                {" "}
                <label className="mb-1">Role</label>
                <input
                  type="text"
                  value={userDetails?.role?.code}
                  placeholder="Customer"
                  className="input-box1 br-10 p-2  fw-500"
                  readOnly
                />
              </div>
            </IonCol>
            <IonCol size="6">
              <div className="flex-column">
                {" "}
                <label className="mb-1">Name</label>
                <input
                  type="text"
                  value={userDetails?.name}
                  placeholder="Srinivas"
                  className="input-box1 br-10 p-2 w-100  fw-500"
                  readOnly
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="6">
              <input
                type="text"
                placeholder="Balance"
                value={userWalletStatsData?.balance || 0}
                className="input-box1 br-10 p-2 w-100 fw-500"
                readOnly
              />
            </IonCol>
            <IonCol size="6">
              <input
                type="text"
                placeholder="Withdraw Balance"
                value={withdrawBalance}
                className="input-box1 br-10 p-2 w-100  fw-500"
                readOnly={isWithdrawDisabled}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <div className="flex-column">
                <label className="mb-1">Enter Amount</label>
                <input
                  type="number"
                  placeholder="Amount"
                  value={amount}
                  onChange={handleAmountChange}
                  className="input-box1 br-10 p-2  fw-500"
                  min={1000}
                />

                {error && <p className="small -font error-text">*{error}</p>}
              </div>
            </IonCol>
            <IonCol size="6">
              {" "}
              <div className="flex-column">
                <label className="mb-1">Payment Mode</label>
                <select
                  className="input-box1 br-10 p-2  fw-500 pointer"
                  value={selectedPaymentGateway}
                  onChange={handlePaymentGatewayChange}
                >
                  <option value="Phone Pe">Phone Pe</option>
                  <option value="Google Pay">Google Pay</option>
                  <option value="Paytm">Paytm</option>
                  <option value="NEFT">NEFT</option>
                  <option value="QR Code">QR Code</option>
                </select>
              </div>
            </IonCol>
          </IonRow>
          {selectedPaymentGateway === "QR Code" && (
            <div className="flex-column w-100 mt-2 font-14 fw-600">
              <div className="w-100 mt-2">
                <label className="font-14 fw-500 mb-2 text-black">
                  Upload QR Code
                </label>

                <input
                  type="file"
                  id="fileUpload"
                  className="file-input"
                  onChange={handleQrCodeUpload}
                />
                <label
                  htmlFor="fileUpload"
                  className="custom-file-upload input-bg px-2 py-1 font-14"
                >
                  <span className="font-14">Upload File</span>
                  <span className="upload-icon">📤</span>
                </label>

                {selectedFile && (
                  <div className="mt-2">
                    <p>Selected File: {selectedFile.name}</p>
                  </div>
                )}
                {error && (
                  <div className="text-danger mt-2">
                    <p>{error}</p>
                  </div>
                )}
              </div>
            </div>
          )}
          {selectedPaymentGateway !== "QR Code" && (
            <div className="flex-column w-100 mt-2 font-14 fw-600">
              <label className="mb-1">UPI/Bank Details</label>
              <textarea
                className="input-box1 br-10 p-2  fw-500"
                value={bankDetails}
                onChange={handleBankDetailsChange}
                placeholder="Enter your UPI or Bank details here"
              />
            </div>
          )}
          {qrCodeImage && (
            <div className="flex-center mt-2">
              <img src={qrCodeImage} alt="QR Code" className="qr-code-img" />
            </div>
          )}

          <div className="w-100 mt-3 font-14 fw-600">
            <div
              className="pink-bg clr-white p-2 rounded-pill text-center pointer"
              onClick={() => userWithdraw()}
            >
              Submit
            </div>
          </div>
          {error && <div className="clr-red font-14 my-2">{error}</div>}
        </div>
      </IonModal>
      <SubmitPopup
        successPopup={showSuccessPopup}
        setSuccessPopup={setShowSuccessPopup}
        title="Scuccess!!!"
        description="Submitted Deposit Request Successfully"
      />
    </>
  );
};

export default Withdraw;
