import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { IonCol, IonRow } from "@ionic/react";
import { FaCheckCircle, FaHourglassHalf, FaTimesCircle } from "react-icons/fa"; // Importing relevant icons for different statuses
import { RxCross2 } from "react-icons/rx";
import CancelPopup from "./CancelPopup";

function UpcomingTrips({
  bookingIdToCancel,
  setBookingIdToCancel,
  formatDateToIndian,
  showDocuments,
  setShowDocumnets,
  upcomingTours,
  handleDocuments,
}) {
  console.log(bookingIdToCancel, "====================>bookingIdToCancel");
  const [showCancel, setShowCancel] = useState(false);
  const handleCancelPopup = (bookingId) => {
    setBookingIdToCancel(bookingId);
    setShowCancel(true);
  };
  return (
    <div className="p-2">
      <div className="white-bg my-1">
        <h4 className="password-clr px-2 my-1 py-1 fw-700">Upcoming Tours</h4>
        <div className="overflow-auto p-1">
          <IonRow class="password-clr font-16 fw-600 border-grey">
            <IonCol size="3" className="text-center">
              S NO
            </IonCol>
            <IonCol size="4" className="text-start">
              Tour Title
            </IonCol>
            <IonCol size="5" className="text-start">
              Status/Docs
            </IonCol>
          </IonRow>
          {upcomingTours.map((tour, index) => (
            <IonRow
              class="py-1 password-clr font-14 fw-500 border-bottom-grey d-flex align-items-center"
              key={index}
            >
              <IonCol size="3" className="text-center">
                1<div>{formatDateToIndian(tour.schedule_from)}</div>
                <div className="clr-green">{tour.total_price}</div>
              </IonCol>
              <IonCol size="4" className="text-center">
                <div className="d-flex flex-column">
                  <div>{tour.tour_title}</div>
                </div>
              </IonCol>
              <IonCol size="5" className="text-center">
                <div className="w-100 d-flex justify-content-around align-items-center">
                  <div>
                    {tour.tour_status === "approved" && (
                      <FaCheckCircle className="approved clr-green font-35 py-1 br-20" />
                    )}
                    {tour.tour_status === "pending" && (
                      <FaHourglassHalf className="pending clr-orange font-20 py-1 br-20" />
                    )}
                    {tour.tour_status === "rejected" && (
                      <FaTimesCircle className="rejected clr-red font-20 py-1 br-20" />
                    )}
                  </div>
                  <AiFillEye
                    className="font-30 clr-pink"
                    onClick={() => handleDocuments(tour)}
                  />
                  <RxCross2
                    className="clr-red font-30 br-20"
                    onClick={() => handleCancelPopup(tour.booking_id)}
                  />
                </div>
              </IonCol>
            </IonRow>
          ))}
        </div>
      </div>
      <CancelPopup
        bookingIdToCancel={bookingIdToCancel}
        showCancel={showCancel}
        setShowCancel={setShowCancel}
        heading="Are You Sure? You want to cancel the trip?"
      />
    </div>
  );
}

export default UpcomingTrips;
