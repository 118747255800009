import { IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import React, { useState } from "react";
import { Images } from "./../images/index";
import Login from "../pages/popups/Login";
import SignUp from "../pages/popups/SignUp";
import Otp from "../pages/popups/Otp";
import SubmitPopup from "../pages/popups/SubmitPopup";
import { useNavigate } from "react-router";

function Header() {
  const navigate = useNavigate();

  const [successPopup, setSuccessPopup] = useState(false);
  const handleSuccessPopup = () => {
    setSuccessPopup(true);
    setOtpModal(false);
    setSignupModal(false);
  };
  const [OtpModal, setOtpModal] = useState(false);
  const handleOtpModal = () => {
    setOtpModal(true);
    setLoginModal(false);
  };
  const [activeButton, setActiveButton] = useState("login"); // 'login' or 'signup'

  const [loginModal, setLoginModal] = useState(false);
  const handleLoginModal = () => {
    setActiveButton("login");
    setLoginModal(true);
    setSignupModal(false);
    setOtpModal(false);
  };
  const [signupModal, setSignupModal] = useState(false);
  const handleSignupModal = () => {
    setActiveButton("signup");
    setSignupModal(true);
    setLoginModal(false);
  };

  return (
    <IonGrid className="header p-1">
      <IonRow>
        <IonCol size="5">
          <IonImg
            src={Images.HeaderLogo}
            className="tours-logo"
            alt=""
            onClick={() => navigate("/")}
          />
        </IonCol>
        <IonCol size="7" className="flex-center">
          <div className="w-100 flex-around">
            <div
              className={`px-3 py-2 ${
                activeButton === "login" ? "pink-btn" : "white-btn"
              }`}
              onClick={handleLoginModal}
            >
              Login
            </div>
            <div
              className={`px-3 py-2 ${
                activeButton === "signup" ? "pink-btn" : "white-btn"
              }`}
              onClick={handleSignupModal}
            >
              Signup
            </div>
          </div>
        </IonCol>
      </IonRow>
      <Login
        loginModal={loginModal}
        setLoginModal={setLoginModal}
        handleOtpModal={handleOtpModal}
        handleSignupModal={handleSignupModal}
      />
      <SignUp
        signupModal={signupModal}
        setSignupModal={setSignupModal}
        handleSuccessPopup={handleSuccessPopup}
        handleLoginModal={handleLoginModal}
      />
      <Otp
        OtpModal={OtpModal}
        setOtpModal={setOtpModal}
        handleSuccessPopup={handleSuccessPopup}
        handleLoginModal={handleLoginModal}
      />
      <SubmitPopup
        successPopup={successPopup}
        setSuccessPopup={setSuccessPopup}
        heading="Updated Password"
        title=""
      />
    </IonGrid>
  );
}

export default Header;
