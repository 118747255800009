import "./styles.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";
import { FaCommentDots, FaHeart } from "react-icons/fa6";
import Login from "../popups/Login";
import { getLoginVideos, getPublicPhotos } from "../../api/apiMethods";
import moment from "moment";

function HomeContent() {
  const [loginModal, setLoginModal] = useState(false);
  const handleLoginModal = () => {
    setLoginModal(true);
  };
  const [openVideoUrl, setOpenVideoUrl] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedvideo, setSelectedVideo] = useState();
  const [openVideo, setOpenVideo] = useState(
    "https://www.youtube.com/embed/2DQ4arfP-ls?si=QqT2gJGkV10VVhtL"
  );
  const handleVideo = (youtubeUrl, index) => {
    setOpenVideo(youtubeUrl);
    setSelectedVideo(index);
  };

  const closeVideoModal = () => {
    setShowVideoModal(false);
    setOpenVideoUrl(null);
  };

  const handleVideoClick = (openVideo) => {
    setShowVideoModal(true);
    setOpenVideoUrl(openVideo);
  };
  const [loginVideo, setLoginVideo] = useState([]);
  const [loginPhoto, setLoginPhoto] = useState([]);

  console.log("loginVideo", loginVideo);
  const getAllPublicVideos = () => {
    getLoginVideos()
      .then((response) => {
        if (response.status === true) {
          console.log(response.data);
          setLoginVideo(response.data);
        } else {
          console.log("SOme Erroor Is happening");
        }
      })
      .catch((error) => console.log(error));
  };
  const getAllPublicPhotos = () => {
    getPublicPhotos()
      .then((response) => {
        if (response.status === true) {
          console.log(response.data);
          setLoginPhoto(response.data);
        } else {
          console.log("Some Error Is happening");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getAllPublicVideos();
    getAllPublicPhotos();
  }, []);

  return (
    <>
      <div className="h-100vh">
        <div className="input-bg p-2 homecontent-sec">
          <div>
            <div className="d-flex flex-row align-items-center justify-content-between my-2">
              <h4 className="fw-700">Tour Videos</h4>
            </div>

            {openVideo && (
              <iframe
                className="video-container"
                src={openVideo}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                onClick={() => handleVideoClick(openVideo)}
                allowFullScreen
              ></iframe>
            )}
            <IonRow
              className="horizontal-scroll-container "
              style={{ overflowX: "auto" }}
            >
              {loginVideo?.map((item, index) => (
                <IonCol
                  size="5"
                  key={index}
                  className={`white-bg boxShadow br-10 ${
                    selectedvideo === index ? "pink-border" : "none"
                  }`}
                >
                  <div className={`px-1 py-1`}>
                    <img
                      src={item.path}
                      alt={item.video_name}
                      onClick={() => handleVideo(item.path, index)}
                    />
                    <div className="font-18 my-1 fw-600 clr-black">
                      {item.video_name}
                    </div>
                    <div className="d-flex flex-row align-items-center">
                      <FaRegCalendarAlt className="font-18" />
                      <div className="font-14 mx-1 grey-text fw-600">
                        {moment(item.created_at).format("DD-MM-YYYY")}
                      </div>
                    </div>
                  </div>
                </IonCol>
              ))}
            </IonRow>
          </div>

          <div>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <h4 className="fw-700 mt-1">Tour Photos</h4>
            </div>
            <div>
              <IonRow className="d-flex justify-content-around w-100">
                {loginPhoto &&
                  loginPhoto?.length &&
                  loginPhoto?.map((photo, index) => (
                    <IonCol key={index} size="5.8" className="pos-relative">
                      <img
                        src={photo}
                        className="w-100 m-1"
                        alt={`photo-${index}`}
                        onClick={handleLoginModal}
                      />

                      <div className="w-100 px-2 clr-white d-flex justify-content-between pos-like-sec">
                        <div className="d-flex">
                          <FaHeart className="font-24 clr-pink mr-1" />
                          {20}
                        </div>
                        <div className="d-flex">
                          <FaCommentDots className="font-24 clr-white mr-1" />
                          {50}
                        </div>
                      </div>
                    </IonCol>
                  ))}
              </IonRow>
            </div>
          </div>
        </div>
      </div>

      <Login loginModal={loginModal} setLoginModal={setLoginModal} />
    </>
  );
}

export default HomeContent;
