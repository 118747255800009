import React, { useState } from "react";
import {
  IonModal,
  IonContent,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
} from "@ionic/react";
import { IoMdDownload } from "react-icons/io";
import { AiFillEye } from "react-icons/ai";
import { close } from "ionicons/icons";

function ViewTicketsPopup({ showTickets, setShowTickets, ticketDetails }) {
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState("");

  const handleDocumentClose = () => {
    setShowTickets(false);
  };

  const handleImageClose = () => {
    setShowImagePopup(false);
    setSelectedImage(null);
    setSelectedImageName("");
  };

  const handleViewImage = (url, name) => {
    setSelectedImage(url);
    setSelectedImageName(name);
    setShowImagePopup(true);
  };

  if (!ticketDetails) return null;

  const { guidance_details, travel_details, hotel_details } = ticketDetails;

  const getFileName = (url) => {
    return url.split("/").pop().split("?")[0];
  };

  return (
    <>
      {/* Tickets Modal */}
      <IonModal
        isOpen={showTickets}
        onDidDismiss={handleDocumentClose}
        // cssClass="auto-height-modal"
        className="custom-modal"
      >
        <IonContent className="white-bg p-3">
          <IonHeader>
            <IonToolbar>
              <IonTitle className="text-center font-24 clr-black">
                Tickets
              </IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={handleDocumentClose}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <div className="p-2">
            <div>
              {/* Travel Booking Section */}
              <h6 className="clr-pink my-2">Travel Booking</h6>
              <div className="d-flex w-100 flex-column my-1">
                {travel_details?.map((travel) => (
                  <div
                    className="d-flex align-items-center justify-content-between border-grey m-1 br-10 py-1 px-2"
                    key={travel.travel_id}
                  >
                    <div>{getFileName(travel.travel_path)}</div>
                    <div className="w-20 d-flex justify-content-around align-items-center">
                      <AiFillEye
                        className="large-font fw-600 password-clr grey-bg"
                        onClick={() =>
                          handleViewImage(
                            travel.travel_path,
                            getFileName(travel.travel_path)
                          )
                        }
                      />
                      <a
                        href={travel.travel_path}
                        download={getFileName(travel.travel_path)}
                        rel="noopener noreferrer"
                      >
                        <IoMdDownload className="large-font fw-600 password-clr" />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              
              {/* Hotel Booking Section */}
              <h6 className="clr-pink my-2">Hotel Booking</h6>
              <div className="d-flex w-100 flex-column">
                {hotel_details?.map((hotel) => (
                  <div
                    className="d-flex align-items-center justify-content-between border-grey m-1 br-10 py-1 px-2"
                    key={hotel.hotel_id}
                  >
                    <div>{getFileName(hotel.hotel_path)}</div>
                    <div className="w-20 d-flex justify-content-around align-items-center">
                      <AiFillEye
                        className="large-font fw-600 password-clr grey-bg"
                        onClick={() =>
                          handleViewImage(
                            hotel.hotel_path,
                            getFileName(hotel.hotel_path)
                          )
                        }
                      />
                      <a
                        href={hotel.hotel_path}
                        download={getFileName(hotel.hotel_path)}
                        rel="noopener noreferrer"
                      >
                        <IoMdDownload className="large-font fw-600 password-clr" />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              
              {/* Tour Guidance Section */}
              <h6 className="clr-pink my-2">Tour Guidance</h6>
              <div className="d-flex w-100 flex-column">
                {guidance_details?.map((guidance) => (
                  <div
                    className="d-flex align-items-center justify-content-between border-grey m-1 br-10 py-1 px-2"
                    key={guidance.guidance_id}
                  >
                    <div>{getFileName(guidance.guidance_path)}</div>
                    <div className="w-20 d-flex justify-content-around align-items-center">
                      <AiFillEye
                        className="large-font fw-600 password-clr grey-bg"
                        onClick={() =>
                          handleViewImage(
                            guidance.guidance_path,
                            getFileName(guidance.guidance_path)
                          )
                        }
                      />
                      <a
                        href={guidance.guidance_path}
                        download={getFileName(guidance.guidance_path)}
                        rel="noopener noreferrer"
                      >
                        <IoMdDownload className="large-font fw-600 password-clr" />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </IonContent>
      </IonModal>

      {/* Image Popup Modal */}
      <IonModal
        isOpen={showImagePopup}
        onDidDismiss={handleImageClose}
        cssClass="auto-height-modal" // Apply custom class here
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>{selectedImageName}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={handleImageClose}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="d-flex justify-content-center align-items-center">
          <div className="p-2">
            <img
              src={selectedImage}
              alt={selectedImageName}
              className="img-fluid"
            />
          </div>
        </IonContent>
      </IonModal>
    </>
  );
}

export default ViewTicketsPopup;
