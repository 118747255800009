import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { Images } from "../images";
import Notification from "./Notification";
import chatting from "../api/chatting";
import personalPhotos from "../api/personalphotos";

function Message({ setShowMessageBox }) {
  const handleMsgClose = () => {
    setShowMessageBox(false);
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const [friendRequests, setFriendRequests] = useState([]);
  const handleActivebutton = (index) => {
    setActiveIndex(index);
  };
  const getFriendRequests = async () => {
    try {
      const response = await chatting.getFriendRequest();
      setFriendRequests(response?.data);
      console.log("Friend requests fetched successfully:", response?.data);
    } catch (error) {
      console.error("Error fetching friend requests:", error);
    }
  };

  useEffect(() => {
    getFriendRequests();
  }, []);

  const handleAcceptFriendRequest = async (requestId) => {
    try {
      const response = await chatting.acceptFriendRequest(requestId);
      console.log("Friend request accepted:", response.data);
    } catch (error) {
      console.error("Error accepting friend request:", error);
    }
  };

  const handleDeleteFriendRequest = async (requestId) => {
    try {
      const response = await chatting.rejectFriendRequest(requestId);
      console.log("Friend request rejected:", response.data);
    } catch (error) {
      console.error("Error rejecting friend request:", error);
    }
  };

  const acceptPersonalPhotosRequest = async (id) => {
    try {
      console.log(id, "==>requestForPersonalPhotos");

      const response = await personalPhotos.acceptPersonalPhotosRequest(id);
      console.log(response.data, "==>personal photos");
    } catch (error) {
      console.error("Error requesting personal photos:", error);
    }
  };

  const rejectPersonalPhotosRequest = async (id) => {
    try {
      console.log(id, "==>requestForPersonalPhotos");

      const response = await personalPhotos.rejectPersonalPhotosRequest(id);
      console.log(response.data, "==>personal photos");
    } catch (error) {
      console.error("Error requesting personal photos:", error);
    }
  };

  const buttons = ["Message", "Notification"];
  return (
    <div className="message-section light-grey-bg w-100 p-3 h-93vh">
      <div className="d-flex flex-row justify-content-between align-items-center w-100 p-2">
        <div className="w-75 d-flex flex-row align-items-center justify-content-around">
          {buttons.map((btn, index) => (
            <div
              key={index}
              className={
                activeIndex === index
                  ? "pink-bg clr-white font-16 fw-600 px-2 py-2 br-20"
                  : "input-bg clr-black font-16 fw-600 px-2 py-2 br-20"
              }
              onClick={() => handleActivebutton(index)}
            >
              {btn}
            </div>
          ))}
        </div>

        <RxCross2
          className="font-24 clr-black"
          onClick={() => handleMsgClose()}
        />
      </div>
      {activeIndex === 0 && (
        <div className="white-bg message-sec br-10">
          {friendRequests?.map((item, index) => (
            <div key={index} className="d-flex flex-row border-bottom-grey p-2">
              <div>
                <div className="w-100 px-2 d-flex ">
                  <img
                    src={Images.Boy}
                    alt={`Avatar ${index + 1}`}
                    className="chat-profile"
                  />
                  <div className=" font-16 p-2 fw-600 clr-pink friend-request ">
                    {item.senderName} send a friend request
                  </div>
                </div>

                <div className="d-flex w-100 flex-evenly ">
                  <div
                    className="pink-bg clr-white font-16 fw-600 px-2 py-1 br-10"
                    onClick={() => handleAcceptFriendRequest(item.id)}
                  >
                    Accept
                  </div>
                  <div
                    className="pink-bg clr-white font-16 fw-600 px-2 py-1 br-10"
                    onClick={() => handleDeleteFriendRequest(item.id)}
                  >
                    Decline
                  </div>
                </div>
              </div>
            </div>
          ))}
          {friendRequests?.map((item, index) => (
            <div key={index} className="d-flex flex-row border-bottom-grey p-2">
              <div>
                <div className="w-100 px-2 d-flex ">
                  <img
                    src={Images.Boy || item?.profile_image}
                    alt={`Avatar ${index + 1}`}
                    className="chat-profile"
                  />
                  <div className=" font-16 p-2 fw-600 clr-pink friend-request ">
                    {item.senderName} requested for photos
                  </div>
                </div>

                <div className="d-flex w-100 flex-evenly ">
                  <div
                    className="pink-bg clr-black font-16 fw-600 px-2 py-1 br-10"
                    onClick={() => acceptPersonalPhotosRequest(item.id)}
                  >
                    Accept
                  </div>
                  <div
                    className="pink-bg clr-black font-16 fw-600 px-2 py-1 br-10"
                    onClick={() => rejectPersonalPhotosRequest(item.id)}
                  >
                    Decline
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {activeIndex === 1 && <Notification />}
    </div>
  );
}

export default Message;
