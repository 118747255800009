import React, { useEffect, useState } from "react";
import { Images } from "../../images";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router";
import chatting from "../../api/chatting";
import { useDispatch } from "react-redux";
import { setCheckMyCreator, setSelectedUser } from "../../redux/action";
import ForwardMessage from "./ForwardMessage";


function Chat() {
  const [friendRequests, setFriendRequests] = useState([]);
  const [myTourMembers, setMyTourMembers] = useState([]);
  const [myAcceptedFriends, setMyAcceptedFriends] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [typing, setTyping] = useState(false);
  const [typingStatus, setTypingStatus] = useState({});

  const myId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (id, name,blocked,isCreator) => {
  dispatch(setCheckMyCreator(isCreator))
    console.log(id,name,blocked,"==>id and namem");
    
    dispatch(
      setSelectedUser({
        userId: id,
        userName: name,
        block:blocked
      })
    );
    navigate(`${id}`);
  };



  

  const getMyFriendRequests = async () => {
    const response = await chatting?.getFriendRequest();
    setFriendRequests(response?.data);
  };

  const getMyTourMembers = async () => {
    const response = await chatting?.getMyTourMemberDetails();
    setMyTourMembers(response?.data);
  };

  const sendFriendRequest = async (id) => {
    console.log(myId, "friend request sent successfully in app ", id);
    const response = await chatting.sendFriendRequest(id,"request sent");
    
  };

  const getMyFriendsAll = async () => {
    const response = await chatting.getMyFriendsList();
   
    
    setMyAcceptedFriends(response?.data);
  };

  useEffect(() => {
    getMyTourMembers();
    getMyFriendRequests();
    getMyFriendsAll();
  }, []);

 
console.log(myAcceptedFriends,"==>myAcceptedFriends");

  


  

  return (
    <div className="input-bg px-2 homecontent-sec">
      <div>
        <div className="fw-600">
          <div className=" font-16 fw-600 my-2 border-bottom-grey py-1" >
            Add Friends
          </div>
          <div className="w-100 d-flex horizontal-scroll-container">
            {myTourMembers?.map((chat, index) => {
              const friendRequest = friendRequests?.find((item) => item?.recipientId === Number(chat?.id));
              return (
                <div key={index} className="d-flex flex-column justify-content-center align-items-center p-1 white-bg w-25 mx-1">
                  <img
                    src={Images.addfrnd1}
                    className="chat-profile"
                    alt="Profile"
                    onClick={() => sendFriendRequest(chat?.id)}
                  />
                  <div className="d-flex flex-column font-12">
                    <div className="password-clr fw-600 font-14 my-1 px-3">{chat?.user_name}</div>
                    <div className="clr-white pink-bg font-14 text-center py-1 br-10">
                      {friendRequest?.status === "PENDING" ? (
                        "Requested"
                      ) : (
                        <span onClick={() => sendFriendRequest(chat?.id)}>Add</span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div>
        <div className="font-24 my-2 fw-600" onClick={getMyFriendsAll}>Users</div>
        <div className="white-bg w-100 border-grey p-1 d-flex align-items-center br-10">
          <input
            type="text"
            placeholder="Search Users"
            className=" font-12 grey-text w-90 all-none fw-600"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            
          />
          <FaSearch className="large-font grey-text w-10" />
        </div>
        <div className="d-flex flex-column p-2">
        {myAcceptedFriends?.map((chat, index) => (
  <div 
    key={index} 
    className="d-flex flex-row align-items-center py-1 border-bottom-grey" 
    onClick={() => {
      if (chat.hasOwnProperty("isCreator")) {
        handleClick(
          chat?.userId,
          chat?.userName,
          chat?.blocked,
          chat?.isCreator
        );
      } else {
        handleClick(
          chat?.userId,
          chat?.userName,
          chat?.blocked
        );
      }
    }}
  >
    <div className="w-20">
      <img src={Images.addfrnd1} className="chat-profile" alt="Profile" />
    </div>
    <div className="d-flex flex-column w-80 font-12">
      <div className="d-flex flex-row justify-content-between">
        <div className="password-clr fw-600 font-14">{chat?.userName}</div>
      </div>
      <div className="grey-text font-12 mt-2px small-font">Tap to Chat</div>
    </div>
  </div>
))}

        </div>
      </div>
    </div>
  );
}

export default Chat;
